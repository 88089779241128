import React from "react";
import { Route, Switch, BrowserRouter as Router, Redirect } from "react-router-dom";
import "./App.scss";
import "rc-pagination/assets/index.css";
import ScrollToTop from "utilities/scrollHelpers";
import { PrivacyPage } from "components/privacyTerms/privacyPage";
import ContactPageContainer from "components/contact";
import AboutPageContainer from "components/about";
import CareersPageContainer from "components/careers";
import MediaPageContainer from "components/media";
import HomePageContainer from "components/home";
import DemoPageContainer from "components/demo";
import ErrorBoundary from "components/controls/errorBoundary";
import { HistoryContext, HistoryListener, IHistoryContext } from "./utilities/historyListener";
import * as contentful from "contentful";
import contentfulClient from "./utilities/contentfulClient";
import { EditorialPage } from "./components/media/editorial";
import { Editorial, EditorialRouteParams, EditorialsContext } from "types/editorial";
import HomeEquityPageContainer from "components/homeEquity";
import LossMitPageContainer from "components/lossMit";
import SecurityPage from "components/security";
import { getHomePageHeroSlides, IHomePageHeroSlide } from "types/homePageHeroSlide";
import JobsPage from "components/jobs";
import {
  getThoughtLeadershipSections,
  IThoughtLeadershipSection,
  ThoughtLeadershipSectionsContext,
} from "types/thoughtLeadershipSection";
import "react-tooltip/dist/react-tooltip.css";

interface State {
  history: IHistoryContext;
  editorials: contentful.Entry<Editorial>[];
  homePageHeroSlides: contentful.Entry<IHomePageHeroSlide>[];
  thoughtLeadershipSections: contentful.Entry<IThoughtLeadershipSection>[];
}

class App extends React.Component<{}, State> {
  setInitialHistoryEventCalled = () => {
    this.setState({
      ...this.state,
      history: {
        ...this.state.history,
        initialHistoryEventCalled: true,
      },
    });
  };
  state: State = {
    history: {
      initialHistoryEventCalled: false,
      setInitialHistoryEventCalled: this.setInitialHistoryEventCalled,
    },
    editorials: [],
    homePageHeroSlides: [],
    thoughtLeadershipSections: [],
  };

  componentDidMount() {
    //get all editorials
    contentfulClient
      .getEntries<Editorial>({
        // sort by most recent
        order: "-fields.publishDate",
        // MUST specify contentType
        content_type: "flueidEditorial",
      })
      .then((entries) => {
        this.setState({ ...this.state, editorials: entries.items });
      });

    // get all home page hero slides
    getHomePageHeroSlides()
      .then((entries) => this.setState({ ...this.state, homePageHeroSlides: entries.items }))
      .catch((e) => console.error(e));

    // get all thought leadership sections
    getThoughtLeadershipSections()
      .then((entries) => this.setState({ ...this.state, thoughtLeadershipSections: entries.items }))
      .catch((err) => console.error(err));
  }

  render() {
    const { history, editorials, homePageHeroSlides, thoughtLeadershipSections } = this.state;
    const editorialSlug: keyof EditorialRouteParams = "slug";

    return (
      <div className="App">
        <HistoryContext.Provider value={this.state.history}>
          <EditorialsContext.Provider value={editorials}>
            <ThoughtLeadershipSectionsContext.Provider value={thoughtLeadershipSections}>
              <Router>
                <ErrorBoundary>
                  <HistoryListener
                    initialHistoryEventCalled={history.initialHistoryEventCalled}
                    setInitialHistoryEventCalled={history.setInitialHistoryEventCalled}
                  />
                  <ScrollToTop />
                  <Switch>
                    {/* always remove trailing slash from end of url so path checks work */}
                    <Redirect from="/:url*(/+)" to={location.pathname.slice(0, -1)} />
                    <Route path="/contact" component={ContactPageContainer} />
                    <Route path="/about" component={AboutPageContainer} />
                    <Route path="/careers" component={CareersPageContainer} />
                    <Route path="/job-postings" component={JobsPage} />
                    {/* <Route path="/job-postings" component={JobPostings} /> */}
                    <Route path="/demo" component={DemoPageContainer} />
                    <Route path="/media" component={MediaPageContainer} />
                    <Route path="/privacy" component={PrivacyPage} />
                    <Route path={`/editorial/:${editorialSlug}`} component={EditorialPage} />
                    <Route path="/home-equity" component={HomeEquityPageContainer} />
                    <Route path="/loss-mit" component={LossMitPageContainer} />
                    <Route path="/security" component={SecurityPage} />
                    <Route
                      path="/"
                      render={({ location }) => {
                        // logic to ensure social media links using old paths are redirected to new path structure
                        // not root path? (paths not found will trickle down to root path)
                        if (location.pathname !== "/") {
                          // see if editorial with old previous path matching current path can be found
                          const editorialMatchPath = editorials.find(
                            (x) =>
                              x.fields?.previousPath?.trim().toLocaleLowerCase() ===
                              location.pathname.trim().toLocaleLowerCase(),
                          );
                          if (editorialMatchPath && editorialMatchPath.fields.previousPath) {
                            // redirect to new editorial route
                            return <Redirect to={`/editorial/${editorialMatchPath.fields.slug}`} />;
                          }
                          if (editorials.length && !editorialMatchPath) {
                            return <HomePageContainer homePageHeroSlides={homePageHeroSlides} />;
                          }
                        } else {
                          return <HomePageContainer homePageHeroSlides={homePageHeroSlides} />;
                        }
                      }}
                    />
                  </Switch>
                </ErrorBoundary>
              </Router>
            </ThoughtLeadershipSectionsContext.Provider>
          </EditorialsContext.Provider>
        </HistoryContext.Provider>
      </div>
    );
  }
}

export default App;
