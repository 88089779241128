import { useContext } from "react";
import { NavLink, useRouteMatch } from "react-router-dom";
import { EditorialRouteParams, EditorialsContext } from "types/editorial";
import Transition from "components/controls/transition";
import { SubNavbar } from "components/navbar/subNavbar";
import { documentToReactComponents, Options } from "@contentful/rich-text-react-renderer";
import { SectionContent } from "components/common/sectionContent";
import { ReactComponent as IconLinkedIn } from "../../images/icons/socialMedia/icon-linkedin.svg";
import { ReactComponent as IconEmail } from "../../images/icons/icon-email.svg";
import { ReactComponent as IconFacebook } from "../../images/icons/socialMedia/icon-facebook.svg";
import { ReactComponent as IconTwitter } from "../../images/icons/socialMedia/icon-twitter.svg";
import Footer from "../footer";
import { formatDateLong } from "utilities/formatters";
import EditorialRequestForm from "./editorialRequestForm";
import { INLINES } from "@contentful/rich-text-types";

export const EditorialPage = () => {
  // grab slug from path
  const match = useRouteMatch<EditorialRouteParams>();
  const slug = match.params.slug;

  // grab editorial from list
  const editorials = useContext(EditorialsContext);
  const editorial = editorials.find((x) => x.fields.slug === slug);

  // TODO: perhaps show some "sorry, editorial not found" type message???
  if (!editorial) return null;

  // format published date
  const date = formatDateLong(editorial.fields.publishDate);

  // img data
  const headerImgSrc = editorial.fields?.headerImg?.fields?.file?.url;
  const headerImgAlt = editorial.fields?.headerImg?.fields?.description;
  // vertical image? if so then assign floated styles to img
  const headerImg = editorial.fields?.headerImg?.fields?.file?.details?.image;
  const isVertImg = headerImg && headerImg?.height > headerImg?.width ? true : false;

  // url encoded title for links
  const encodedTitle = encodeURIComponent(editorial.fields.title);

  const stats = editorial.fields?.stats;

  // options to use react router for internal links - provides faster UX cus it doesn't have to reload the whole app
  const rendererOptions: Options = {
    renderNode: {
      [INLINES.HYPERLINK]: (node, children) => {
        // try-catch block because if node.data.uri is undefined or invalid it throws error that would crash app
        try {
          const url = new URL(node.data.uri);
          if (url?.host === "flueid.com" || url?.host === "www.flueid.com") {
            return <NavLink to={url.pathname + url.search || ""}>{children}</NavLink>;
          } else {
            return (
              <a target="_blank" href={node.data.uri}>
                {children}
              </a>
            );
          }
        } catch (error) {
          console.log(error);
        }
      },
    },
  };

  return (
    <Transition visible animation="fadeIn">
      <div className="featuredStory-container">
        <SubNavbar title={editorial.fields.type} to="/media" />
        <div className={editorial.fields?.useRequestForm ? "flex flex-center flex-col_mobile" : ""}>
          <div>
            <SectionContent maxWidthType="section-maxWidth-xsm" className="margin-top_lg section-paddingBottom_md">
              <div className="subheader-2 txt-light">{date}</div>
              <h2>{editorial.fields.title}</h2>
              <h4 className="subheader-main">{editorial.fields.subTitle}</h4>
              <div className="flex flex-align-center flex-wrap flex-justify-spaceBetween margin-top_xsm">
                <div className="subheader-2 txt-light byline-margin">{editorial.fields.byLine}</div>
                <div className="flex flex-align-center">
                  <div className="subheader-2 txt-medium shareLink">SHARE</div>
                  <a
                    href={`https://www.linkedin.com/sharing/share-offsite/?url=${window.location.href}`}
                    className="shareLink"
                    target="_blank">
                    <IconLinkedIn />
                  </a>
                  <a
                    href={`https://twitter.com/intent/tweet?url=${window.location.href}&text=${encodedTitle}`}
                    className="shareLink"
                    target="_blank">
                    <IconTwitter />
                  </a>
                  <a
                    href={`https://www.facebook.com/sharer/sharer.php?u=${window.location.href}&quote=${encodedTitle}`}
                    className="shareLink"
                    target="_blank">
                    <IconFacebook />
                  </a>
                  <a
                    href={`mailto:?subject=Flueid%20Story&body=${encodedTitle} - ${window.location.href}`}
                    className="shareLink">
                    <IconEmail />
                  </a>
                </div>
              </div>
            </SectionContent>
            <SectionContent maxWidthType="section-maxWidth-xsm">
              <img
                className={`img-main margin-bottom_sm ${isVertImg ? "img-main_vert margin-right_sm" : ""}`}
                src={headerImgSrc}
                alt={headerImgAlt}
              />
              {stats?.length && (
                <div className="flex flex-wrap flex-justify-center flex-align-start">
                  {stats.map((x, i) => (
                    <div key={i} className="txt-center margin_xsm">
                      <h1 className="stats-h1 margin-none">{x.value}</h1>
                      <p className="stats-p">{x.text}</p>
                    </div>
                  ))}
                </div>
              )}
              <div className="editorial-body">
                {editorial.fields.body && documentToReactComponents(editorial.fields.body, rendererOptions)}
              </div>
            </SectionContent>
          </div>
          {editorial.fields?.useRequestForm && <EditorialRequestForm editorial={editorial} />}
        </div>
        <Footer />
      </div>
    </Transition>
  );
};
