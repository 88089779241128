import { useContext } from "react";
import Pagination from "rc-pagination";
import { SectionContent } from "../common/sectionContent";
import { PressReleaseRow } from "./pressReleaseRow";
import { paginationTitles } from "../../types/pagination";
import { ArrowButton } from "components/controls/arrowButtonGroup";
import { EditorialsContext } from "types/editorial";
import { usePagination } from "utilities/hooks/usePagination";

function LatestNewsSection() {
  // get all press releases
  const editorials = useContext(EditorialsContext);
  const pressReleases = editorials.filter((x) => x.fields.type === "Press Release");

  const { current, setCurrent, pageStart, pageEnd, totalPages, pageSize, pageData } = usePagination({
    pageSize: 3,
    name: "pressReleases",
    data: pressReleases,
  });
  return (
    <section className="latestNews-section">
      <SectionContent maxWidthType="section-maxWidth-small">
        <h2 className="margin-top_none">Press Releases</h2>
        <div className="minHeight-wrapper">
          {pageData.map((x) => (
            <PressReleaseRow story={x} key={`pressReleaseRow-${x.sys.id}`} />
          ))}
        </div>
        <div className="flex flex-justify-spaceBetween pagination-margin">
          <div className="p-2 txt-medium">
            Page {current} of {totalPages}
          </div>
          <Pagination
            className="pagination-container"
            onChange={(x) => {
              setCurrent(x);
            }}
            current={current}
            total={pressReleases.length}
            pageSize={pageSize}
            locale={paginationTitles}
            prevIcon={<ArrowButton direction="prev" />}
            nextIcon={<ArrowButton direction="next" />}
          />
        </div>
      </SectionContent>
    </section>
  );
}

export default LatestNewsSection;
