import { SectionContent } from "../common/sectionContent";
import { NavLink } from "react-router-dom";
import { Button } from "../controls/button";
import React from "react";
import { ReactComponent as IconCheck } from "../../images/icons/icon-automate.svg";
import { ReactComponent as IconArrow } from "../../images/icons/icon-risk.svg";
import { ReactComponent as IconCosts } from "../../images/icons/icon-profit.svg";
import { ReactComponent as IconStar } from "../../images/icons/icon-time.svg";
import { ReactComponent as IconLock } from "../../images/icons/icon-exp.svg";
import DashedLineGroup, { DashedLine } from "components/controls/dashedLines";
import { useThoughtLeadershipSections } from "types/thoughtLeadershipSection";
import ThoughtLeadershipSection from "components/common/thoughtLeadershipSection";

interface FeatureGridItem {
  icon: React.ReactNode;
  title: string;
  text: string;
}
interface Props {
  item: FeatureGridItem;
}
const FeatureGridItem: React.FC<Props> = ({ item }) => (
  <div className="featureGrid-item">
    {item.icon}
    <h6 className="txt-semiBold">{item.title}</h6>
    <div className="p-3 txt-extraLight">{item.text}</div>
  </div>
);

const gridItems: FeatureGridItem[] = [
  {
    icon: <IconCheck />,
    title: "Automate & Optimize",
    text: "Digitally enhance labor intensive review processes and easily confirm details about a property and consumer directly within your core operating system. Choose the best path for each order in real time.",
  },
  {
    icon: <IconArrow />,
    title: "Understand Closing Risk",
    text: "Leverage our proprietary analyzers, data sets and logic to uncover risks and highlight potential title issues, so they aren’t missed, and you know about them earlier.",
  },
  {
    icon: <IconCosts />,
    title: "Boost Profitability",
    text: "Prioritize files that are cleared of title issues and push off costs for those that aren’t yet clear until identified items are verified. Eliminate files that would later fall out and ensure title partners are working on opportunities they know are most likely to close.",
  },
  {
    icon: <IconStar />,
    title: "Shorten Cycle Times",
    text: "Win back the weeks that are lost when property or consumer issues pop up after receiving the title report or title commitment, depending on transaction type, and close more opportunities quickly.",
  },
  {
    icon: <IconLock />,
    title: "Simplify the Consumer Experience",
    text: "Know the right questions and information to gather from consumers. Most importantly, avoid 	surprises, late-breaking requests, or timeline changes for a seamless process.",
  },
];

export const FeaturesSection = () => {
  const thoughtLeadershipSections = useThoughtLeadershipSections();
  const homePageQuotesSlug = "home-page-quotes";
  const quoteSection = thoughtLeadershipSections.find((x) => x.fields.slug === homePageQuotesSlug);

  return (
    <section className="features-section">
      {quoteSection && <ThoughtLeadershipSection section={quoteSection} />}
      <SectionContent maxWidthType="section-maxWidth-small">
        <h2 className="txt-align-center fuel-top-header">The Fueled by Flueid™ Advantage</h2>
        <div className="featureGrid">
          {gridItems.map((x: FeatureGridItem, i: number) => (
            <FeatureGridItem item={x} key={`featureGrid-item-${i}`} />
          ))}
        </div>
        <DashedLineGroup animationDirection="left" className="dashedLineGroup-2 dashedLineGroupInterested">
          {(visible) => (
            <>
              <DashedLine color="black" width={269} duration={0.5} delay={0.7} x={"-10vw"} show={visible} />
              <DashedLine color="black" width={150} duration={1.5} delay={0} x={"-8vw"} show={visible} />
              <DashedLine color="black" width={15} duration={1.5} delay={1} x={"-4vw"} show={visible} />
              <DashedLine color="black" width={25} duration={2} delay={1} x={"-1vw"} show={visible} />
            </>
          )}
        </DashedLineGroup>
        <div className="width-banner-fix-mobile">
          <div></div>
          <div className="demo-box-container">
            <div className="demo-box-content">
              <h3 className="txt-bold">Interested?</h3>
              <p>Schedule a comprehensive financial impact analysis for your company.</p>
              <NavLink to="/demo?topic=demo">
                <Button className="btn-blue btn-left-align">Schedule a Meeting</Button>
              </NavLink>
            </div>
          </div>
        </div>

        <div className="width-banner-fix-desktop">
          <div></div>
          <div className="demo-box-container">
            <div className="demo-box-content">
              <h3 className="txt-bold">Interested?</h3>
              <p>Schedule a comprehensive financial impact analysis for your company.</p>
              <NavLink to="/demo?topic=demo">
                <Button className="btn-blue btn-left-align">Schedule a Meeting</Button>
              </NavLink>
            </div>
          </div>
        </div>
      </SectionContent>
    </section>
  );
};
