import ReactDOMServer from "react-dom/server";

export class ContactUsRequest {
  topic: ContactTopic = ContactTopic.Undefined;
  name: string = "";
  companyName: string = "";
  email: string = "";
  phone: string = "";
  position: string = "";
  message: string = "";

  static EmailBody(contactUsRequest: ContactUsRequest): string {
    const blankField = "----------";
    const body = ReactDOMServer.renderToStaticMarkup(
      <div>
        <h1>Contact Us Request</h1>
        <br />
        <p>
          Topic: <span>{contactUsRequest.topic}</span>
        </p>
        <p>
          Name: <span>{contactUsRequest.name}</span>
        </p>
        <p>
          Company Name: <span>{contactUsRequest.companyName || blankField}</span>
        </p>
        <p>
          Email: <span>{contactUsRequest.email}</span>
        </p>
        <p>
          Phone: <span>{contactUsRequest.phone}</span>
        </p>
        <p>
          Position: <span>{contactUsRequest.position || blankField}</span>
        </p>
        <p>
          Message: <span>{contactUsRequest.message}</span>
        </p>
      </div>,
    );
    return body;
  }
}

export type ContactUsKey = keyof ContactUsRequest;

export enum ContactTopic {
  Undefined = "",
  General = "General Question",
  Meeting = "Schedule a call/meeting",
  Demo = "Schedule a product demonstration",
  Join = "I’m interested in career opportunities at Flueid",
  HR = "Contact Human Resources",
  Feedback = "General Feedback",
  Other = "Other",
}

export const isContactTopic = (str?: string | null): str is ContactTopic => {
  const topics = Object.values(ContactTopic).filter(Boolean);
  return topics.includes(str as ContactTopic);
};

export function getEmailRecipient(topic: ContactTopic | string): string {
  switch (topic) {
    case ContactTopic.General:
    case ContactTopic.Feedback:
    case ContactTopic.Other:
      return "hi@flueid.com";
    case ContactTopic.Join:
    case ContactTopic.HR:
      return "hr@flueid.com";
    case ContactTopic.Demo:
    case ContactTopic.Meeting:
      return "sales@flueid.com";
    default:
      return "hi@flueid.com";
  }
}

export interface DropdownOption<T> {
  value: T;
  label: string | number;
}

export const contactOptions: DropdownOption<ContactTopic>[] = [
  { value: ContactTopic.General, label: ContactTopic.General },
  { value: ContactTopic.Meeting, label: ContactTopic.Meeting },
  { value: ContactTopic.Demo, label: ContactTopic.Demo },
  { value: ContactTopic.Join, label: ContactTopic.Join },
  { value: ContactTopic.HR, label: ContactTopic.HR },
  { value: ContactTopic.Feedback, label: ContactTopic.Feedback },
  { value: ContactTopic.Other, label: ContactTopic.Other },
];
