import React from "react";

interface Props {
  className?: string;
}

export const BlockQuote: React.FC<Props> = (props) => (
  <h6 className={`blockQuote txt-light ${props.className || ""}`}>
    <span>
      <h2 className="openQuote">&ldquo;</h2>
    </span>
    {props.children}
    <span>
      <h2 className="closeQuote">&rdquo;</h2>
    </span>
  </h6>
);
