import React from "react";
import Input from "./input";

class TextArea<T extends string> extends Input<T> {
  ref = React.createRef<HTMLTextAreaElement>();

  render() {
    const {
      editable,
      styles,
      label,
      required,
      leftIcon,
      type,
      placeHolder,
      autoComplete,
      valid,
      errorMessages,
      name,
      inputVersion,
      tabIndex,
      className,
      value,
      max,
      pattern,
    } = this.props;

    const optionalAttributes: { [key: string]: any } = {};
    if (max && type === "text") optionalAttributes.maxLength = max;
    if (max && (type === "number" || type === "stringNumber")) optionalAttributes.max = max;
    if (pattern) optionalAttributes.pattern = pattern;
    if (editable === false) optionalAttributes.readOnly = true;

    return (
      <div
        className={`
                input-group 
                ${editable === false && "read-only"} 
                ${inputVersion !== undefined && inputVersion}
                ${valid !== undefined && !valid && "error"}
                ${className || ""}`}
        style={styles ? styles : {}}>
        {label && label.length > 0 && (
          <div className={`label ${required && "required"}`}>
            {label} {required && required === true ? "*" : ""}
          </div>
        )}
        {leftIcon ? leftIcon : ""}
        <textarea
          value={type === "number" && !value ? "" : value}
          tabIndex={tabIndex}
          name={name}
          onChange={this.handleInputChange}
          placeholder={placeHolder}
          ref={this.ref}
          style={leftIcon ? { paddingLeft: 25 } : {}}
          autoComplete={autoComplete ? "on" : "off"}
          {...optionalAttributes}
          className={`${errorMessages && errorMessages.length ? "error" : ""} ${
            type === "date" && !value ? "pad-right-5" : ""
          }`}
        />
      </div>
    );
  }
}

export default TextArea;
