import styles from "./styles.module.scss";

type Spacing = "none" | "sm" | "md" | "lg";

type Props = {
  className?: string;
  vertical?: boolean;
  spacing?: Spacing;
  flexItem?: boolean; // Must use if it's in a flexbox container!
};

const Divider = ({ className, vertical, spacing, flexItem }: Props) => {
  const spacingMap = new Map<Spacing | undefined, string>([
    [undefined, vertical ? styles.mx_md : styles.my_md],
    ["none", styles.m_0],
    ["sm", vertical ? styles.mx_sm : styles.my_sm],
    ["md", vertical ? styles.mx_md : styles.my_md],
    ["lg", vertical ? styles.mx_lg : styles.my_lg],
  ]);

  return (
    <hr
      className={`            
            ${vertical ? styles.vertical : styles.divider}        
            ${className || ""}
            ${spacingMap.get(spacing)}
            ${flexItem ? styles.flex_item : ""}
        `}
    />
  );
};

export default Divider;
