import React from "react";

interface Props {
  open: boolean;
  handleOpen: () => void;
  handleClose: () => void;
}

export const Hamburger: React.FC<Props> = ({ open, handleClose, handleOpen }) => {
  const strokeWidth = "5px";
  return (
    <div className={`navbar-hamburger ${open ? "open" : "closed"}`} onClick={open ? handleClose : handleOpen}>
      <svg
        className="svg-hamburger"
        width="22px"
        height="22px"
        viewBox="0 0 48 48"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg">
        <g>
          <line strokeWidth={strokeWidth} x1="0" y1="17" x2="48" y2="17" />
          <line strokeWidth={strokeWidth} x1="0" y1="31" x2="48" y2="31" />
        </g>
        <g>
          <line strokeWidth={strokeWidth} x1="0" y1="24" x2="48" y2="24" />
          <line strokeWidth={strokeWidth} x1="0" y1="24" x2="48" y2="24" />
        </g>
      </svg>
    </div>
  );
};
