import Modal from "components/controls/modal";
import DemoFormModel from "components/demo/demoFormModel";
import React from "react";

interface Props {
  className?: string;
}

interface State {
  modalOpen: boolean;
}
class DecisionReportLossMit extends React.Component<Props> {
  setModalOpen = () => this.setState({ modalOpen: true });
  SetModalClose = () => this.setState({ modalOpen: false });
  state: State = {
    modalOpen: false,
  };
  render() {
    return (
      <>
        <div className="services-container-loss-mit">
          <div className="services-title text-blue">A proactive solution for today’s market</div>
          <div className="services-flex-group">
            <div className="services-flex-block">
              <div className="services-block-title">Champion your Borrower</div>
              <div className="services-block-text">
                Counsel and advise your borrowers to seek a reperforming loan status and avoid foreclosure whenever
                possible.
              </div>
            </div>
            <div className="services-flex-block">
              <div className="services-block-title">Demonstrate Consumer Care</div>
              <div className="services-block-text">
                Have a record of option analysis and due diligence for a particular borrower to support regulatory
                compliance.
              </div>
            </div>
            <div className="services-flex-block">
              <div className="services-block-title">Unlock Data-Driven Decisioning</div>
              <div className="services-block-text">
                Embed comprehensive information about a borrower and the property into your existing workflows and to
                drive process optimization.
              </div>
            </div>
            <div className="services-flex-block">
              <div className="services-block-title">Check Investor Guidelines with Ease</div>
              <div className="services-block-text">
                Review your strategies against investor guidelines with data and alerts on the borrower, property and
                mortgage.
              </div>
            </div>
            <div className="services-flex-block">
              <div className="services-block-title">Expense Reduction</div>
              <div className="services-block-text">
                Cut costs by assigning the right staff to files depending on complexity, while selecting the best path
                for the borrower from the start.
              </div>
            </div>
            <div className="services-flex-block">
              <div className="services-block-title">Prioritize Data Security</div>
              <div className="services-block-text">
                Be confident your borrowers’ data is securely transmitted and stored with Flueid's SOC 2 Type 2
                compliant platform down to the individual order.
              </div>
            </div>
          </div>
          <div className="report-bottom-text">Want a full demo of the Decision Report and Equity Analysis?</div>
          <div className="btn-container-equity-demo">
            <div onClick={this.setModalOpen} className="btn btn-black equity-hero-btn equity-btn-outline">
              Request a Demo
            </div>
          </div>
        </div>
        <Modal open={this.state.modalOpen} onClose={() => this.SetModalClose()}>
          <DemoFormModel />
        </Modal>
      </>
    );
  }
}

export default DecisionReportLossMit;
