import DemoRequest, { CompanyType } from "../../types/demoRequest";
import { newValidatedField, ValidationDictionary, ValidationSchema, ValidationType } from "../../types/validation";

export const validationDefault: ValidationSchema<DemoRequest> = {
  name: newValidatedField(true, [ValidationType.Value]),
  companyName: newValidatedField(true, [ValidationType.Value]),
  email: newValidatedField(true, [ValidationType.Email]),
  phone: newValidatedField(false, [ValidationType.Phone]),
};

export const validation: ValidationDictionary<CompanyType, DemoRequest> = {
  "Title Underwriter": {
    ...validationDefault,
    stateFootprint: newValidatedField(true, [ValidationType.NotEmptyArray]),
    prodSystems: newValidatedField(true, [ValidationType.NotEmptyArray]),
  },
  "Title Agency": {
    ...validationDefault,
    stateFootprint: newValidatedField(true, [ValidationType.NotEmptyArray]),
    prodSystems: newValidatedField(true, [ValidationType.NotEmptyArray]),
    titleUnderwriters: newValidatedField(true, [ValidationType.Value]),
  },
  "Escrow/Closing": {
    ...validationDefault,
    stateFootprint: newValidatedField(true, [ValidationType.NotEmptyArray]),
    prodSystems: newValidatedField(true, [ValidationType.NotEmptyArray]),
    titleUnderwriters: newValidatedField(true, [ValidationType.Value]),
  },
  "Mortgage Lender": {
    ...validationDefault,
    stateFootprint: newValidatedField(true, [ValidationType.NotEmptyArray]),
    posSystem: newValidatedField(true, [ValidationType.Value]),
    titleAgents: newValidatedField(true, [ValidationType.Value]),
    titleUnderwriters: newValidatedField(true, [ValidationType.Value]),
  },
  "Real Estate": {
    ...validationDefault,
    stateFootprint: newValidatedField(true, [ValidationType.NotEmptyArray]),
    bizCities: newValidatedField(true, [ValidationType.Value]),
  },
  Other: {
    ...validationDefault,
    industryType: newValidatedField(true, [ValidationType.Value]),
  },
};
