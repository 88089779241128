import ReactDOMServer from "react-dom/server";
import { OptionType } from "./stateOptions";

export const companyTypeOptions = [
  "Title Underwriter",
  "Title Agency",
  "Escrow/Closing",
  "Mortgage Lender",
  "Mortgage Servicer",
  "Real Estate",
  "Foreclosure Attorney",
  "Capital Markets/Investor",
  "Digital Mortgage/Title SaaS",
  "Other",
  "",
] as const;
export type CompanyType = typeof companyTypeOptions[number];

// export type IndustryType = "" | "Fin Tech";
// export const industryTypeOptions: IndustryType[] = ["Fin Tech"];

export const prodSystemsOptions: OptionType[] = [
  { label: "SoftPro", value: "SoftPro" },
  { label: "Rezware", value: "Rezware" },
  { label: "Qualia", value: "Qualia" },
  { label: "RamQuest", value: "RamQuest" },
  { label: "Other", value: "Other" },
];

export type DemoRequestKey = keyof DemoRequest;

export default class DemoRequest {
  // CONTACT INFO
  name: string = "";
  companyName: string = "";
  email: string = "";
  phone: string = "";
  preferedDate: string = "";
  message: string = "";

  //--------------
  // COMPANY INFO
  companyType: CompanyType = "";

  titleAgents: string = "";
  titleUnderwriters: string = "";

  stateFootprint: string[] = [];
  bizCities: string = "";
  bizCounties: string = "";

  prodSystems: string[] = [];
  posSystem: string = "";
  losSystem: string = "";

  orderVolume: string = "";
  industryType: string = "";
  // industryType: IndustryType = "";

  static EmailBody(demoRequest: DemoRequest): string {
    const blankField = "----------";

    const body = ReactDOMServer.renderToStaticMarkup(
      <div>
        <h1>Demo Request</h1>
        <br />
        <h3>Contact Info:</h3>
        <p>
          Name: <span>{demoRequest.name}</span>
        </p>
        <p>
          Company Name: <span>{demoRequest.companyName}</span>
        </p>
        <p>
          Email: <span>{demoRequest.email}</span>
        </p>
        <p>
          Phone: <span>{demoRequest.phone}</span>
        </p>
        <p>
          Prefered Date: <span>{demoRequest.preferedDate || blankField}</span>
        </p>
        <p>
          Message: <span>{demoRequest.message || blankField}</span>
        </p>
        <br />
        <h3>Company Info:</h3>
        <p>
          Company Type: <span>{demoRequest.companyType || blankField}</span>
        </p>
        <p>
          State Footprint: <span>{demoRequest.stateFootprint.join(", ") || blankField}</span>
        </p>
        <p>
          Business Cities: <span>{demoRequest.bizCities || blankField}</span>
        </p>
        <p>
          Business Counties: <span>{demoRequest.bizCounties || blankField}</span>
        </p>
        <p>
          Title Production Systems: <span>{demoRequest.prodSystems.join(", ") || blankField}</span>
        </p>
        <p>
          POS System: <span>{demoRequest.posSystem || blankField}</span>
        </p>
        <p>
          LOS System: <span>{demoRequest.losSystem || blankField}</span>
        </p>
        <p>
          Title Agents: <span>{demoRequest.titleAgents || blankField}</span>
        </p>
        <p>
          Title Underwriters: <span>{demoRequest.titleUnderwriters || blankField}</span>
        </p>
        <p>
          Order Volume: <span>{demoRequest.orderVolume || blankField}</span>
        </p>
        <p>
          Industry Type: <span>{demoRequest.industryType || blankField}</span>
        </p>
      </div>,
    );
    return body;
  }

  static EmailBodyHomeEquity(demoRequest: DemoRequest): string {
    const blankField = "----------";

    const body = ReactDOMServer.renderToStaticMarkup(
      <div>
        <h1>Home Equity Decision for Lender Demo Request</h1>
        <br />
        <h3>Contact Info:</h3>
        <p>
          Name: <span>{demoRequest.name}</span>
        </p>
        <p>
          Company Name: <span>{demoRequest.companyName}</span>
        </p>
        <p>
          Email: <span>{demoRequest.email}</span>
        </p>
        <p>
          Phone: <span>{demoRequest.phone}</span>
        </p>
        <p>
          Prefered Date: <span>{demoRequest.preferedDate || blankField}</span>
        </p>
        <p>
          Message: <span>{demoRequest.message || blankField}</span>
        </p>
        <br />
      </div>,
    );
    return body;
  }
}
