import Badge from "components/common/badge";
import Divider from "components/common/divider";
import { Button } from "components/controls/button";
import { useParams } from "react-router";
import { JobProps } from "types/jobPosting";
import styles from "./styles.module.scss";
import ReactMarkdown from "react-markdown";
import { NormalComponents } from "react-markdown/lib/complex-types";
import { SpecialComponents } from "react-markdown/lib/ast-to-react";
import { useClipboard } from "utilities/hooks/useClipboard";
import { useEffect } from "react";
import { ReactComponent as IconCopy } from "images/icons/icon-copy.svg";
import { Tooltip } from "react-tooltip";
import { classNames } from "utilities/classNames";

const JobDetails = ({ jobs, loading }: JobProps) => {
  const { jobId } = useParams<{ jobId: string }>();
  const job = jobs.find((x) => x.sys.id === jobId);
  const noResults = !job && !loading;
  const { value: clipboardValue, setValue: setClipboardValue, hasCopied, onCopy } = useClipboard();

  if (loading) {
    return (
      <>
        {/* TODO JB - use a spinner? */} <p>Loading...</p>
      </>
    );
  }

  if (noResults || !job) {
    return (
      <>
        <p>Oops! No job found.</p>
      </>
    );
  }

  const {
    title,
    department,
    location,
    listingUrl,
    type,
    benefitTags,
    descriptionFull,
    duties,
    requirements,
    benefits,
    aboutFlueid,
  } = { ...job.fields };

  // listingUrl received, set as clipboard value
  useEffect(() => {
    if (listingUrl && !clipboardValue) {
      setClipboardValue(listingUrl);
    }
  }, [listingUrl, clipboardValue]);

  // custom elements for react-markdown to render
  const reactMarkdownComponents: Partial<Omit<NormalComponents, keyof SpecialComponents> & SpecialComponents> = {
    p: ({ children, className, ...props }) => (
      <p className={classNames(className, "p")} {...props}>
        {children}
      </p>
    ),
    // setting `ordered=false` here to avoid weird error warning for lists
    //https://github.com/remarkjs/remark/discussions/873
    ul: ({ children, className, ordered = false, ...props }) => (
      <ul className={classNames(styles.list, className)} {...props}>
        <p className="p">{children}</p>
      </ul>
    ),
  };

  return (
    <>
      <h2 className={styles.title}>{title}</h2>
      <p className={styles.location + " p"}>
        <i>{location}</i>
      </p>
      <div className={styles.btns}>
        <a href={listingUrl} target="_blank" rel="noreferrer">
          <Button className={classNames(styles.btn_apply, "btn-blue")}>Apply Now</Button>
        </a>
        <Button className={styles.btn_copy} onClick={onCopy} data-tooltip-id="btn-copy">
          <IconCopy width={"1.25rem"} height={"1.25rem"} />
        </Button>
        <Tooltip id="btn-copy" content={hasCopied ? "Copied!" : "Copy Job Posting Link to Clipboard"} />
      </div>
      <h5 className={styles.section_title}>Job Details</h5>
      <p className="p">
        {department} - {type}
      </p>
      <Divider className={styles.divider} />
      <h5 className={styles.section_title}>Benefits</h5>
      <div className={styles.tags}>
        {benefitTags?.map((tag) => (
          <Badge key={tag}>{tag}</Badge>
        ))}
      </div>
      <Divider className={styles.divider} />
      {descriptionFull && (
        <>
          <h5 className={styles.section_title}>Job Description</h5>
          <ReactMarkdown components={reactMarkdownComponents}>{descriptionFull}</ReactMarkdown>
        </>
      )}
      {duties && (
        <>
          <h5 className={styles.section_title}>Duties & Responsibilities</h5>
          <ReactMarkdown components={reactMarkdownComponents}>{duties}</ReactMarkdown>
        </>
      )}
      {requirements && (
        <>
          <h5 className={styles.section_title}>Requirements & Physical Demands</h5>
          <ReactMarkdown components={reactMarkdownComponents}>{requirements}</ReactMarkdown>
        </>
      )}
      {benefits && (
        <>
          <h5 className={styles.section_title}>Benefits</h5>
          <p className="p">As a {title}, you will be eligible for a comprehensive benefits package including:</p>
          <ReactMarkdown components={reactMarkdownComponents}>{benefits}</ReactMarkdown>
        </>
      )}
      {aboutFlueid && (
        <>
          <h5 className={styles.section_title}>About Flueid</h5>
          <ReactMarkdown components={reactMarkdownComponents}>{aboutFlueid}</ReactMarkdown>
        </>
      )}
    </>
  );
};

export default JobDetails;
