import React from "react";
import { NavLink, withRouter, RouteComponentProps, Link } from "react-router-dom";
import { Button } from "../controls/button";
import Popup, { PopupOption } from "../controls/popup";
import { HashLink } from "react-router-hash-link";
import { Link as ScrollLink } from "react-scroll";
import { PopupArrow } from "./popupArrow";
import { MobileLink } from "./mobileLink";
import { Hamburger } from "./hamburger";
import { setBodyOverflow } from "utilities/bodyOverflow";
import Collapse from "components/controls/collapse";
import { scrollWithOffset } from "utilities/scrollHelpers";
import Modal from "components/controls/modal";
import DemoFormModel from "components/demo/demoFormModel";
import { classNames } from "utilities/classNames";

function companyPageActive(path: string): boolean {
  return path === "/careers" || path === "/about" || path === "/media" ? true : false;
}

export type NavbarColor = "black" | "white" | "green" | "trans-black" | "trans-white" | undefined;
export const isNavBlackVariant = (navColor: NavbarColor) => navColor === "black" || navColor === "trans-black";
export const isNavWhiteVariant = (navColor: NavbarColor) => navColor === "white" || navColor === "trans-white";

interface OwnProps {
  className?: string;
  color?: NavbarColor;
  offsetLogo?: boolean;
  onMobileMenuOpen?: () => void;
  onMobileMenuClose?: () => void;
  useDemoFormModal?: boolean;
}

type ComponentProps = OwnProps & RouteComponentProps;

interface State {
  mobileMenuOpen: boolean;
  onHomePage: boolean;
  solutionsPopupOpen: boolean;
  companyPopupOpen: boolean;
  companyMobileOpen: boolean;
  modalOpen: boolean;
  hidden: boolean;
  atTop: boolean;
}

class Navbar extends React.Component<ComponentProps, State> {
  /** After scrolling down past this amount, navbar will hide */
  scrollThreshold = 50;
  prevScrollY = 0;

  /** Determines if current scroll position is down past threshold */
  scrolledDownPastThreshold = () => {
    return window.scrollY > this.prevScrollY && window.scrollY > this.scrollThreshold;
  };

  state: State = {
    mobileMenuOpen: false,
    onHomePage: false,
    solutionsPopupOpen: false,
    companyPopupOpen: false,
    companyMobileOpen: false,
    modalOpen: false,
    hidden: false,
    // important to always assume it's at page top, even though newly pushed to/linked to pages don't always start with window.scrollY === 0;
    // seems to be related to how react-router + browsers handle scroll restoration
    atTop: true,
  };

  componentDidMount() {
    if (this.props.location.pathname === "/") this.setState({ onHomePage: true });
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll = () => {
    const { hidden, atTop } = this.state;

    // scrolling down & past threshold, time to hide it
    if (this.scrolledDownPastThreshold() && !hidden && !atTop) {
      this.setState({ hidden: true });

      // scrolling up, time to show it
    } else if (window.scrollY < this.prevScrollY && hidden) {
      this.setState({ hidden: false });
    }

    // at page top or not?
    if (window.scrollY === 0 && !atTop) {
      this.setState({ atTop: true });
    } else if (window.scrollY > 0 && atTop) {
      this.setState({ atTop: false });
    }

    // always be sure to update the prevScrollY as last step
    this.prevScrollY = window.scrollY;
  };

  openMobileMenu = () => {
    setBodyOverflow("hidden");
    this.props.onMobileMenuOpen?.();
    this.setState({ mobileMenuOpen: true });
  };

  closeMobileMenu = () => {
    setBodyOverflow("auto");
    this.props.onMobileMenuClose?.();
    this.setState({ mobileMenuOpen: false, companyMobileOpen: false });
  };

  setModalClose = () => this.setState({ modalOpen: false });
  setModalOpen = () => this.setState({ modalOpen: true });

  platformLink = () => {
    const { onHomePage, mobileMenuOpen } = this.state;
    return (
      <MobileLink mobileMenuOpen={mobileMenuOpen} closeMobileMenu={this.closeMobileMenu}>
        {onHomePage ? (
          <ScrollLink className="navbar-btn" to="fueledByFlueid" smooth offset={100}>
            Platform
          </ScrollLink>
        ) : (
          <HashLink className="navbar-btn" to="/#fueledByFlueid" scroll={(el) => scrollWithOffset(el, 100)}>
            Platform
          </HashLink>
        )}
      </MobileLink>
    );
  };

  solutionsLink = () => {
    const { onHomePage, mobileMenuOpen } = this.state;
    return (
      <MobileLink mobileMenuOpen={mobileMenuOpen} closeMobileMenu={this.closeMobileMenu}>
        {onHomePage ? (
          <ScrollLink className="navbar-btn" to="integrations" smooth offset={100}>
            Integrations
          </ScrollLink>
        ) : (
          <HashLink className="navbar-btn" to="/#integrations" scroll={(el) => scrollWithOffset(el, 100)}>
            Integrations
          </HashLink>
        )}
      </MobileLink>
    );
  };

  securityLink = () => {
    const { mobileMenuOpen } = this.state;
    return (
      <MobileLink mobileMenuOpen={mobileMenuOpen} closeMobileMenu={this.closeMobileMenu}>
        <NavLink className="navbar-btn" to="/security">
          Security
        </NavLink>
      </MobileLink>
    );
  };

  companyLinks = () => {
    const { location, color } = this.props;
    const { companyPopupOpen } = this.state;
    return (
      <Popup
        className={`navbar-btn ${companyPageActive(location.pathname) ? "active" : ""}`}
        open={companyPopupOpen}
        onOpen={() => this.setState({ companyPopupOpen: true })}
        onClose={() => this.setState({ companyPopupOpen: false })}
        trigger={<div className="flex flex-center">Company {PopupArrow(companyPopupOpen, color)}</div>}
      >
        <PopupOption>
          <Link to="/about">About</Link>
        </PopupOption>
        <PopupOption>
          <Link to="/media">Media</Link>
        </PopupOption>
        <PopupOption>
          <Link to="/careers">Careers</Link>
        </PopupOption>
      </Popup>
    );
  };

  companyMobileLinks = () => {
    const { location, color } = this.props;
    const { mobileMenuOpen, companyMobileOpen } = this.state;
    return (
      <div>
        <div
          onClick={() => this.setState({ companyMobileOpen: !companyMobileOpen })}
          className={`navbar-btn ${companyPageActive(location.pathname) ? "active" : ""}`}
        >
          Company {PopupArrow(companyMobileOpen, color)}
        </div>
        <Collapse open={companyMobileOpen} className="companyMobileLinks-padding flex flex-col">
          <MobileLink mobileMenuOpen={mobileMenuOpen} closeMobileMenu={this.closeMobileMenu}>
            <Link className="navbar-btn" to="/about">
              About
            </Link>
          </MobileLink>
          <MobileLink mobileMenuOpen={mobileMenuOpen} closeMobileMenu={this.closeMobileMenu}>
            <Link className="navbar-btn" to="/media">
              Media
            </Link>
          </MobileLink>
          <MobileLink mobileMenuOpen={mobileMenuOpen} closeMobileMenu={this.closeMobileMenu}>
            <Link className="navbar-btn" to="/careers">
              Careers
            </Link>
          </MobileLink>
        </Collapse>
      </div>
    );
  };

  contactLink = () => {
    const { mobileMenuOpen } = this.state;
    return (
      <MobileLink mobileMenuOpen={mobileMenuOpen} closeMobileMenu={this.closeMobileMenu}>
        <NavLink className="navbar-btn" to="/contact">
          Contact
        </NavLink>
      </MobileLink>
    );
  };

  demoLink = () => {
    const { color, useDemoFormModal } = this.props;
    const { mobileMenuOpen } = this.state;
    const btnClass: string = isNavBlackVariant(color) || color === "green" ? "btn-darkGray" : "btn-white";
    const linkClass: string = `navbar-btn ${(mobileMenuOpen && "navbar-btn_demoMobile") || ""}`;

    return (
      <>
        {useDemoFormModal ? (
          <Button onClick={this.setModalOpen}>Request a Demo</Button>
        ) : (
          <MobileLink mobileMenuOpen={mobileMenuOpen} closeMobileMenu={this.closeMobileMenu}>
            <NavLink className={linkClass} to="/demo">
              <Button className={btnClass}>Schedule a Demo</Button>
            </NavLink>
          </MobileLink>
        )}
      </>
    );
  };

  renderMobileMenu = () => {
    const { mobileMenuOpen } = this.state;
    return (
      <div className={`navbar-mobileMenu ${mobileMenuOpen ? "open" : "closed"} flex flex-justify-center`}>
        <div className="navbar-mobileMenu_width flex flex-col flex-align-start">
          {this.platformLink()}
          {this.solutionsLink()}
          {this.securityLink()}
          {this.companyMobileLinks()}
          {this.contactLink()}
          {this.demoLink()}
        </div>
      </div>
    );
  };

  render() {
    const { className, history, color, offsetLogo } = this.props;
    const { hidden, atTop, onHomePage, mobileMenuOpen, modalOpen } = this.state;
    const scrollingUp = !hidden && !atTop;

    const isBlackOrGreenVariant = isNavBlackVariant(color) || color === "green";
    const logoClass = classNames({
      logo: true,
      "logo-flueid-white": isBlackOrGreenVariant,
      "logo-flueid": !isBlackOrGreenVariant,
      "logo-offset": !!offsetLogo && atTop && !mobileMenuOpen,
    });

    const navContainerClasses = classNames(
      "navbar-container",
      color,
      className,
      { "navbar-hidden": hidden },
      { "navbar-box-shadow-none": atTop },
      { "navbar-scrolling": scrollingUp },
    );

    return (
      <div className={navContainerClasses}>
        <Modal open={modalOpen} onClose={() => this.setModalClose()}>
          <DemoFormModel />
        </Modal>
        <div className="navbar-content">
          <div
            className={logoClass}
            onClick={() => {
              if (mobileMenuOpen) this.closeMobileMenu();
              onHomePage ? window.scrollTo({ top: 0, behavior: "smooth" }) : history.push("/");
            }}
          />
          {!mobileMenuOpen && (
            <div className="btns-container">
              {this.platformLink()}
              {this.solutionsLink()}
              <div className="navbar-btn-container">
                {this.securityLink()}
                <div className="navbar-btn-border-bottom" />
              </div>
              <div className="line-separator" />
              <div className="navbar-btn-container">
                {this.companyLinks()}
                <div className="navbar-btn-border-bottom" />
              </div>
              <div className="navbar-btn-container">
                {this.contactLink()}
                <div className="navbar-btn-border-bottom" />
              </div>
              <div className="line-separator" />
              {this.demoLink()}
            </div>
          )}
          <Hamburger open={mobileMenuOpen} handleClose={this.closeMobileMenu} handleOpen={this.openMobileMenu} />
          {this.renderMobileMenu()}
        </div>
      </div>
    );
  }
}

export default withRouter(Navbar);
