import { Entry } from "contentful";
import { createContext, useContext } from "react";
import contentfulClient from "utilities/contentfulClient";
import { IThoughtLeadershipSlide } from "./thoughtLeadershipSlide";

export interface IThoughtLeadershipSection {
  name?: string;
  slug?: string;
  backgroundColor?: string;
  textColor?: string;
  bigQuoteColor?: string;
  linkColor?: string;
  slides?: Entry<IThoughtLeadershipSlide>[];
}

export const THOUGHT_LEADERSHIP_SECTION_CONTENT_TYPE_ID = "flueidThoughtLeadershipSection";

export const getThoughtLeadershipSections = () =>
  contentfulClient.getEntries<IThoughtLeadershipSection>({
    content_type: THOUGHT_LEADERSHIP_SECTION_CONTENT_TYPE_ID,
  });

export const ThoughtLeadershipSectionsContext = createContext<Entry<IThoughtLeadershipSection>[]>([]);

export const useThoughtLeadershipSections = () => useContext(ThoughtLeadershipSectionsContext);
