import React from "react";
import { RouteComponentProps } from "react-router-dom";
import DemoRequest, { CompanyType, companyTypeOptions, DemoRequestKey } from "../../types/demoRequest";
import { allSchemaFieldsValid, setValidatedFieldTrue, validateSchema, ValidationSchema } from "../../types/validation";
import { Button } from "../controls/button";
import Input from "../controls/input";
import { withRouter } from "react-router-dom";
import { ContactTopic, getEmailRecipient, isContactTopic } from "../../types/contactUsRequest";
import { ActionMeta, OptionTypeBase } from "react-select";
import { hasKey, mapParsedValues } from "../../utilities/tsHelpers";
import TextArea from "../controls/textArea";
import * as QueryString from "query-string";
import { validation, validationDefault } from "./validation";
import { CompanyFields, InputFlex } from "./companyFields";
import { OverflowContainer } from "components/controls/overflow";
import { Email, EmailResponse, StatusCodes } from "types/email";
import { SubNavbar } from "components/navbar/subNavbar";
import { ReqStatus } from "types/reqStatus";
import Modal from "components/controls/modal";
import { ContactFailMessage, ContactOkMessage } from "components/contact/contactForm";
import RouteChangeGuard from "components/controls/routeChangeGuard";
import { Location } from "history";
import isEqual from "lodash.isequal";
import Transition from "components/controls/transition";
import { API_URL, callEmailHandler, defaultFetchOptions } from "utilities/apiService";

interface State {
  changesMade: boolean;
  demoRequest: DemoRequest;
  modalOpen: boolean;
  reqStatus: ReqStatus;
  showCompanyTypeOptions: boolean;
  topic: ContactTopic;
  validation: ValidationSchema<DemoRequest>;
}

class DemoPageContainer extends React.Component<RouteComponentProps, State> {
  defaultState: State = {
    demoRequest: { ...new DemoRequest() },
    validation: { ...validationDefault },
    topic: ContactTopic.Demo,
    reqStatus: new ReqStatus(),
    modalOpen: false,
    showCompanyTypeOptions: true,
    changesMade: false,
  };

  state: State = { ...this.defaultState };

  componentDidMount() {
    const { location } = this.props;
    // see if any query params exist to determine topic
    if (location.search) {
      // parse values
      const queryParams = new URLSearchParams(location.search);
      const topic: string | null = queryParams.get("topic");
      const parsedParams = QueryString.parse(location.search);
      const demoRequest = mapParsedValues<DemoRequest>(new DemoRequest(), parsedParams);

      // set default demoRequest to include any query param values, otherwise isEqual comparison in componentDidUpdate will
      // compare an empty demoRequest to one with values and consider changesMade true. So if the user clicks back without manually changing the form
      // they will be prompted to confirm changes by the RouteChangeGuard.
      this.defaultState.demoRequest = { ...demoRequest };

      this.setState({
        topic: isContactTopic(topic) ? topic : ContactTopic.Demo,
        demoRequest,
      });
    }
  }

  componentDidUpdate(prevProps: RouteComponentProps, prevState: State) {
    const { demoRequest, changesMade } = this.state;

    // changes made
    if (!isEqual(this.defaultState.demoRequest, demoRequest) && !changesMade) {
      this.setState({ changesMade: true });
    }

    // changes made turned off
    if (isEqual(this.defaultState.demoRequest, demoRequest) && changesMade) {
      this.setState({ changesMade: false });
    }

    // company type changes, reset all company info related fields and preserve all contact info related fields
    if (demoRequest.companyType && prevState.demoRequest.companyType !== demoRequest.companyType) {
      this.setState({
        ...this.state,
        demoRequest: {
          ...new DemoRequest(),
          companyType: demoRequest.companyType,
          name: demoRequest.name,
          companyName: demoRequest.companyName,
          email: demoRequest.email,
          phone: demoRequest.phone,
          preferedDate: demoRequest.preferedDate,
          message: demoRequest.message,
        },
        showCompanyTypeOptions: false,
        validation: validation[demoRequest.companyType] || this.state.validation,
      });
    }
  }

  handleInputChange = (name: DemoRequestKey, value: string) => {
    const { validation, demoRequest } = this.state;

    this.setState({
      ...this.state,
      demoRequest: {
        ...demoRequest,
        [name]: value,
      },
      validation: validation[name]?.valid === false ? setValidatedFieldTrue<DemoRequest>(validation, name) : validation,
    });
  };

  handleDropdownChange = (data: any, options: ActionMeta<OptionTypeBase>) => {
    const { demoRequest, validation } = this.state;

    if (options.name && hasKey(demoRequest, options.name)) {
      this.setState({
        ...this.state,
        demoRequest: {
          ...demoRequest,
          [options.name]: Array.isArray(data) ? data.map((x) => x.value) : data.value,
        },
        validation:
          validation[options.name]?.valid === false
            ? setValidatedFieldTrue<DemoRequest>(validation, options.name)
            : validation,
      });
    }
  };

  handleCheckboxChange = (name: DemoRequestKey, value: string[]) => {
    const { validation } = this.state;
    this.setState({
      ...this.state,
      demoRequest: {
        ...this.state.demoRequest,
        [name]: value,
      },
      validation: validation[name]?.valid === false ? setValidatedFieldTrue<DemoRequest>(validation, name) : validation,
    });
  };

  handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    // validate
    const { demoRequest, validation, reqStatus } = this.state;

    // don't allow extra smashes while request pending
    if (reqStatus.pending) return;

    const v = validateSchema<DemoRequest>(demoRequest, validation);
    const allFieldsValid = allSchemaFieldsValid<DemoRequest>(v);

    if (allFieldsValid) {
      // set reqStatus pending
      this.setState({ reqStatus: ReqStatus.Pending() });

      // construct email and fire request
      const email: Email = {
        to: [getEmailRecipient(this.state.topic)],
        subject: "Flueid Corp Site Demo Request",
        text: DemoRequest.EmailBody(demoRequest),
      };

      callEmailHandler(email)
        .then((data) => data.json())
        .then((resp: EmailResponse) => {
          if (resp.statusCode === StatusCodes.Ok) {
            // reset defaultState.demoRequest to a fresh demoRequest since it might of had queryParams values in componentDidMount
            this.defaultState.demoRequest = { ...new DemoRequest() };
            this.setState({
              ...this.defaultState,
              modalOpen: true,
              reqStatus: ReqStatus.Ok(),
            });
          } else {
            console.log(resp);
            this.setState({
              modalOpen: true,
              reqStatus: ReqStatus.Failed(),
            });
          }
        })
        .catch((err) => {
          console.log(err);
          this.setState({
            modalOpen: true,
            reqStatus: ReqStatus.Failed(),
          });
        });
    } else {
      this.setState({ validation: v });
    }
  };

  modalOpen = () => this.setState({ modalOpen: true });
  modalClose = () => this.setState({ modalOpen: false });

  render() {
    const { history, location } = this.props;

    const { demoRequest, topic, validation, reqStatus, modalOpen, showCompanyTypeOptions, changesMade } = this.state;

    const companyFields = CompanyFields({
      demoRequest,
      validation,
      handleDropdownChange: this.handleDropdownChange,
      handleInputChange: this.handleInputChange,
      handleCheckboxChange: this.handleCheckboxChange,
    });

    return (
      <OverflowContainer>
        <Transition visible animation="fadeIn">
          <div className="demoPage-container">
            <SubNavbar title={topic === ContactTopic.Meeting ? "Request a Meeting" : "Request a Demo"} />
            <div className="form-container">
              <div className="maxWidth">
                <div className="subheader-1 txt-bold">
                  {topic === ContactTopic.Meeting ? "Meeting Request" : "Product Demo"}
                </div>
                <div className="p-3">* = Required</div>
                <div className="p-1">What type of company do you work for?</div>
                {!showCompanyTypeOptions ? (
                  <div>
                    <Button className="btn-black companyTypeBtn-maxWidth cursor-auto margin-auto margin-bottom_sm">
                      {demoRequest.companyType}
                    </Button>
                    <div
                      className="change-selection txt-underline"
                      onClick={() => this.setState({ showCompanyTypeOptions: true })}
                    >
                      Change Selection
                    </div>
                  </div>
                ) : (
                  <div className="demoRequestOptions-grid">
                    {companyTypeOptions
                      .filter((o) => o)
                      .map((x: CompanyType, i: number) => (
                        <Button
                          className={`${
                            demoRequest.companyType === x ? "btn-black" : "btn-white btn-white_borderGray"
                          }`}
                          key={`companyType-${i}`}
                          onClick={() => this.setState({ demoRequest: { ...demoRequest, companyType: x } })}
                        >
                          {x}
                        </Button>
                      ))}
                  </div>
                )}
                {demoRequest.companyType && (
                  <form onSubmit={this.handleSubmit}>
                    <div className="p-1">Contact Information</div>
                    <InputFlex>
                      <Input<DemoRequestKey>
                        type="text"
                        name="name"
                        label="Your Name:"
                        placeHolder="Full Name"
                        value={demoRequest.name}
                        onChange={this.handleInputChange}
                        inputVersion="v2"
                        required
                        valid={validation?.name?.valid}
                        errorMessages={!validation?.name?.valid ? ["Please enter Name"] : undefined}
                      />
                      <Input<DemoRequestKey>
                        type="text"
                        name="companyName"
                        label="Company Name:"
                        placeHolder="Company Name"
                        value={demoRequest.companyName}
                        onChange={this.handleInputChange}
                        inputVersion="v2"
                        required
                        valid={validation?.companyName?.valid}
                        errorMessages={!validation?.companyName?.valid ? ["Please enter Company Name"] : undefined}
                      />
                    </InputFlex>
                    <InputFlex>
                      <Input<DemoRequestKey>
                        type="text"
                        name="email"
                        label="Email Address:"
                        placeHolder="Email"
                        value={demoRequest.email}
                        onChange={this.handleInputChange}
                        inputVersion="v2"
                        required
                        valid={validation?.email?.valid}
                        errorMessages={!validation?.email?.valid ? ["Please enter valid Email"] : undefined}
                      />
                      <Input<DemoRequestKey>
                        type="phone"
                        name="phone"
                        label="Phone Number:"
                        placeHolder="Phone"
                        value={demoRequest.phone}
                        onChange={this.handleInputChange}
                        inputVersion="v2"
                        required
                        valid={validation?.phone?.valid}
                        errorMessages={!validation?.phone?.valid ? ["Please enter valid Phone Number"] : undefined}
                      />
                    </InputFlex>
                    <InputFlex>
                      <Input<DemoRequestKey>
                        type="date"
                        name="preferedDate"
                        label="Preferred Date: "
                        placeHolder="Select Date"
                        value={demoRequest.preferedDate}
                        onChange={this.handleInputChange}
                        inputVersion="v2"
                      />
                    </InputFlex>
                    <InputFlex>
                      <TextArea<DemoRequestKey>
                        type="text"
                        name="message"
                        label="Message: "
                        placeHolder="Optional Message"
                        value={demoRequest.message}
                        onChange={this.handleInputChange}
                        inputVersion="v2"
                      />
                    </InputFlex>
                    {companyFields && (
                      <>
                        <div className="p-1">Company Information</div>
                        {companyFields}
                      </>
                    )}
                    <Button className="btn-black submit-btn_margin" type="submit" onSubmit={this.handleSubmit}>
                      Send Info
                    </Button>
                  </form>
                )}
              </div>
            </div>
          </div>
        </Transition>
        <Modal open={modalOpen} onOpen={this.modalOpen} onClose={this.modalClose}>
          {reqStatus.ok ? <ContactOkMessage /> : <ContactFailMessage />}
          <Button onClick={this.modalClose}>Got it</Button>
        </Modal>
        <RouteChangeGuard
          when={changesMade}
          navigate={(path: string) => history.push(path)}
          shouldBlockNavigation={(newLocation: Location) => {
            // if changes are made to form and the newLocation doesn't equal our current location, block navigation
            return changesMade && newLocation.pathname !== location.pathname ? true : false;
          }}
        />
      </OverflowContainer>
    );
  }
}

export default withRouter(DemoPageContainer);
