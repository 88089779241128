import Modal from "components/controls/modal";
import React from "react";
import Slider, { Settings } from "react-slick";
import { EquityGraph, graph } from "./graphCard";
import DemoFormModel from "components/demo/demoFormModel";

interface GraphCardProps {
  graph: EquityGraph;
}

interface State {
  modalOpen: boolean;
  scrollcards: HTMLElement[] | null;
  currentIndex: number;
}

// modern Chrome requires { passive: false } when adding event
var supportsPassive = false;
try {
  window.addEventListener(
    "test",
    () => {},
    Object.defineProperty({}, "passive", {
      get: function () {
        supportsPassive = true;
      },
    }),
  );
} catch (e) {}

var wheelOpt = supportsPassive ? { passive: false } : false;
var wheelEvent = "onwheel" in document.createElement("div") ? "wheel" : "mousewheel";

//scroll timeout setting
var scrollTimeout: NodeJS.Timeout | undefined;

const GraphCard = ({ graph }: GraphCardProps) => (
  <div className="loss-mit-container">
    <div className="graph-header">Become a trusted counselor</div>
    <div className="graph-header-sub">
      With a single report, consult with your borrower to understand their goals and pursue a reperforming loan or an
      efficient liquidation to avoid costly foreclosure whenever possible.
    </div>

    <div className="graph-card-container">
      <div
        className="graph-img-desktop"
        style={{
          backgroundImage: `url(/img/graphics/${graph.img})`,
          width: `770px`,
          height: `642px`,
          backgroundRepeat: `no-repeat`,
          margin: `0 auto`,
          backgroundSize: `cover`,
        }}
      ></div>
      <div
        className="graph-img-mobile"
        style={{
          backgroundImage: `url(/img/graphics/${graph.imgMobile})`,
          width: `60vw`,
          height: `105vw`,
          backgroundRepeat: `no-repeat`,
          margin: `0 auto`,
          marginBottom: `50px`,
          backgroundSize: `contain`,
        }}
      ></div>

      {graph.quote1 && <div className="graph-text-loss-mit para1">{graph.quote1}</div>}
      {graph.quote2 && <div className="graph-text-loss-mit para2">{graph.quote2}</div>}
      {graph.quote3 && <div className="graph-text-loss-mit para3">{graph.quote3}</div>}
      {graph.quote4 && <div className="graph-text-loss-mit para4">{graph.quote4}</div>}
      {graph.quote5 && <div className="graph-text-loss-mit para4">{graph.quote5}</div>}
    </div>
  </div>
);

interface Props {}

class GraphSectionHomeEquity extends React.Component<Props> {
  state: State = {
    modalOpen: false,
    scrollcards: [],
    currentIndex: -1,
  };

  slider: Slider | null = null;

  setModalOpen = () => this.setState({ modalOpen: true });
  SetModalClose = () => this.setState({ modalOpen: false });

  scrollCardsActive = (offset: number) => {
    let rect = document.getElementById("scroll-sections")?.getBoundingClientRect();
    //console.log(document.getElementById("scroll-sections")?.getBoundingClientRect(), offset, document.documentElement.clientHeight, document.documentElement.scrollTop)

    return rect && rect.top - offset < document.documentElement.clientHeight && rect.bottom > 0;
  };

  scrollEventListener = (e: any) => {
    e.preventDefault();

    //wait for the scroll timeout to lapse
    if (scrollTimeout) return;

    //check scrolling direction
    let scrollUp = e.deltaY < 0;
    let scrollDown = e.deltaY > 0;

    if (this.scrollCardsActive(e.deltaY)) {
      let nextCardIndex = e.deltaY > 0 ? this.state.currentIndex + 1 : this.state.currentIndex - 1;

      let scrollLocation;

      if (this.state.scrollcards) {
        let stickySectionRect = document.getElementById("sticky-section")?.getBoundingClientRect();
        let graphSectionRect = document.getElementById("graphsection")?.getBoundingClientRect();
        let graphEndMarker = document.getElementById("graph-end-marker");

        if (scrollDown && nextCardIndex >= this.state.scrollcards?.length) {
          //console.log('scrolltobottom')
          if (graphSectionRect && stickySectionRect && graphEndMarker) scrollLocation = graphEndMarker?.offsetTop;

          this.setState({
            currentIndex:
              nextCardIndex >= this.state.scrollcards?.length ? this.state.scrollcards?.length : nextCardIndex, //do not overflow the last card index
          });
        } else if (scrollUp && nextCardIndex < 0) {
          //console.log('scrolltotop')
          if (stickySectionRect) scrollLocation = stickySectionRect.bottom; // - window.innerHeight
          this.setState({ currentIndex: 0 });
        } else {
          //get next card location
          scrollLocation = this.state.scrollcards && this.state.scrollcards[nextCardIndex]?.getBoundingClientRect().top;
          scrollLocation = window.pageYOffset + scrollLocation - window.innerHeight / 2 - 300;
          this.setState({ currentIndex: nextCardIndex });

          scrollTimeout = setTimeout(() => (scrollTimeout = undefined), 1000);
        }
      }
      //console.log('scrolling to '+scrollLocation, nextCardIndex)

      //scroll to that location
      scrollLocation && window.scrollTo({ top: scrollLocation, behavior: "smooth" });

      //apply active style to cards
      this.state.scrollcards?.forEach((el, idx) => {
        if (idx == nextCardIndex) {
          this.slider?.slickGoTo(nextCardIndex);
          el?.classList.add("active");
        } else {
          el?.classList.remove("active");
        }
      });
    } else {
      window.scrollBy(0, e.deltaY);
    }
  };

  //for mobile version
  checkScrollcardLocation = (e: any) => {
    if (window.innerWidth <= 600) {
      //this logic is for mobile version
      this.state.scrollcards?.forEach((el, idx) => {
        if (
          el?.getBoundingClientRect().top &&
          el?.getBoundingClientRect().top < window.innerHeight &&
          el?.getBoundingClientRect().top < 800 &&
          el?.getBoundingClientRect().top > 10
        ) {
          el?.classList.add("active"); //set clidecard as achive and make it non-opaque
          this.slider?.slickGoTo(idx);
        } else {
          el?.classList.remove("active");
        }
      });
    }
  };

  //component mount event
  componentDidMount = () => {
    //fing all scrollcards
    let scrollcards = graph.map((itm, index) => document.getElementById("scrollcard-" + index));

    this.setState({ scrollcards });

    //add event listener for scrolling event so that we check the location of the scrollcards and highlight them (change opacity) as necessary
    window.addEventListener(wheelEvent, this.scrollEventListener, wheelOpt);
    window.addEventListener("scroll", this.checkScrollcardLocation);

    //document.body.style.overflow="hidden"
    document.body.style.scrollBehavior = "smooth";
  };

  componentWillUnmount = () => {
    //remove event listener for scrolling event
    window.removeEventListener(wheelEvent, this.scrollEventListener);
    window.removeEventListener("scroll", this.checkScrollcardLocation);

    document.body.style.overflow = "unset";
  };

  render() {
    const sliderSettings: Settings = {
      arrows: false,
      fade: true,
      lazyLoad: "ondemand",
      responsive: [
        {
          breakpoint: 768,
          settings: {
            dots: false,
          },
        },
      ],
    };

    return (
      <div className="loss-mit-container">
        <div className="graph-section" id="graphsection">
          <div id="sticky-section">
            <div className="header-text"></div>
            <div className="main-content-container">
              <Slider ref={(c) => (this.slider = c)} {...sliderSettings}>
                {graph.map((x, i) => (
                  <GraphCard key={`${i}`} graph={x} />
                ))}
              </Slider>
            </div>
          </div>

          {
            <div id="scroll-sections">
              {graph.map((itm, index) => (
                <div className="scroll-item" key={"scrollcard-" + index} id={"scrollcard-" + index}>
                  {itm.quote1 && <div className="graph-text-loss-mit">{itm.quote1}</div>}
                  {itm.quote2 && <div className="graph-text-loss-mit">{itm.quote2}</div>}
                  {itm.quote3 && <div className="graph-text-loss-mit">{itm.quote3}</div>}
                  {itm.quote4 && <div className="graph-text-loss-mit">{itm.quote4}</div>}
                  {itm.quote5 && <div className="graph-text-loss-mit">{itm.quote5}</div>}
                </div>
              ))}
            </div>
          }
        </div>

        <div id="graph-end-marker"></div>

        <Modal open={this.state.modalOpen} onClose={() => this.SetModalClose()}>
          <DemoFormModel />
        </Modal>
      </div>
    );
  }
}

export default GraphSectionHomeEquity;
