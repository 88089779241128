import Divider from "components/common/divider";
import { SectionContent } from "components/common/sectionContent";
import graphicRating from "../../images/graphics/graphic-rating.png";

const ProductLifecycleSection = () => {
  return (
    <section className="sec-prod-lifecycle-section">
      <SectionContent>
        <div className="sec-lifecycle-expertise-grid">
          <img src={graphicRating} alt="Cyber Rating Graphic" className="cyber-rating" />
          <Divider vertical spacing="lg" />
          <div>
            <h3>
              <b>Security Guides</b> Our Product Life Cycle
            </h3>
            <p className="p-1">
              <b>
                At an operational level, we prioritize security and trust across elements of the product design,
                inception, creation and optimization.
              </b>{" "}
              Our engineering, development, architecture, and business-level teams accept and prioritize these key
              principles, achieving an overall “A” cyber rating across the safeguard, privacy, resiliency and reputation
              categories.
            </p>
          </div>
        </div>
      </SectionContent>
    </section>
  );
};

export default ProductLifecycleSection;
