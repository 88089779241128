import React from "react";

interface PopupOptionProps<K extends string, T extends string> {
  name?: K;
  value?: T;
  onClick?: (name?: K, value?: T) => void;
  children?: React.ReactNode;
}

export const PopupOption = <K extends string, T extends string>({
  name,
  children,
  onClick,
  value,
}: React.PropsWithChildren<PopupOptionProps<K, T>>) => {
  return (
    <div className="popup-option" onClick={onClick ? () => onClick(name, value) : undefined}>
      {children}
    </div>
  );
};

interface Props {
  trigger: React.ReactNode;
  open?: boolean;
  className?: string;
  onOpen?: () => void;
  onClose?: () => void;
}

interface State {
  open: boolean;
}

class Popup extends React.Component<Props, State> {
  ref = React.createRef<HTMLDivElement>();
  triggerRef = React.createRef<HTMLDivElement>();

  state: State = {
    open: false,
  };

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClick);
  }

  componentDidUpdate(prevProps: Props) {
    const { open } = this.props;

    //parent state closes
    if (prevProps.open === true && open === false) {
      if (this.state.open) this.setState({ open: false });
    }

    // parent state opens
    if (prevProps.open === false && open === true) {
      if (!this.state.open) this.setState({ open: true });
    }
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClick);
  }

  open = () => {
    if (this.state.open) return;
    this.setState({ open: true }, () => {
      if (this.props.onOpen && !this.props.open) this.props.onOpen();
    });
  };

  close = () => {
    if (!this.state.open) return;
    this.setState({ open: false }, () => {
      if (this.props.onClose && this.props.open) this.props.onClose();
    });
  };

  handleClick = (e: any) => {
    const ref = this.ref.current;
    const trigger = this.triggerRef.current;

    if (ref && !ref.contains(e.target) && trigger && !trigger.contains(e.target) && this.state.open) {
      this.close();
    }
  };

  render() {
    const { trigger, className, children } = this.props;

    return (
      <div className={`ui-popup-container ${className || ""}`}>
        <div
          className="popup-trigger"
          ref={this.triggerRef}
          onClick={(e: React.MouseEvent) => {
            this.state.open ? this.close() : this.open();
          }}>
          {trigger}
        </div>
        <div className={`popup ${this.state.open ? "open" : "closed"}`} ref={this.ref}>
          {children}
        </div>
      </div>
    );
  }
}

export default Popup;
