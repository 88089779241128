import { SectionContent } from "components/common/sectionContent";
import Transition from "components/controls/transition";
import DashedLineGroup, { DashedLine } from "../controls/dashedLines";
import Visible from "../controls/visible";

export const DiscoverSection = () => (
  <section className="discover-section">
    <SectionContent maxWidthType="section-maxWidth-small">
      <div className="discover-header-flex">
        <div>
          <div className="masonry-hero-careers">
            <div className="masonry-column">
              <div className="masonry-top-row">
                <Visible>
                  {(state) => (
                    <Transition animation={"fadeInUp"} duration={1000} delay={200} visible={state.visible}>
                      <div ref={state.ref} className="img-careers-middle1 img-careers-masonry"></div>
                    </Transition>
                  )}
                </Visible>
              </div>
            </div>
            <div className="masonry-rows">
              <div className="masonry-row-container left-masonry-row">
                <Visible>
                  {(state) => (
                    <Transition animation={"fadeInUp"} duration={1000} delay={500} visible={state.visible}>
                      <div ref={state.ref} className="img-careers-right2 img-careers-masonry"></div>
                    </Transition>
                  )}
                </Visible>
                <Visible>
                  {(state) => (
                    <Transition animation={"fadeInUp"} duration={1000} delay={500} visible={state.visible}>
                      <div ref={state.ref} className="img-careers-middle2 img-careers-masonry"></div>
                    </Transition>
                  )}
                </Visible>
              </div>
              <div className="masonry-row-container">
                <Visible>
                  {(state) => (
                    <Transition animation={"fadeInUp"} duration={1000} delay={300} visible={state.visible}>
                      <div ref={state.ref} className="img-careers-left img-careers-masonry"></div>
                    </Transition>
                  )}
                </Visible>
                <Visible>
                  {(state) => (
                    <Transition animation={"fadeInUp"} duration={1000} delay={300} visible={state.visible}>
                      <div ref={state.ref} className="img-careers-right1 img-careers-masonry"></div>
                    </Transition>
                  )}
                </Visible>
              </div>
            </div>
          </div>
        </div>
        <div className="core-text-right">
          <div className="discover-bold-header discover-bold-header-main">AT THE CORE</div>
          <p className="para-padding-bottom">
            We act boldly and intentionally in pursuing our goals. We embrace the free flow of thought and encourage
            creativity because we know the value curiosity brings to all aspects of our business.{" "}
          </p>
          <p className="para-padding-bottom">
            We build on one another’s strengths and advocate for one another’s pursuits. We prioritize building
            relationships rooted in deep trust and mutual respect to foster empowerment and growth.
          </p>
          <p className="para-padding-bottom">
            And we are focused. We are unified in achieving our ambitions all while having fun together. This is the
            foundation from which we build. It’s what allows us to innovate and deliver real value to ourselves and to
            our partners.
          </p>
        </div>
      </div>
      <div className="at-the-core-horizontal"></div>

      <div className="flex mobile-top-margin">
        <div className="intentional-section-group-margin discover-body-text-mobile">
          <div className="">
            <Visible>
              {(state) => (
                <Transition animation={"fadeInLeft"} duration={1000} delay={100} visible={state.visible}>
                  <div ref={state.ref} className="discover-bold-header">
                    BE <span className="discover-bold-header-small">INTENTIONAL</span>
                  </div>
                </Transition>
              )}
            </Visible>
            <Visible>
              {(state) => (
                <Transition animation={"fadeInLeft"} duration={1000} delay={300} visible={state.visible}>
                  <p ref={state.ref} className="p-width_sm discover-body-text-mobile">
                    We are purposeful. We believe in our mission. We relentlessly pursue excellence in all that we do.
                  </p>
                </Transition>
              )}
            </Visible>
          </div>
          <Visible>
            {(state) => (
              <Transition animation={"fadeInLeft"} duration={1000} delay={100} visible={state.visible}>
                <div ref={state.ref} className="discover-bold-header">
                  ACT WITH <span className="discover-bold-header-small">CONVICTION</span>
                </div>
              </Transition>
            )}
          </Visible>
          <Visible>
            {(state) => (
              <Transition animation={"fadeInLeft"} duration={1000} delay={300} visible={state.visible}>
                <p ref={state.ref} className="p-width_sm">
                  We are bold and determined. We confidently and courageously push forward on our ambitions.
                </p>
              </Transition>
            )}
          </Visible>
          <Visible>
            {(state) => (
              <Transition animation={"fadeInLeft"} duration={1000} delay={100} visible={state.visible}>
                <div ref={state.ref} className="discover-bold-header">
                  FOSTER <span className="discover-bold-header-small">COLLABORATION</span>
                </div>
              </Transition>
            )}
          </Visible>
          <Visible>
            {(state) => (
              <Transition animation={"fadeInLeft"} duration={1000} delay={300} visible={state.visible}>
                <p ref={state.ref} className="p-width_sm">
                  We are united. We learn and discover together. We partner to uncover the best possible path forward.
                </p>
              </Transition>
            )}
          </Visible>
        </div>
        <div className="img-right-container">
          <div className="discover-img-right"></div>
        </div>
      </div>
      <div className="flex flex-mobile-reverse">
        <div className="img-left-container">
          <div className="discover-img-left"></div>
        </div>
        <div className="discover-body-text-mobile">
          <Visible>
            {(state) => (
              <Transition animation={"fadeInLeft"} duration={1000} delay={100} visible={state.visible}>
                <div ref={state.ref} className="discover-bold-header">
                  BUILD <span className="discover-bold-header-small">SPIRIT</span>
                </div>
              </Transition>
            )}
          </Visible>
          <Visible>
            {(state) => (
              <Transition animation={"fadeInLeft"} duration={1000} delay={300} visible={state.visible}>
                <p ref={state.ref} className="p-width_sm">
                  We have fun together. We have love and care for one another. We are passionate about what we do each
                  day.
                </p>
              </Transition>
            )}
          </Visible>
          <Visible>
            {(state) => (
              <Transition animation={"fadeInLeft"} duration={1000} delay={100} visible={state.visible}>
                <div ref={state.ref} className="discover-bold-header">
                  DRIVE <span className="discover-bold-header-small">INNOVATION</span>
                </div>
              </Transition>
            )}
          </Visible>
          <Visible>
            {(state) => (
              <Transition animation={"fadeInLeft"} duration={1000} delay={300} visible={state.visible}>
                <p ref={state.ref} className="p-width_sm">
                  We are creative. We challenge traditional thinking and encourage originality. We can’t be limited in
                  our goals and achievements.
                </p>
              </Transition>
            )}
          </Visible>
        </div>
      </div>
    </SectionContent>
  </section>
);
