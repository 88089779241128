import Transition from "../controls/transition";
import Visible from "../controls/visible";
import React from "react";
import Slider, { Settings } from "react-slick";
import DashedLineGroup, { DashedLine } from "components/controls/dashedLines";
import { ReactComponent as GraphicVOT } from "images/graphics/graphic_VOT.svg";

export interface DecisionSlider {
  name: string;
  header: string;
  subtitle: string;
  img: string;
  img2?: string;
  imgDesktop: string;
}

const lenders: DecisionSlider = {
  name: "Lenders",
  header: "Lenders",
  subtitle:
    "Run a TitleCheck™ as a simple verification step at loan application and know if there are title issues that may affect the timing of a transaction and whether it may be fast tracked to closing. Address curative issues during upfront workflow steps, and ensure title is working on opportunities they know are most likely to close.",
  img: "photo-slider1 decision-img-top",
  img2: "photo-slide-bottom1 decision-img-bottom",
  imgDesktop: "flueid-photo-lg photo-lenders",
};

const servicers: DecisionSlider = {
  name: "Servicers",
  header: "Servicers",
  subtitle:
    "Proactively monitor a portfolio by activating a regular TitleCheck™ to understand loan health.  Partner with title to address any issues in real-time, so a performing loan’s title is ready and clear for the secondary market or other loan programs.",
  img: "photo-slider-servicers decision-img-top",
  imgDesktop: "flueid-photo-lg photo-servicers",
};

const lossMit: DecisionSlider = {
  name: "Loss Mitigation",
  header: "Loss Mitigation Consultants",
  subtitle:
    "Review a Loss Mitigation Analysis to understand the viability of every strategy tailored to a borrower’s circumstances and current market conditions, plus have evidence of the search. Enhance core systems with key data and decisioning insights to ensure you go down the best workflow path for the consumer.",
  img: "photo-slider5 decision-img-top",
  img2: "photo-slide-bottom5 decision-img-bottom",
  imgDesktop: "flueid-photo-lg photo-lossmit",
};

const realEstatePro: DecisionSlider = {
  name: "Real Estate Pros",
  header: "Real Estate Professionals",
  subtitle:
    "All you need is a buyer. Request a TitleCheck™ at listing and partner with a Fueled by Flueid™ title provider to ‘pre-clear’ a property for a quick close for the most seamless and cost-effective transaction experience possible.",
  img: "photo-slider4 decision-img-top",
  img2: "photo-slide-bottom4 decision-img-bottom",
  imgDesktop: "flueid-photo-lg photo-real-estate",
};

const titleAgent: DecisionSlider = {
  name: "Title Agents",
  header: "Title Agents",
  subtitle:
    "Digitize and automate your title search best practices based on tried-and-true underwriter logic, data and industry experience with Title Decision. Quickly, easily and securely produce and deliver a clean title commitment or title condition report to your lender partners, depending on the transaction type, for an accurate and efficient process.",
  img: "photo-slider2 decision-img-top",
  img2: "photo-slide-bottom2 decision-img-bottom",
  imgDesktop: "flueid-photo-lg photo-agents",
};

const titleUnderwriter: DecisionSlider = {
  name: "Title Underwriters",
  header: "Title Underwriters",
  subtitle:
    "Deliver title clearance decisions in under a minute using Title Decision based on your unique underwriting guidelines for a modern, tech-based competitive advantage. Support your title agent network in delivering faster commitments.",
  img: "photo-slider3 decision-img-top",
  img2: "photo-slide-bottom3 decision-img-bottom",
  imgDesktop: "flueid-photo-lg photo-UW",
};

export const diagram: DecisionSlider[] = [lenders, servicers, lossMit, realEstatePro, titleAgent, titleUnderwriter];

interface Props {
  decisionslider: DecisionSlider;
}

interface State {
  active: DecisionSlider;
}

export const DecisionSliderCard: React.FC<Props> = ({ decisionslider }) => (
  <div>
    <div className="slider-header mobile-width">{decisionslider.header}</div>
    <div className="slider-subheader mobile-width">{decisionslider.subtitle}</div>
    <div className={`${decisionslider.img}`}></div>
    {decisionslider.img2 && <div className={`${decisionslider.img2}`}></div>}
  </div>
);

class BuiltByFlueidSection extends React.Component<{}, State> {
  sliderSettings: Settings = {
    responsive: [
      {
        breakpoint: 4000,
        settings: {
          dots: true,
          slidesToShow: 1,
          draggable: true,
          fade: true,
          arrows: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          dots: true,
          slidesToShow: 1,
          draggable: true,
          fade: true,
          arrows: false,
        },
      },
    ],
  };

  state: State = {
    active: diagram[0],
  };

  isActive = (slide: DecisionSlider) => {
    return this.state.active === slide;
  };

  render() {
    const { active } = this.state;
    const mainHeader = "The first verification solution for title";
    const mainParagraph =
      "Fueled by our leading Flueid Decision platform, our VOT products make key title data and intelligence accessible during critical decision-making steps in a real estate transaction. This eliminates silos between title and closing and other workflows for a streamlined consumer experience.";
    const slidesHeader = "How does it work for you?";
    const votHeader = "Title verification products for each workflow";
    const votParagraph =
      "Our VOT products are purpose-built for every team. An easy-to-use workflow tool, they provide you with a title clearance decision and customized insights about a property and consumer in minutes via a dynamic report or data payload.";

    return (
      <div>
        <section className="builtByFlueid-section" id="fueledByFlueid">
          <GraphicVOT className="vot-graphic" />
          {/* DESKTOP VERSION */}
          <div className="content desktop-screens">
            <h2 className="decision-header">{mainHeader}</h2>
            <p className="paragraph-mb">{mainParagraph}</p>
            <DashedLineGroup className="dashedLineGroup-1">
              {(visible) => (
                <>
                  <DashedLine color="white" width={50} duration={2.5} delay={0} x={"5vw"} show={visible} />
                  <DashedLine color="white" width={35} duration={1.5} delay={0.2} x={"15vw"} show={visible} />
                  <DashedLine color="white" width={200} duration={1} delay={0.5} x={"18vw"} show={visible} />
                  <DashedLine color="white" width={75} duration={0.5} delay={0.7} x={"20vw"} show={visible} />
                </>
              )}
            </DashedLineGroup>

            <div className="btns-container">
              <div>
                <div className="vot-block">
                  <h3 className="vot-header">{votHeader}</h3>
                  <p className="vot-p">{votParagraph}</p>
                </div>
                <h5 className="slides-header flueid-decision-header-spacing">{slidesHeader}</h5>
                <div className="decision-graphic-section">
                  <div className="decision-left-section">
                    {diagram.map((x) => (
                      <div className="arrow-row-container" key={x.name}>
                        <p
                          onClick={() =>
                            this.setState({
                              active: x,
                            })
                          }
                          className={
                            this.isActive(x) ? "active-builtsection decision-subtitles txt-bold" : "decision-subtitles"
                          }
                        >
                          {x.name}
                        </p>
                        <div className={this.isActive(x) ? "active-arrow" : ""}></div>
                      </div>
                    ))}
                  </div>
                  <div className="decision-right-section">
                    <h3 className="slides-header">{active.header}</h3>
                    <p>{active.subtitle}</p>
                  </div>
                </div>
              </div>
              <Visible key={active.name}>
                {(state) => (
                  <Transition animation="fadeInRight" duration={1000} visible={state.visible}>
                    <div ref={state.ref} className={active.imgDesktop} />
                  </Transition>
                )}
              </Visible>
            </div>
          </div>

          {/* MOBILE VERSION */}
          <div className="flueid-decision-mobile mobile-width">
            <h2 className="decision-header mobile-width">{mainHeader}</h2>
            <p className="subparagraph mobile-width">{mainParagraph}</p>
            <DashedLineGroup animationDirection="left" className="dashedLineGroup-2 dashedLineGroupInterested">
              {(visible) => (
                <>
                  <DashedLine color="white" width={269} duration={0.5} delay={0.7} x={"-10vw"} show={visible} />
                  <DashedLine color="white" width={150} duration={1.5} delay={0} x={"-8vw"} show={visible} />
                  <DashedLine color="white" width={15} duration={1.5} delay={1} x={"-4vw"} show={visible} />
                  <DashedLine color="white" width={25} duration={2} delay={1} x={"-1vw"} show={visible} />
                </>
              )}
            </DashedLineGroup>
            <div className="vot-block mobile-width">
              <h3 className="vot-header">{votHeader}</h3>
              <p className="vot-p">{votParagraph}</p>
            </div>
            <h5 className="slides-header mobile-width flueid-decision-header-spacing">{slidesHeader}</h5>
            <Slider {...this.sliderSettings}>
              {diagram.map((x: DecisionSlider, i: number) => (
                <DecisionSliderCard key={`teamMember-card-${i}`} decisionslider={x} />
              ))}
            </Slider>
          </div>
          <div>
            <svg
              className="waves"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              viewBox="0 24 150 28"
              preserveAspectRatio="none"
              shapeRendering="auto"
            >
              <defs>
                <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
              </defs>
              <g className="parallax">
                <use xlinkHref="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7" />
                <use xlinkHref="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.5)" />
                <use xlinkHref="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)" />
                <use xlinkHref="#gentle-wave" x="48" y="7" fill="#fff" />
              </g>
            </svg>
          </div>
        </section>
      </div>
    );
  }
}

export default BuiltByFlueidSection;
