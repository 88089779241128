import HeroSection from "./heroSection";
import Footer from "../footer";
import Navbar from "../navbar/navbar";
import BuiltByFlueidSection from "./builtByFlueidSection";
import { FeaturesSection } from "./featuresSection";
import { JoinSection } from "./joinSection";
import { NumbersSection } from "./numbersSection";
import { SolutionsSection } from "./solutionsSection";
import { FlueidDecisionSection } from "./flueidDecisionSection";
import { FlueidBuiltSection } from "./flueidBuiltSection";
import { OverflowContainer } from "components/controls/overflow";
import { useContext } from "react";
import { EditorialsContext } from "types/editorial";
import { EditorialCardsSection } from "components/media/editorialCardsSection";
import EditorialCard from "components/media/editorialCard";
import { HomePageHeroSlideProps } from "types/homePageHeroSlide";
import gearIconSvg from "../../images/icons/icon-api-large.svg";

const HomePageContainer = ({ homePageHeroSlides }: HomePageHeroSlideProps) => {
  // get 3 most recent editorials
  const editorials = useContext(EditorialsContext);
  const latestEditorials = editorials.slice(0, 3);

  // hero section ready once they're loaded
  const heroSectionReady = !!homePageHeroSlides.length;

  return (
    <div className="homePage-container">
      {/* {!mobileMenuOpen && <HousingWireBanner />} Commented out but keeping for future award banner reference https://gitlab.com/flueid/flueid-website/-/issues/73 */}
      {/* <IncBanner /> */}
      <Navbar color="trans-black" offsetLogo />

      {/* show a skeleton section while content is loading */}
      {!heroSectionReady && <section className="home-hero-sections-container"></section>}
      {heroSectionReady && <HeroSection homePageHeroSlides={homePageHeroSlides} />}

      <BuiltByFlueidSection />
      <div className="gears-background" id="integrations">
        <img src={gearIconSvg} className="gears-img" />
        <div className="solutionsSuite-section">
          <FlueidDecisionSection />
          <FlueidBuiltSection />
        </div>
        <FeaturesSection />
      </div>
      <SolutionsSection />
      <NumbersSection />
      <OverflowContainer>
        <EditorialCardsSection title="What’s new at Flueid?">
          {latestEditorials.map((x) => (
            <EditorialCard editorial={x} key={x.sys.id}>
              <EditorialCard.Subtitle2>
                <div className="txt-blue">{x.fields.type}</div>
              </EditorialCard.Subtitle2>
              <EditorialCard.Title>{x.fields.title}</EditorialCard.Title>
              <EditorialCard.Subtitle>{x.fields.cardDescription}</EditorialCard.Subtitle>
              <EditorialCard.Link />
            </EditorialCard>
          ))}
        </EditorialCardsSection>
        <JoinSection />
        <Footer />
      </OverflowContainer>
    </div>
  );
};

export default HomePageContainer;
