import { SectionContent } from "../common/sectionContent";
import { ReactComponent as IconFactSheet } from "../../images/icons/icon-fact.sheet.svg";
import { ReactComponent as IconBrandAssets } from "../../images/icons/icon-brand.assets.kit.svg";
import DashedLineGroup, { DashedLine } from "../controls/dashedLines";
import { ReactComponent as IconDownload } from "../../images/icons/icon-download.svg";
import { ReactComponent as IconFlueid } from "../../images/icons/icon-flueid.clear.svg";

export const PressKitSection = () => (
  <section className="pressKit-section">
    <SectionContent maxWidthType="section-maxWidth-small">
      <DashedLineGroup className="dashedLineGroup-1">
        {(visible) => (
          <>
            <DashedLine width={50} duration={2.5} delay={0} x={"5vw"} show={visible} />
            <DashedLine width={35} duration={1.5} delay={0.2} x={"15vw"} show={visible} />
            <DashedLine width={200} duration={1} delay={0.5} x={"18vw"} show={visible} />
            <DashedLine width={75} duration={0.5} delay={0.7} x={"20vw"} show={visible} />
          </>
        )}
      </DashedLineGroup>
      <h2>Media Kit</h2>
      <p className="p-maxWidth margin-bottom_lg">
        Interested in learning more about Flueid? Check out our corporate fact sheet and brand assets.
      </p>
      <div className="mediaKit-grid">
        <div className="col-1">
          <IconFlueid className="svg-icon_lg" />
          <h6 className="txt-semiBold">Our Name [floo-id]</h6>
          <div className="p-3 txt-light">
            <i>Flueid</i> is pronounced just like it looks, <i className="txt-semiBold">[floo-id]</i>. Our spelling is
            unique because it is a blend of our brand promise to make transactions fluid and our use of data to fuel
            decision making. It is the embodiment of ‘fluidity’ and ‘fuel’ in concept, spirit and spelling.
          </div>
        </div>
        <div className="col-2">
          <IconBrandAssets className="svg-icon_lg" />
          <h6 className="txt-semiBold">Brand Assets</h6>
          <div className="p-3 txt-light">
            Flueid’s full media kit and brand guidelines. The assets are the protected intellectual property of Flueid.
            We reserve the right to modify or change the permissions in these guidelines. For questions, please contact{" "}
            <a className="txt-regular txt-underline" href="mailto:marketing@flueid.com">
              marketing@flueid.com
            </a>
            .
          </div>
          <a
            href="https://titlefy-resources.s3.us-west-2.amazonaws.com/flueid/Flueid-Comms_Brand-Guidelines_v3.2.pdf"
            target="_blank"
            className="download-width p-2 txt-underline flex flex-align-center cursor-pointer">
            Download <IconDownload className="iconDownload" />
          </a>
        </div>
        <div className="col-3">
          <IconFactSheet className="svg-icon_lg" />
          <h6 className="txt-semiBold">Flueid Fact Sheet</h6>
          <div className="p-3 txt-light">
            Founded in 2017, Flueid is an independent technology company using data and insight to fuel digital real
            estate transactions. Learn more about how we have fundamentally changed the real estate transaction life
            cycle through automation.
          </div>
          <a
            href="https://titlefy-resources.s3-us-west-2.amazonaws.com/flueid/R2_FactSheet_060221.pdf"
            target="_blank"
            className="download-width p-2 txt-underline flex flex-align-center cursor-pointer">
            Download <IconDownload className="iconDownload" />
          </a>
        </div>
      </div>
    </SectionContent>
  </section>
);
