import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function ScrollToTop() {
  const { pathname } = useLocation();

  // if pathname changes, scroll to top of page
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

export function scrollWithOffset(element: HTMLElement, offset = 200) {
  const elementPosition = element.offsetTop + offset;
  window.scroll({
    top: elementPosition,
    left: 0,
    behavior: "smooth",
  });
}
