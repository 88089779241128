// pass this to Pagination (rc-pagination) component's "locale" prop to override default Chinese title text
export const paginationTitles = {
  // Options
  items_per_page: "items per page",
  jump_to: "jump to",
  jump_to_confirm: "jump to confirm",
  page: "page",

  // Pagination
  prev_page: "previous page",
  next_page: "next page",
  prev_5: "previous 5 pages",
  next_5: "next 5 pages",
  prev_3: "previous 3 pages",
  next_3: "next 3 pages",
};
