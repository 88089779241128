import { Entry } from "contentful";
import contentfulClient from "utilities/contentfulClient";
import { Editorial } from "./editorial";

export interface IEditorialCardSection {
  name?: string;
  slug?: string;
  title?: string;
  subtitle?: string;
  editorials?: Entry<Editorial>[];
  link?: string;
}

/** ID for the Editorial Card Section content type. Needed for fetching this type of content */
export const EDITORIAL_CARD_SECTION_CONTENT_TYPE_ID = "flueidEditorialCardSection";

/** Gets all Editorial Card Sections */
export const getEditorialCardSections = () =>
  contentfulClient.getEntries<IEditorialCardSection>({
    content_type: EDITORIAL_CARD_SECTION_CONTENT_TYPE_ID,
  });

/** Gets an Editorial Card Section by its ID */
export const getEditorialCardSection = (id: string) => contentfulClient.getEntry<IEditorialCardSection>(id);
