import { Email } from "types/email";

export const API_URL = process.env.REACT_APP_AWS_LAMBDA_URL || "";
export const API_KEY = process.env.REACT_APP_AWS_LAMBDA_API_KEY || "";
export const defaultFetchOptions: RequestInit = {
  headers: {
    "x-api-key": API_KEY,
  },
};

/** Calls the AWS Lambda email communications handler. Attaches supplied email as a string to the request body*/
export const callEmailHandler = async (email: Email) =>
  fetch(API_URL, {
    ...defaultFetchOptions,
    method: "POST",
    body: JSON.stringify(email),
  });
