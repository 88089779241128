import { Link as ScrollLink } from "react-scroll";
export const BreakSectionHomeEquity = () => (
  <div className="break-section">
    <div className="breaksection-text">
      Here’s why unlocking data and insights at the loan application is essential to scaling your home equity offering{" "}
    </div>
    <ScrollLink to="graphsection" smooth>
      <div className="arrow-icon"></div>
    </ScrollLink>
  </div>
);
