import { SectionContent } from "components/common/sectionContent";
import Transition from "components/controls/transition";
import Visible from "components/controls/visible";
import { NavLink } from "react-router-dom";
import DashedLineGroup, { DashedLine } from "../controls/dashedLines";
import { TextLink } from "components/common/textLink";

export const FlueidBuiltSection = () => (
  <section className="solutions-subsection flueidBuilt-section">
    <SectionContent className="flex flueidpro-section" maxWidthType="section-maxWidth-large">
      <Visible>
        {(state) => (
          <Transition animation="fadeInLeft" duration={2500} visible={state.visible}>
            <div className="photoContainer" ref={state.ref}>
              <div className="photo-flueidBuilt photo-flueidDecision" />
            </div>
          </Transition>
        )}
      </Visible>
      <div className="flex flex-col txtContainer">
        <h3 className="txt-bold txt-large">Get up and running fast with Flueid Pro</h3>
        <h3 className="txt-blue txt-subheader txt-semiBold">Flueid Pro</h3>
        <p>
          Leverage Flueid Pro to directly access VOT products via our online application through a Fueled by Flueid™
          title partner to accelerate closing during the integration process. Review key alerts and insights, open title
          orders and more.
        </p>
        <TextLink to="https://pro.flueid.com" external color="blue">
          Learn more at pro.flueid.com
        </TextLink>
        <a href="https://pro.flueid.com/auth/sign-in" className="btn-margin-top btn-maxwidth">
          <button className="btn btn-blue flex flex-center btn-learn-more">Sign In</button>
        </a>
        <DashedLineGroup className="dashedLineGroup-1_mobile">
          {(visible) => (
            <>
              <DashedLine width={101} duration={2.5} delay={0} x={"3vw"} show={visible} />
              <DashedLine width={97} duration={1.5} delay={0.2} x={"7vw"} show={visible} />
              <DashedLine width={21} duration={1} delay={0.5} x={"11vw"} show={visible} />
              <DashedLine width={56} duration={0.5} delay={0.7} x={"14vw"} show={visible} />
            </>
          )}
        </DashedLineGroup>
      </div>
    </SectionContent>
    <SectionContent className="integrations-section">
      <div className="section-padding">
        <h3 className="txt-blue integrations-header txt-semiBold">Flueid Integrations</h3>
        <p className="subheader-maxwidth">
          Order a VOT product through the leading industry core operating systems you know, love, and use every day. And
          we are always adding more.
        </p>
      </div>
      <div className="logos-flex-container">
        <div className="schedule-meeting-container">
          <h3 className="meeting-header txt-bold">Interested in integrating with us?</h3>
          <p className="p">Schedule a meeting with our team.</p>
          <NavLink to="/demo?topic=demo" className="demo-link">
            <button className="btn btn-blue flex flex-center btn-learn-more btn-maxwidth ">Schedule a Meeting</button>
          </NavLink>
        </div>
        <div className="logos-grid-container">
          <div className="img-encompass img-logo" />
          <div className="img-firstclose img-logo"></div>
          <div className="img-blend img-logo"></div>
          <div className="img-resware img-logo"></div>
        </div>
      </div>
    </SectionContent>
  </section>
);
