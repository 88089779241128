import * as contentful from "contentful";
import { AppEnv, getAppEnv } from "./appEnv";

const { REACT_APP_CONTENTFUL_SPACE_ID, REACT_APP_CONTENTFUL_API_KEY, REACT_APP_CONTENTFUL_PREVIEW_API_KEY } =
  process.env;

// get env and determine whether to use main or preview contentful api key
const appEnv = getAppEnv();
const accessToken = appEnv === AppEnv.PROD ? REACT_APP_CONTENTFUL_API_KEY : REACT_APP_CONTENTFUL_PREVIEW_API_KEY;
const host = appEnv === AppEnv.PROD ? "cdn.contentful.com" : "preview.contentful.com";

const contentfulClient = contentful.createClient({
  space: REACT_APP_CONTENTFUL_SPACE_ID || "",
  accessToken: accessToken || "",
  host,
  // when there are errors resolving linked Entries (like if an Entry was deleted but another Entry still references it)
  // this will remove those unresolved linked Entries. Without this, there will be entries without the `.fields` property which is assumed to be present and can break code
  removeUnresolved: true, // https://github.com/contentful/contentful.js/issues/214#issuecomment-355589503
});

export default contentfulClient;
