import React from "react";

interface Props {
  open: boolean;
  className?: string;
  maxHeight?: number;
}

class Collapse extends React.Component<Props> {
  render() {
    const { open, className, children, maxHeight } = this.props;
    const style: React.CSSProperties = {};
    if (maxHeight) style.maxHeight = maxHeight;
    return (
      <div className={`collapse-container ${className || ""} ${open ? "open" : "closed"}`} style={style}>
        {children}
      </div>
    );
  }
}

export default Collapse;
