import Modal from "components/controls/modal";
import Visible from "components/controls/visible";
import DemoFormModel from "components/demo/demoFormModel";
import React from "react";

interface Props {
  className?: string;
}

interface State {
  modalOpen: boolean;
}
class DecisionReportHomeEquity extends React.Component<Props> {
  setModalOpen = () => this.setState({ modalOpen: true });
  SetModalClose = () => this.setState({ modalOpen: false });
  state: State = {
    modalOpen: false,
  };
  render() {
    return (
      <>
        <div className="button-graph-section-mobile">
          <div className="graph-text-bottom">
            <span className="bottom-text-bold">Want to know more?</span> Schedule a comprehensive financial impact
            analysis.
          </div>
          <div className="btn-container-equity-demo">
            <div onClick={this.setModalOpen} className="btn btn-black equity-hero-btn">
              Request a Demo
            </div>
          </div>
        </div>
        <div className="report-container">
          <div className="report-title">Drive higher margins and volume with every decision in the workflow</div>
          <div className="report-content">
            <Visible>
              {(state) => (
                <div ref={state.ref} className={`${state.visible ? "white" : ""} ${"report-block report-transition0"}`}>
                  <Visible>
                    {(state) => (
                      <div
                        ref={state.ref}
                        className={`${state.visible ? "white-background" : ""} ${"report-bar report-transition-bar1"}`}
                      ></div>
                    )}
                  </Visible>
                  <div className="report-block-header">Lender Decision Report</div>
                  <div className="report-block-text">
                    Used on all transactions to drive the most economic and efficient workflows with a focus on those
                    with the highest likelihood to close
                  </div>
                </div>
              )}
            </Visible>

            <Visible>
              {(state) => (
                <div ref={state.ref} className={`${state.visible ? "white" : ""} ${"report-block report-transition"}`}>
                  <Visible>
                    {(state) => (
                      <div
                        ref={state.ref}
                        className={`${state.visible ? "white-background" : ""} ${"report-bar report-transition-bar2"}`}
                      ></div>
                    )}
                  </Visible>
                  <div className={`${"report-block-header"}`}>Workflow Decision Report</div>
                  <div className={`${"report-block-text"}`}>
                    Used on all transactions to reduce costs and accelerate Title Condition Report delivery{" "}
                  </div>
                </div>
              )}
            </Visible>
            <Visible>
              {(state) => (
                <div ref={state.ref} className={`${state.visible ? "white" : ""} ${"report-block report-transition2"}`}>
                  <Visible>
                    {(state) => (
                      <div
                        ref={state.ref}
                        className={`${state.visible ? "white-background" : ""} ${"report-bar report-transition-bar3"}`}
                      ></div>
                    )}
                  </Visible>
                  <div className={`${"report-block-header"}`}>Title Condition Report</div>
                  <div className={`${"report-block-text"}`}>
                    Purpose-built for each decision result to reduce the cost of producing a property report and
                    insurance coverage
                  </div>
                </div>
              )}
            </Visible>
          </div>

          <div className="report-bottom-text">Want a full demo of the Decision and TCR Reports?</div>
          <div className="btn-container-equity-demo">
            <div onClick={this.setModalOpen} className="btn btn-black equity-hero-btn equity-btn-outline">
              Request a Demo
            </div>
          </div>
        </div>
        <Modal open={this.state.modalOpen} onClose={() => this.SetModalClose()}>
          <DemoFormModel />
        </Modal>
      </>
    );
  }
}

export default DecisionReportHomeEquity;
