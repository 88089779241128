import NewsletterSignUp from "./newsletterSection";
import { HeroSectionMedia } from "./heroSection";
import Footer from "../footer";
import Navbar from "../navbar/navbar";
import { PressKitSection } from "./pressKitSection";
import LatestNewsSection from "./lastestNews";
import { FeaturedStorySection } from "./featuredStorySection";
import { OverflowContainer } from "components/controls/overflow";
import { EditorialCardsSection } from "./editorialCardsSection";
import { useContext } from "react";
import { Editorial, EditorialsContext } from "types/editorial";
import { Entry } from "contentful";
import EditorialCard, { EditorialLink } from "./editorialCard";
import { formatDateLong } from "utilities/formatters";

const MediaPageContainer = () => {
  // get all editorials
  const editorials = useContext(EditorialsContext);
  const articles = editorials.filter((x) => x.fields.type === "Article");
  const caseStudies = editorials.filter((x) => x.fields.type === "Case Study");
  const stories = editorials.filter((x) => x.fields.type === "Story");

  const articleCards = articles.map((x) => (
    <EditorialCard editorial={x} key={x.sys.id}>
      <EditorialCard.Subtitle2>{formatDateLong(x.fields.publishDate)}</EditorialCard.Subtitle2>
      <EditorialCard.Title>{x.fields.title}</EditorialCard.Title>
      <EditorialCard.Link>Read Article</EditorialCard.Link>
    </EditorialCard>
  ));

  const caseStudyCards = caseStudies.map((x) => (
    <EditorialCard editorial={x} key={x.sys.id}>
      <EditorialCard.Title>{x.fields.title}</EditorialCard.Title>
      <EditorialCard.Subtitle>{x.fields.cardDescription}</EditorialCard.Subtitle>
      <EditorialCard.Link />
    </EditorialCard>
  ));

  const storyCards = stories.map((x) => (
    <EditorialCard editorial={x} key={x.sys.id}>
      <EditorialCard.Title>{x.fields.title}</EditorialCard.Title>
      <EditorialCard.Subtitle>{x.fields.cardDescription}</EditorialCard.Subtitle>
      <EditorialCard.Link />
    </EditorialCard>
  ));

  return (
    <div className="mediaPage-container">
      <Navbar color="black" />
      <OverflowContainer>
        <HeroSectionMedia />
        <FeaturedStorySection />
        <EditorialCardsSection
          className="background_lightGray"
          title="Stories"
          subtitle="Our perspectives and insights on the future of the digital real estate transaction.">
          {storyCards}
        </EditorialCardsSection>
        <LatestNewsSection />

        <EditorialCardsSection title="In The News" className="background_lightGray">
          {articleCards}
        </EditorialCardsSection>

        <EditorialCardsSection
          title="Case Studies"
          subtitle="Learn how we help fuel decision-making for industry leaders.">
          {caseStudyCards}
        </EditorialCardsSection>

        <PressKitSection />
        <NewsletterSignUp />
        <Footer />
      </OverflowContainer>
    </div>
  );
};

export default MediaPageContainer;
