export function uniqueID(prefix?: string) {
  return `${prefix || ""}_${Math.random().toString(36).substr(2, 9)}`;
}

export function formatPhoneNumber(value: string): string {
  if (!value) return value;
  const phoneNumber = value.replace(/[^\d]/g, "");
  const phoneNumberLength = phoneNumber.length;

  let range1 = 4;
  let range2 = 7;
  let slice1start = 0;
  let slice1end = 3;
  let slice2start = 3;
  let slice2end = 6;
  let slice3start = 6;
  let slice3end = 10;

  // number starts with 1, need to increment each range/slice value
  if (phoneNumber[0] === "1") {
    range1++, range2++, slice1start++, slice1end++, slice2start++, slice2end++, slice3start++, slice3end++;
  }
  // ex. returns 234
  if (phoneNumberLength < range1) return phoneNumber;
  // ex. returns (234) 432
  if (phoneNumberLength < range2) {
    return `${phoneNumber[0] === "1" ? "1 " : ""}(${phoneNumber.slice(slice1start, slice1end)}) ${phoneNumber.slice(
      slice2start,
    )}`;
  }

  // ex. returns fully formatted (234) 432-1234
  return `${phoneNumber[0] === "1" ? "1 " : ""}(${phoneNumber.slice(slice1start, slice1end)}) ${phoneNumber.slice(
    slice2start,
    slice2end,
  )}-${phoneNumber.slice(slice3start, slice3end)}`;
}

// returns formatted date like "September 21, 2021"
export function formatDateLong(dateString: string): string {
  if (!dateString) return "";
  const date = new Date(dateString);
  return `${date.toLocaleString("en-US", { month: "long" })} ${date.getDate()}, ${date.getFullYear()}`;
}

export function camelize(str: string) {
  return str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) => {
      return index === 0 ? word.toLowerCase() : word.toUpperCase();
    })
    .replace(/\s+/g, "");
}
