import { OverflowContainer } from "components/controls/overflow";
import React from "react";
import { HeroSection } from "../common/heroSection";
import Footer from "../footer";
import Navbar from "../navbar/navbar";
import ContactForm from "./contactForm";
import { ContactInfoSection } from "./contactInfoSection";

class ContactPageContainer extends React.Component {
  render() {
    return (
      <div className="contactPage-container">
        <Navbar color="white" />
        <OverflowContainer>
          <HeroSection className="contactPage-hero-section">
            <ContactForm />
          </HeroSection>
          <ContactInfoSection />
          <Footer />
        </OverflowContainer>
      </div>
    );
  }
}

export default ContactPageContainer;
