import { ContactTopic, ContactUsRequest } from "types/contactUsRequest";
import DemoRequest from "types/demoRequest";
import { newValidatedField, ValidationDictionary, ValidationSchema, ValidationType } from "types/validation";

export const validationDefault: ValidationSchema<ContactUsRequest> = {
  name: newValidatedField(true, [ValidationType.Value]),
  email: newValidatedField(true, [ValidationType.Email]),
  phone: newValidatedField(false, [ValidationType.Phone]),
  topic: newValidatedField(true, [ValidationType.Value]),
};

export const validationHomeEquityDemo: ValidationSchema<DemoRequest> = {
  name: newValidatedField(true, [ValidationType.Value]),
  email: newValidatedField(true, [ValidationType.Email]),
  phone: newValidatedField(true, [ValidationType.Phone]),
  companyName: newValidatedField(true, [ValidationType.Value]),
  industryType: newValidatedField(true, [ValidationType.Value]),
};

export const validationDictionary: ValidationDictionary<ContactTopic, ContactUsRequest> = {
  "I’m interested in career opportunities at Flueid": {
    ...validationDefault,
    position: newValidatedField(true, [ValidationType.Value]),
  },
};
