import React from "react";
import {
  contactOptions,
  ContactTopic,
  ContactUsKey,
  ContactUsRequest,
  DropdownOption,
  getEmailRecipient,
} from "../../types/contactUsRequest";
import { ReqStatus } from "../../types/reqStatus";
import { allSchemaFieldsValid, setValidatedFieldTrue, validateSchema, ValidationSchema } from "../../types/validation";
import { Button } from "../controls/button";
import Input from "../controls/input";
import Popup, { PopupOption } from "../controls/popup";
import { withRouter, RouteComponentProps, NavLink } from "react-router-dom";
import * as QueryString from "query-string";
import { Email, EmailResponse, StatusCodes } from "types/email";
import { validationDefault, validationDictionary } from "./validation";
import { ReactComponent as IconArrowDown } from "../../images/icons/glyph-dropdown.down.svg";
import Modal from "components/controls/modal";
import { API_URL, callEmailHandler, defaultFetchOptions } from "utilities/apiService";
import { mapParsedValues } from "utilities/tsHelpers";

interface State {
  contactUsRequest: ContactUsRequest;
  reqStatus: ReqStatus;
  validation: ValidationSchema<ContactUsRequest>;
  popupOpen: boolean;
  modalOpen: boolean;
}

export const ContactOkMessage = () => (
  <>
    <h3>Thank you for your email!</h3>
    <p>
      We appreciate your interest in Flueid. Our team is reviewing your request and will follow up directly with next
      steps. We look forward to connecting.
    </p>
  </>
);

export const ContactFailMessage = () => (
  <>
    <h3>Oops! Something went wrong.</h3>
    <p>
      Please refresh the page and try again or send an email to <a href="mailto:sales@flueid.com">sales@flueid.com</a>
    </p>
  </>
);

class ContactForm extends React.Component<RouteComponentProps, State> {
  defaultState: State = {
    contactUsRequest: new ContactUsRequest(),
    reqStatus: new ReqStatus(),
    validation: { ...validationDefault },
    popupOpen: false,
    modalOpen: false,
  };

  state: State = { ...this.defaultState };

  componentDidMount() {
    const { location } = this.props;
    if (location.search) {
      const parsedParams = QueryString.parse(location.search);
      const contactUsRequest = mapParsedValues<ContactUsRequest>(new ContactUsRequest(), parsedParams);
      this.setState({ contactUsRequest });
    }
  }

  componentDidUpdate(prevProps: RouteComponentProps, prevState: State) {
    const { contactUsRequest, validation } = this.state;
    // topic changes, update validation and reset position/companyName fields
    if (prevState.contactUsRequest.topic !== contactUsRequest.topic && contactUsRequest.topic) {
      this.setState({
        contactUsRequest: {
          ...contactUsRequest,
          position: "",
          companyName: "",
        },
        validation: validationDictionary[contactUsRequest.topic] || { ...validationDefault },
      });
    }
  }

  handleInputChange = (name: ContactUsKey, value: string) => {
    const { contactUsRequest, validation } = this.state;
    this.setState({
      ...this.state,
      contactUsRequest: {
        ...contactUsRequest,
        [name]: value,
      },
      validation: setValidatedFieldTrue<ContactUsRequest>(validation, name),
    });
  };

  handleInputClear = (name: ContactUsKey) => {
    const { validation, contactUsRequest } = this.state;
    this.setState({
      ...this.state,
      contactUsRequest: {
        ...contactUsRequest,
        [name]: "",
      },
      validation: setValidatedFieldTrue<ContactUsRequest>(validation, name),
    });
  };

  handleTopicChange = (name?: string, value?: ContactTopic) => {
    const { contactUsRequest, validation } = this.state;
    // if meeting or demo, push to demo page with query params
    if (value === ContactTopic.Demo || value === ContactTopic.Meeting) {
      const queryParams = QueryString.stringify({
        ...contactUsRequest,
        topic: value,
      });
      this.props.history.push("/demo" + "?" + queryParams);
    } else {
      this.setState({
        ...this.state,
        popupOpen: false,
        contactUsRequest: {
          ...contactUsRequest,
          topic: value || ContactTopic.Undefined,
        },
        validation: validation.topic?.valid === false ? setValidatedFieldTrue(validation, "topic") : validation,
      });
    }
  };

  submitForm = (e: React.FormEvent) => {
    e.preventDefault();

    const { contactUsRequest, validation, reqStatus } = this.state;

    // don't allow extra smashes while request pending
    if (reqStatus.pending) return;

    // validate form
    const v = validateSchema<ContactUsRequest>(contactUsRequest, validation);
    const allFieldsValid = allSchemaFieldsValid<ContactUsRequest>(v);

    if (allFieldsValid) {
      // set reqStatus pending
      this.setState({ reqStatus: ReqStatus.Pending() });

      // construct email and fire request
      const email: Email = {
        to: [getEmailRecipient(contactUsRequest.topic)],
        subject: "Flueid Corp Site Contact Request",
        text: ContactUsRequest.EmailBody(contactUsRequest),
      };

      callEmailHandler(email)
        .then((data) => data.json())
        .then((resp: EmailResponse) => {
          if (resp.statusCode === StatusCodes.Ok) {
            // reset form, open modal
            this.setState({
              ...this.defaultState,
              modalOpen: true,
              reqStatus: ReqStatus.Ok(),
            });
          } else {
            console.log(resp);
            // open modal and show error
            this.setState({
              modalOpen: true,
              reqStatus: ReqStatus.Failed(),
            });
          }
        })
        .catch((err) => {
          console.log(err);
          // open modal and show error
          this.setState({
            modalOpen: true,
            reqStatus: ReqStatus.Failed(),
          });
        });
    } else {
      this.setState({ validation: v });
    }
  };

  modalOpen = () => this.setState({ modalOpen: true });
  modalClose = () => this.setState({ modalOpen: false });

  render() {
    const { contactUsRequest, validation, popupOpen, reqStatus, modalOpen } = this.state;

    return (
      <div className="container-relative">
        <div className="form-section">
          <form className="content-left" onSubmit={this.submitForm}>
            <h1>Drop us a note</h1>
            <h5 className="txt-light">We can't wait to hear from you.</h5>
            <Popup
              className="contactForm-popup"
              open={popupOpen}
              onOpen={() => this.setState({ popupOpen: true })}
              onClose={() => this.setState({ popupOpen: false })}
              trigger={
                <div className="flueidProContactForm-inputSec">
                  <Input<ContactUsKey>
                    label="Topic"
                    type="text"
                    name="topic"
                    value={contactUsRequest.topic}
                    onChange={this.handleInputChange}
                    editable={false}
                    // needs to be false for clicking the label to activate popup
                    labelUseHTMLFor={false}
                    required
                    valid={validation?.topic?.valid}
                    errorMessages={validation?.topic?.valid === false ? ["Please select a topic"] : []}
                  />
                  <IconArrowDown className="icon-arrow" />
                </div>
              }
            >
              {contactOptions.map((x: DropdownOption<ContactTopic>, i: number) => (
                <PopupOption<ContactUsKey, ContactTopic>
                  key={`contact-topic-${i}`}
                  value={x.value}
                  name="topic"
                  onClick={this.handleTopicChange}
                >
                  {x.value}
                </PopupOption>
              ))}
            </Popup>
            <div className="flueidProContactForm-inputSec">
              <Input<ContactUsKey>
                label="Your Name"
                type="text"
                name="name"
                value={contactUsRequest.name}
                onChange={this.handleInputChange}
                required
                valid={validation?.name?.valid}
                errorMessages={validation?.name?.valid === false ? ["Please enter name"] : []}
              />
            </div>
            {contactUsRequest.topic !== ContactTopic.Join && (
              <div className="flueidProContactForm-inputSec">
                <Input<ContactUsKey>
                  label="Company Name"
                  type="text"
                  name="companyName"
                  value={contactUsRequest.companyName}
                  onChange={this.handleInputChange}
                />
              </div>
            )}
            <div className="flueidProContactForm-inputSec">
              <Input<ContactUsKey>
                label="Email Address"
                type="text"
                name="email"
                value={contactUsRequest.email}
                onChange={this.handleInputChange}
                required
                valid={validation?.email?.valid}
                errorMessages={validation?.email?.valid === false ? ["Please enter valid email"] : []}
              />
            </div>
            <div className="flueidProContactForm-inputSec">
              <Input<ContactUsKey>
                label="Phone Number"
                type="phone"
                name="phone"
                value={contactUsRequest.phone}
                onChange={this.handleInputChange}
                valid={validation?.phone?.valid}
                errorMessages={validation?.phone?.valid === false ? ["Please enter valid phone number"] : []}
              />
            </div>
            {contactUsRequest.topic === ContactTopic.Join && (
              <div className="flueidProContactForm-inputSec">
                <Input<ContactUsKey>
                  type="text"
                  label="Position"
                  name="position"
                  value={contactUsRequest.position}
                  onChange={this.handleInputChange}
                  required
                  valid={validation?.position?.valid}
                  errorMessages={validation?.position?.valid === false ? ["Please enter position"] : []}
                />
              </div>
            )}
            <div className="flueidProContactForm-inputSec msg">
              <Input<ContactUsKey>
                label="Your Message"
                type="text"
                name="message"
                value={contactUsRequest.message}
                onChange={this.handleInputChange}
              />
            </div>
            <div className="flueidProContactForm-submitBtnSec">
              <div className="p-4 txt-light">
                <span className="txt-semiBold">We care about your privacy. </span>
                Your data is secure and we will never share your information with outside parties.
              </div>
              <Button type="submit" className="btn-black" onSubmit={this.submitForm}>
                SUBMIT
              </Button>
            </div>
          </form>
        </div>
        <div className="dot-pattern" />
        <Modal open={modalOpen} onOpen={this.modalOpen} onClose={this.modalClose}>
          {reqStatus.ok ? <ContactOkMessage /> : <ContactFailMessage />}
          <Button onClick={this.modalClose}>Got it</Button>
        </Modal>
      </div>
    );
  }
}

export default withRouter(ContactForm);
