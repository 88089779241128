import { Asset, Entry } from "contentful";
import contentfulClient from "utilities/contentfulClient";

export interface IHomePageHeroSlide {
  backgroundGraphic?: Asset;
  ctaLink?: string;
  ctaLinkExternal?: boolean;
  ctaText?: string;
  floatingGraphic?: Asset;
  floatingGraphicOffsetRight?: number;
  floatingGraphicOffsetTop?: number;
  floatingPhoto?: Asset;
  floatingPhotoObjectPosition?: string;
  floatingPhotoOffsetRight?: number;
  floatingPhotoOffsetTop?: number;
  header?: string;
  message?: string;
  name?: string;
  position?: number;
  preHeadline?: string;
  useAnimatedDashedLines?: boolean;
}

/** Content Type ID for Home Page Hero Slides */
export const HOME_PAGE_HERO_SLIDE_CONTENT_TYPE_ID = "flueidHomePageHeroSlide";

/** Fetches all Home Page Hero Slides */
export const getHomePageHeroSlides = () =>
  contentfulClient.getEntries<IHomePageHeroSlide>({
    content_type: HOME_PAGE_HERO_SLIDE_CONTENT_TYPE_ID,
  });

/** Shared React Props for passing list of Home Page Hero Slides */
export type HomePageHeroSlideProps = {
  homePageHeroSlides: Entry<IHomePageHeroSlide>[];
};
