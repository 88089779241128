import ReactDOMServer from "react-dom/server";
import { hasKey } from "utilities/tsHelpers";
import { OptionType } from "./stateOptions";

export const editorialRequestRecipients: string[] = [
  "media@flueid.com",
  "elizabeth.lilly@flueid.com",
  "johnny@flueid.com",
  "peter.richter@flueid.com",
];

export const orgTypeOptions: OptionType[] = [
  { value: "Title Agent", label: "Title Agent" },
  { value: "Mortgage Lender", label: "Mortgage Lender" },
  { value: "Mortgage Servicer", label: "Mortgage Servicer" },
  { value: "Digital Service Provider", label: "Digital Service Provider" },
  { value: "iBuyer", label: "iBuyer" },
  { value: "Real Estate Agent", label: "Real Estate Agent" },
  { value: "Title Underwriter ", label: "Title Underwriter " },
  { value: "Foreclosure Attorney", label: "Foreclosure Attorney" },
];

export const EditorialRequestFullKeyNames: Record<keyof EditorialRequest, string> = {
  name: "Name",
  email: "Email",
  orgName: "Organization Name",
  orgType: "Organization Type",
  editorialTitle: "Editorial Title",
  editorialId: "Editorial Contentful Id",
};

export class EditorialRequest {
  name: string = "";
  email: string = "";
  orgName: string = "";
  orgType: string = "";
  editorialTitle: string = "";
  editorialId: string = "";

  static EmailBody(req: EditorialRequest) {
    return ReactDOMServer.renderToStaticMarkup(
      <div>
        <h1>Editorial Request</h1>
        {Object.keys(req).map((x) => {
          return (
            hasKey(EditorialRequestFullKeyNames, x) &&
            hasKey(req, x) && (
              <p key={x}>
                {EditorialRequestFullKeyNames[x]}: <span>{req[x]}</span>
              </p>
            )
          );
        })}
      </div>,
    );
  }
}
