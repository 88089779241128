import { SectionContent } from "components/common/sectionContent";
import Transition from "components/controls/transition";
import Visible from "components/controls/visible";
import { SecurityDetailCard } from "./types";
import { ReactComponent as IconFlag } from "../../images/icons/icon-flag.svg";
import { ReactComponent as IconControls } from "../../images/icons/icon-controls.svg";
import { ReactComponent as IconStack } from "../../images/icons/icon-stack.svg";
import { ReactComponent as IconBgs } from "../../images/icons/icon-bgs.svg";
import { ReactComponent as IconTrust } from "../../images/graphics/graphic-trust.svg";

const PlatformDetailCard = (detail: SecurityDetailCard) => (
  <div className="platform-detail-card sec-bg-green-dark">
    <div>{detail.icon}</div>
    <p className="margin-none">{detail.text}</p>
  </div>
);

const TrustPlatformSection = () => {
  const detailCards: SecurityDetailCard[] = [
    {
      delay: 500,
      animation: "fadeInLeft",
      icon: <IconFlag />,
      text:
        "We’ve created a unique set of analyzers, platform features and cutting-edge technologies to flag risks that we see recurring across transactions, so they aren’t missed, and you know about them earlier. ",
    },
    {
      delay: 1000,
      animation: "fadeInRight",
      icon: <IconControls />,
      text:
        "We have the knowledge and automated controls in place to provide assurance to our clients that we meet the trust services criteria pertaining to security, availability and confidentiality. ",
    },
    {
      delay: 500,
      animation: "fadeInLeft",
      icon: <IconStack />,
      text: "We are integrated with the most robust data sets to ensure quality and accuracy of our solutions. ",
    },
    {
      delay: 1000,
      animation: "fadeInRight",
      icon: <IconBgs />,
      text: "Our platform is backed by 14 of the top 20 national title underwriters insuring the transaction. ",
    },
  ];

  return (
    <section className="sec-trust-platform-section">
      <SectionContent maxWidthType="section-maxWidth-xsm">
        <h3>
          <b>Trust in</b> Our Platform
        </h3>
        <p className="p-1 p-max-width margin-bottom_md">
          <b>
            Our patented Flueid Decision platform is built on the foundation of more than a 100 years of deep industry
            knowledge and logic from real life experiences.
          </b>{" "}
          Today, it’s not just a better platform, it’s the best-performing title data and intelligence platform.
        </p>
        <div className="platform-detail-cards-grid">
          {detailCards.map((card) => (
            <Visible key={card.text}>
              {({ visible, ref }) => (
                <Transition animation={card.animation} duration={1000} visible={visible} delay={card.delay}>
                  <div ref={ref} className="platform-detail-card-transition">
                    <PlatformDetailCard {...card} />
                  </div>
                </Transition>
              )}
            </Visible>
          ))}
        </div>
      </SectionContent>
      <IconTrust className="icon-trust" />
    </section>
  );
};

export default TrustPlatformSection;
