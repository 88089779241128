import { Link } from "react-router-dom";
import { getUrlPath, isUrl } from "utilities/url";
import { IconArrowColor, IconArrowRight } from "../icons/iconArrowRight";
import styles from "./styles.module.scss";
import { classNames } from "utilities/classNames";

type Props = React.DetailedHTMLProps<React.AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement> & {
  external?: boolean;
  to: string;
  useArrow?: boolean;
  color?: IconArrowColor;
  colorInline?: string;
};

export const TextLink: React.FC<Props> = ({
  children,
  external,
  to,
  useArrow = true,
  color = "black",
  className,
  colorInline,
  ...props
}) => {
  const classes = classNames(className, styles.text_link, `txt-${color}`);
  const inlineStyles: React.CSSProperties = { color: colorInline };

  // if it's an internal link, with a full url - get just the pathname
  const internalTo = !external && isUrl(to) ? getUrlPath(to) : to;

  const Content = () => (
    <>
      <u>{children}</u> {useArrow && <IconArrowRight color={color} colorInline={colorInline} />}
    </>
  );

  return external ? (
    <a href={to} className={classes} target="_blank" rel="noreferrer" {...props} style={inlineStyles}>
      <Content />
    </a>
  ) : (
    <Link to={internalTo} className={classes} style={inlineStyles}>
      <Content />
    </Link>
  );
};
