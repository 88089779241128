import React from "react";
import Visible from "./visible";

export type DashedLineColor = "black" | "white";

interface DashedLineProps {
  width: number; // in pixels, gets set as maxWidth
  x: string; // transform: translateX(x)
  duration: number; // in seconds
  delay: number; // in seconds
  show: boolean;
  // optional
  color?: DashedLineColor;
  className?: string;
  id?: string;
  style?: React.CSSProperties;
}

export const DashedLine = ({ style, className, id, width, x, delay, duration, show, color }: DashedLineProps) => {
  const baseStyles: React.CSSProperties = {
    transition: `all ${duration}s ease-out`,
    transitionDelay: `${delay}s`,
    maxWidth: `${width}px`,
  };

  const animationStyles: React.CSSProperties = {
    transform: `translateX(${x})`,
    opacity: 1,
  };

  let finalStyles = { ...baseStyles, ...style };
  if (color) finalStyles = { ...finalStyles, backgroundColor: color };
  if (show) finalStyles = { ...finalStyles, ...animationStyles };
  return <div style={finalStyles} className={`dashed-group-item ${className || ""}`} id={id} />;
};

interface Props {
  children: (visible: boolean) => React.ReactNode;
  className?: string;
  showOnMount?: boolean;
  animationDirection?: "left" | "right";
}

class DashedLineGroup extends React.Component<Props> {
  render() {
    const { className, animationDirection, children, showOnMount } = this.props;

    return (
      <Visible>
        {(state) => (
          <div
            ref={state.ref}
            className={`
              dashed-line-group-container
              ${className || ""} 
              ${animationDirection ? `dashed-line-group-container-${animationDirection}` : ""}
              ${state.visible || showOnMount ? "show" : "hide"}`}
          >
            {children(state.visible)}
          </div>
        )}
      </Visible>
    );
  }
}

export default DashedLineGroup;
