import { JobProps } from "types/jobPosting";
import JobPostingCard from "./jobPostingCard";

const JobPostings = ({ jobs, loading }: JobProps) => {
  const noResults = !jobs.length && !loading;

  return (
    <>
      <h2 className="margin-top_none">Open Jobs</h2>
      {loading && <p>Loading...</p>}
      {noResults && <p>There are currently no job openings.</p>}
      {!!jobs.length && (
        <>
          <p className="p">
            Welcome! We are a growing company looking for talented individuals to join our team and help us achieve our
            goals. As an employer, we value intentionallity, collaboration, and innovation, and we believe that our
            success comes from the hard work and dedication of our employees.
          </p>
          <p className="p">
            We offer a dynamic and challenging work environment where you will have the opportunity to learn and grow
            professionally. We are committed to providing our employees with competitive compensation and benefits
            packages, as well as a supportive and inclusive workplace culture that encourages innovation and excellence.
          </p>
          <p className="p">
            If you are passionate about your work, and you're looking for a rewarding and fulfilling career, we invite
            you to explore our job opportunities and join us in shaping the future of our company. Whether you're an
            experienced professional or a recent graduate, we encourage you to apply and become a part of our team.
          </p>
          {jobs.map((x) => (
            <JobPostingCard {...x} key={x.sys.id} />
          ))}
        </>
      )}
    </>
  );
};

export default JobPostings;
