import React, { ReactElement, PropsWithChildren } from "react";

interface MobileLinkProps {
  mobileMenuOpen: boolean;
  closeMobileMenu: () => void;
  children?: ReactElement;
}

export const MobileLink = ({ mobileMenuOpen, closeMobileMenu, children }: PropsWithChildren<MobileLinkProps>) => {
  if (!children) return null;
  let props: Partial<any> & React.Attributes = {};
  mobileMenuOpen ? (props.onClick = closeMobileMenu) : "";

  return React.cloneElement(children, props);
};
