import React from "react";
import Footer from "../footer";
import Navbar from "../navbar/navbar";
import { CoreValues } from "./coreValues";
import { TeamSection } from "./teamSection";
import { HeroSection } from "../common/heroSection";
import TestimonySection from "./testimonySection";
import { TimelineSection } from "./timelineSection";
import { BackedBySection } from "./backedBySection";
import { OverflowContainer } from "components/controls/overflow";
import { MissionBlock } from "./missionBlock";
import DashedLineGroup, { DashedLine } from "../controls/dashedLines";
import { FeaturedStoryAboutSection } from "./featuredStoryAboutSection";

const AboutPageContainer = () => {
  return (
    <div className="aboutPage-container">
      <Navbar color="black" />
      <OverflowContainer>
        <HeroSection className="black aboutPage-heroSection">
          <div className="subheader-2 txt-semiBold">About</div>
          <h1>
            Our Company
            <DashedLineGroup animationDirection="left" className="dashedLineGroup-1">
              {(visible) => (
                <>
                  <DashedLine color="white" width={211} duration={0.5} delay={0.7} x={"-10vw"} show={visible} />
                  <DashedLine color="white" width={150} duration={1.5} delay={0} x={"-8vw"} show={visible} />
                  <DashedLine color="white" width={15} duration={1.5} delay={1} x={"-4vw"} show={visible} />
                  <DashedLine color="white" width={25} duration={2} delay={1} x={"-1vw"} show={visible} />
                </>
              )}
            </DashedLineGroup>
          </h1>
          <h3 className="h3-large txt-light">
            We help our clients create a truly on-demand consumer experience by fueling every part of the real estate
            transaction.
          </h3>
          <DashedLineGroup className="dashedLineGroup-2">
            {(visible) => (
              <>
                <DashedLine color="white" width={50} duration={2.5} delay={0} x={"5vw"} show={visible} />
                <DashedLine color="white" width={35} duration={1.5} delay={0.2} x={"15vw"} show={visible} />
                <DashedLine color="white" width={200} duration={1} delay={0.5} x={"18vw"} show={visible} />
                <DashedLine color="white" width={75} duration={0.5} delay={0.7} x={"20vw"} show={visible} />
              </>
            )}
          </DashedLineGroup>
          <MissionBlock className="mission-block_desktop" />
        </HeroSection>
        <MissionBlock className="mission-block_mobile" />
        <FeaturedStoryAboutSection />
        <TimelineSection />
        <CoreValues />
        <TestimonySection showDashedLines />
        <TeamSection />
        <BackedBySection />
        <Footer />
      </OverflowContainer>
    </div>
  );
};

export default AboutPageContainer;
