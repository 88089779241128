import { SectionContent } from "../common/sectionContent";
import DashedLineGroup, { DashedLine } from "../controls/dashedLines";

export const CoreValues = () => (
  <section className="coreValues-section">
    <SectionContent>
      <DashedLineGroup className="dashedLineGroup-1">
        {(visible) => (
          <>
            <DashedLine width={50} duration={2.5} delay={0} x={"5vw"} show={visible} />
            <DashedLine width={35} duration={1.5} delay={0.2} x={"15vw"} show={visible} />
            <DashedLine width={200} duration={1} delay={0.5} x={"18vw"} show={visible} />
            <DashedLine width={75} duration={0.5} delay={0.7} x={"20vw"} show={visible} />
          </>
        )}
      </DashedLineGroup>
      <div className="border-fade">
        <h2>The promise behind our name</h2>
        <p className="txt-semiBold">
          <i className="txt-bold">Flueid.</i> The embodiment of ‘fluidity’ and ‘fuel’ in concept, spirit and spelling.
        </p>
        <div className="p-2">
          We strive to be like water. To make things flow. To fundamentally connect the world. To serve as the fuel that
          powers an entire ecosystem. For real estate, our Flueid platform fuels transactions for our clients so they
          can easily flow from start to finish. And we deliver on this promise through our independence, collaborative
          spirit, commitment to transparency and focused execution.
        </div>
      </div>
    </SectionContent>
    <div className="img-numbers" />
    <SectionContent className="numbers-marginTop">
      <div className="border-fade border-fade_mobileNone">
        <h2>Our guiding principles</h2>
        <p className="txt-semiBold">We are inventors. Rethinkers. Architects and planners.</p>
        <p className="txt-semiBold">
          We are collaborators who recognize the value freedom unlocks by operating independently.
        </p>
        <p className="txt-semiBold">And we embrace the free flow of thought, collaboration and creativity.</p>
        <div className="p-2">
          We are logical. We untwist overly complicated or legacy processes for the betterment of our clients. We are
          tech-first, using data and insights to unlock greater potential. And we use it to help us all work ‘smarter’
          not harder in the future.
        </div>
      </div>
    </SectionContent>
  </section>
);
