import { SectionContent } from "components/common/sectionContent";
import React from "react";
import Slider, { Settings } from "react-slick";
import Pagination from "rc-pagination";
import { paginationTitles } from "../../types/pagination";
import { ArrowButton } from "components/controls/arrowButtonGroup";
import { usePagination } from "utilities/hooks/usePagination";
import { camelize } from "../../utilities/formatters";

interface Props {
  title?: string;
  subtitle?: string;
  className?: string;
  sliderSettings?: Settings;
  children: React.ReactNode[];
  usePagination?: boolean;
}

export const EditorialCardsSection: React.FC<Props> = ({
  title = "",
  subtitle,
  children,
  className,
  sliderSettings,
  usePagination: usePaginationFlag = true,
}) => {
  const settings: Settings = {
    arrows: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          dots: true,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    ...sliderSettings,
  };

  const sliderCards = React.Children.map(children, (child) => <div className="editorial-slider_margin">{child}</div>);

  // pagination state

  const { current, totalPages, setCurrent, pageSize, pageData } = usePagination({
    pageSize: 6,
    name: camelize(title),
    data: children || [],
  });

  const gridCards = React.Children.map(usePaginationFlag ? pageData : children, (child) => (
    <div className="editorial-cards_grid-item flex">{child}</div>
  ));
  //slice children

  return (
    <section className={`editorial-cards_section ${className || ""}`}>
      <SectionContent maxWidthType="section-maxWidth-small">
        <h2>{title}</h2>
        {subtitle && <h5 className="txt-regular">{subtitle}</h5>}

        <div className="editorial-cards_grid editorial-cards_grid-padding">{gridCards}</div>

        <Slider {...settings}>{sliderCards}</Slider>
        {usePaginationFlag && totalPages > 1 && (
          <div className="flex flex-justify-spaceBetween pagination-margin">
            <div className="p-2 txt-medium">
              Page {current} of {totalPages}
            </div>
            <Pagination
              className="pagination-container"
              onChange={(x) => setCurrent(x)}
              current={current}
              total={children.length}
              pageSize={pageSize}
              locale={paginationTitles}
              prevIcon={<ArrowButton direction="prev" />}
              nextIcon={<ArrowButton direction="next" />}
            />
          </div>
        )}
      </SectionContent>
    </section>
  );
};
