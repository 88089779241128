import * as React from "react";
import { Location } from "history";
import { Prompt } from "react-router-dom";
import Modal from "./modal";
import { Button } from "../controls/button";

interface Props {
  when?: boolean | undefined;
  navigate: (path: string, newLocation?: Location) => void;
  shouldBlockNavigation: (location: Location) => boolean;
}

interface State {
  modalOpen: boolean;
  nextLocation: Location | null;
  confirmedNavigation: boolean;
}

class RouteChangeGuard extends React.Component<Props, State> {
  state: State = {
    modalOpen: false,
    nextLocation: null,
    confirmedNavigation: false,
  };

  componentDidUpdate(prevProps: Props, prevState: State) {
    const { navigate } = this.props;
    const { modalOpen, nextLocation, confirmedNavigation } = this.state;

    // modal has closed and navigation confirmed, then navigate to next location
    if (prevState.modalOpen && !modalOpen && confirmedNavigation) {
      if (nextLocation) {
        // grab new location pathname and any query params if they exist and navigate
        let pathname = nextLocation.pathname;
        const queryParams = nextLocation.search;
        if (queryParams) pathname += queryParams;
        navigate(pathname, nextLocation);
      }
    }
  }

  showModal = (location: Location) => {
    this.setState({ modalOpen: true, nextLocation: location });
  };

  closeModal = () => this.setState({ modalOpen: false });

  // pass this to the Prompt's message prop
  //  - when message is fired, it passes the next desired location
  //  - returning false will block the navigation
  handleBlockedNavigation = (nextLocation: Location) => {
    const { shouldBlockNavigation } = this.props;
    const { confirmedNavigation } = this.state;

    // if they've not confirmed the navigation yet and shouldBlockNavigation === true
    if (!confirmedNavigation && shouldBlockNavigation(nextLocation)) {
      this.showModal(nextLocation);
      return false;
    }

    return true;
  };

  handleConfirmNavigation = () => {
    this.setState({
      modalOpen: false,
      confirmedNavigation: true,
    });
  };

  render() {
    const { when } = this.props;
    const { modalOpen } = this.state;

    return (
      <>
        <Prompt when={when} message={this.handleBlockedNavigation} />
        <Modal open={modalOpen} onClose={this.closeModal}>
          <h3>You have unsaved changes.</h3>
          <p>Do you wish to continue?</p>
          <Button className="btn-black margin-bottom_sm" onClick={this.handleConfirmNavigation}>
            Yes, Continue
          </Button>
          <div className="txt-underline cursor-pointer" onClick={this.closeModal}>
            Cancel
          </div>
        </Modal>
      </>
    );
  }
}

export default RouteChangeGuard;
