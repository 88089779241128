import React from "react";
import { formatPhoneNumber } from "utilities/formatters";
import { ReactComponent as IconClose } from "../../images/icons/icon-close.svg";

type InputErrorsProps = {
  errors?: string[];
};
export const InputErrors = ({ errors }: InputErrorsProps) => {
  if (!errors?.length) return null;
  return (
    <div className={`error-message-container ${(errors.length > 1 && "error-box-shadow") || ""}`}>
      {errors.map((x: string, index: number) => (
        <div key={`input-error-message-${index}`} className="error-message">
          {x}
        </div>
      ))}
    </div>
  );
};

// note: 'stringNumber' is used for something like zipCode, whose value needs to be a string of numeric characters
//  in this case actual input type will be 'number', ensuring no letters can be entered.
type InputType = "text" | "number" | "stringNumber" | "date" | "phone" | "password" | "textArea";

export interface Props<T> {
  type: InputType;
  name: T;
  value: string | number;
  onChange: (name: T, value: string) => void;

  //****
  // optional props
  label?: string;
  labelUseHTMLFor?: boolean;
  onClearClick?: (name: T) => void;
  placeHolder?: string;
  hasFocus?: boolean;
  focusOnMount?: boolean;
  required?: boolean;
  leftIcon?: JSX.Element;
  className?: string;
  styles?: React.CSSProperties;
  autoComplete?: boolean;
  min?: number;
  max?: number;
  pattern?: RegExp;
  valid?: boolean;
  errorMessages?: string[];
  editable?: boolean;
  inputVersion?: "v1" | "v2";
  tabIndex?: number;
}

class Input<T extends string> extends React.Component<Props<T>> {
  private inputRef = React.createRef<HTMLInputElement>();

  componentDidMount() {
    const { focusOnMount } = this.props;

    if (focusOnMount) {
      const ref = this.inputRef.current;
      if (ref) {
        ref.focus();
      }
    }
  }

  handleInputChange = (e: any) => {
    e.persist();

    const { type, max, value } = this.props;

    let newValue = e.target.value;
    let name = e.target.name;

    // HANDLE DIFFERENT INPUT TYPE CONDITIONS
    if (type === "phone") {
      newValue = formatPhoneNumber(newValue);
    } else if (type === "number") {
      // parse the value
      if (!value) {
        newValue = 0;
      } else {
        newValue = parseFloat(newValue);
      }

      // return out if value exceeds max
      // if (max && valueExceedsMax(value, value, max)) return;
    } else if (type === "stringNumber") {
      // return out if value exceeds max
      // if (max && valueExceedsMax(value, value, max)) return;
    }

    this.props.onChange(name, newValue);
  };

  handleClearClick = (e: any) => {
    e.persist();

    const input = this.inputRef.current;
    if (input) {
      input.focus();
    }

    this.props.onChange(this.props.name, "");
  };

  inputType = (): InputType => {
    const { type } = this.props;
    if (type === "stringNumber") return "number";
    return type;
  };

  renderInputV1 = (val: string | number, optionalAttributes: { [key: string]: any }) => {
    const {
      editable,
      styles,
      label,
      required,
      leftIcon,
      type,
      placeHolder,
      autoComplete,
      valid,
      errorMessages,
      name,
      labelUseHTMLFor,
    } = this.props;

    return (
      <div className={`input-group v1 ${editable === false ? "read-only" : ""}`} style={styles ? styles : {}}>
        {leftIcon ? leftIcon : ""}
        <input
          type={type === "stringNumber" ? "number" : type}
          min={type === "number" ? 1 : ""}
          value={type === "number" && !val ? "" : val}
          name={name}
          onChange={this.handleInputChange}
          placeholder={placeHolder}
          ref={this.inputRef}
          style={leftIcon ? { paddingLeft: "25px" } : {}}
          autoComplete={autoComplete ? "on" : "off"}
          {...optionalAttributes}
          className={`${errorMessages && errorMessages.length ? "error" : ""}`}
          id={label}
        />
        <label className={`label ${val ? "focus" : ""}`} htmlFor={labelUseHTMLFor === false ? undefined : label}>
          {label} {required && required === true ? "*" : ""}
        </label>
        <div className={`bar ${!valid ? "error" : ""}`} />
        {errorMessages && errorMessages.length
          ? errorMessages.map((x: string, index: number) => (
              <div key={`input-error-message-${index}`} className="error-message">
                {x}
              </div>
            ))
          : ""}
      </div>
    );
  };

  renderInputV2 = (value: string | number, optionalAttributes: { [key: string]: any }) => {
    const {
      editable,
      styles,
      label,
      required,
      leftIcon,
      type,
      placeHolder,
      autoComplete,
      valid,
      errorMessages,
      name,
      inputVersion,
      tabIndex,
      className,
    } = this.props;

    return (
      <div
        className={`input-group 
                    ${editable === false && "read-only"} 
                    ${inputVersion !== undefined && inputVersion}
                    ${valid !== undefined && !valid && "error"}
                    ${className || ""}`}
        style={styles ? styles : {}}>
        {label && label.length > 0 && (
          <div className={`label ${required && "required"}`}>
            {label} {required && required === true ? "*" : ""}
          </div>
        )}
        {leftIcon ? leftIcon : ""}
        <input
          type={this.inputType()}
          min={type === "number" ? 1 : ""}
          value={type === "number" && !value ? "" : value}
          tabIndex={tabIndex}
          name={name}
          onChange={this.handleInputChange}
          placeholder={placeHolder}
          ref={this.inputRef}
          style={leftIcon ? { paddingLeft: 25 } : {}}
          autoComplete={autoComplete ? "on" : "off"}
          {...optionalAttributes}
          className={`${errorMessages && errorMessages.length ? "error" : ""} ${
            type === "date" && !value ? "pad-right-5" : ""
          }`}
        />
        <div className="icon-closeWrapper">
          <IconClose className={`icon-close ${!value ? "hidden" : ""}`} onClick={this.handleClearClick} />
        </div>

        <InputErrors errors={errorMessages} />
      </div>
    );
  };

  render() {
    const { type, editable, value, max, pattern, inputVersion } = this.props;

    let val = value !== null ? value : "";

    const optionalAttributes: { [key: string]: any } = {};
    if (max && type === "text") optionalAttributes.maxLength = max;
    if (max && (type === "number" || type === "stringNumber")) optionalAttributes.max = max;
    if (pattern) optionalAttributes.pattern = pattern;
    if (editable === false) optionalAttributes.readOnly = true;

    return inputVersion === undefined || inputVersion === "v1"
      ? this.renderInputV1(val, optionalAttributes)
      : this.renderInputV2(val, optionalAttributes);
  }
}

export default Input;
