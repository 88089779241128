import { SectionContent } from "components/common/sectionContent";
import { useContext } from "react";
import { NavLink } from "react-router-dom";
import { EditorialsContext } from "types/editorial";
import { formatDateLong } from "utilities/formatters";
import { ReactComponent as IconArrowRight } from "../../images/icons/glyph-arrow.right.svg";

export const FeaturedStorySection = () => {
  // find most recent featuredStory
  const editorials = useContext(EditorialsContext);
  const featuredStory = editorials.find((x) => x.fields.featured);

  if (!featuredStory) return null;

  return (
    <section className="featuredStory-section">
      <SectionContent>
        <div className="flex flex-col-mobile">
          <img src={featuredStory.fields.featuredImg?.fields?.file?.url || ""} className="img-featuredStory" />
          <div>
            <h2>Featured</h2>
            <div className="subheader-2 dots-margin">. . . . . . . . . .</div>
            <div className="subheader-2">
              {featuredStory.fields.type} - {formatDateLong(featuredStory.fields.publishDate)}
            </div>
            <h3>{featuredStory.fields.title}</h3>
            <p>{featuredStory.fields.cardDescription}</p>
            <NavLink to={`/editorial/${featuredStory.fields.slug}`} className="inlineBlock">
              <div className="subheader-2 txt-underline flex flex-center">
                Continue Reading <IconArrowRight className="icon-arrowRight" />
              </div>
            </NavLink>
          </div>
        </div>
      </SectionContent>
    </section>
  );
};
