export interface TeamMember {
  linkedInUrl: string;
  imgPath: string;
  name: string;
  title: string;
}

const swenson: TeamMember = {
  name: "Eric Swenson",
  title: "Chairman",
  linkedInUrl: "https://www.linkedin.com/in/eric-swenson-78318811/",
  imgPath: "Eric_Swenson.jpg",
};

const bowman: TeamMember = {
  name: "Peter Bowman",
  title: "Founder & CEO",
  linkedInUrl: "https://www.linkedin.com/in/petercbowman/",
  imgPath: "Peter_Bowman.jpg",
};

const richter: TeamMember = {
  name: "Peter Richter",
  title: "Founder & President",
  linkedInUrl: "https://www.linkedin.com/in/peterrichter/",
  imgPath: "Peter_Richter.jpg",
};

export const leadershipTeam: TeamMember[] = [
  swenson,
  bowman,
  richter,
  {
    name: "Ryan Jaeger",
    title: "COO & CISO",
    linkedInUrl: "https://www.linkedin.com/in/ryanandrewjaeger/",
    imgPath: "Ryan_Jaeger.jpg",
  },
  {
    name: "Peter Gonzalez",
    title: "Chief Financial Officer",
    linkedInUrl: "https://www.linkedin.com/in/gonzalezpeter/",
    imgPath: "Peter_Gonzalez.png",
  },

  {
    name: "Dennis Frandsen",
    title: "Chief Software Architect",
    linkedInUrl: "https://www.linkedin.com/in/dennis-frandsen-3565012/",
    imgPath: "Dennis_Frandsen.jpg",
  },
  {
    name: "Matt Regan",
    title: "Chief Technology Officer",
    linkedInUrl: "https://www.linkedin.com/in/mmregan/",
    imgPath: "Matt_Regan.jpg",
  },
  {
    name: "Liz Lilly",
    title: "Chief Marketing Officer",
    linkedInUrl: "https://www.linkedin.com/in/lizrhilton/",
    imgPath: "Liz_Lilly.jpg",
  },
  {
    name: "Jerry Burditt",
    title: "EVP, General Counsel",
    linkedInUrl: "https://www.linkedin.com/in/jerry-burditt-a089b712/",
    imgPath: "Jerry_Burditt.jpg",
  },
  {
    name: "Crystal Krueger",
    title: "Head of Product, Flueid Decision",
    linkedInUrl: "",
    imgPath: "Crystal_Krueger.jpg",
  },
  {
    name: "Trish Troxler",
    title: "Head of Sales, Title",
    linkedInUrl: "https://www.linkedin.com/in/trishtroxler/",
    imgPath: "trish_Troxler.jpg",
  },
  {
    name: "Victoria Grammar",
    title: "Head of Human Resources",
    linkedInUrl: "https://www.linkedin.com/in/vgrammar/",
    imgPath: "Victoria_Grammar.jpg",
  },
];

export const boardOfDirectors: TeamMember[] = [
  swenson,
  bowman,
  richter,
  {
    name: "Jay Gaskill",
    title: "Board Member",
    linkedInUrl: "https://www.linkedin.com/in/jay-gaskill-a79b7759/",
    imgPath: "Jay_GasKill.jpg",
  },
  {
    name: "Max Chee",
    title: "Board Member",
    linkedInUrl: "https://www.linkedin.com/in/maxchee/",
    imgPath: "Max_Chee.jpg",
  },
];
