/** Determines if a string is a valid url */
export function isUrl(url = "") {
  try {
    return Boolean(new URL(url));
  } catch (error) {
    return false;
  }
}

/** Takes a string assumed to be a url and returns its path.  */
export function getUrlPath(url = "") {
  try {
    return new URL(url).pathname;
  } catch (error) {
    console.log(error);
    return "";
  }
}

/** Takes a string that might be a full url or just a pathname */
export function getInternalUrlPath(url = "") {
  return isUrl(url) ? getUrlPath(url) : url;
}
