import { Button } from "components/controls/button";
import React from "react";
import { NavLink } from "react-router-dom";
import { SectionContent } from "../common/sectionContent";
import Transition from "../controls/transition";
import Slider, { Settings } from "react-slick";
import { ArrowButton, ArrowButtonGroup } from "components/controls/arrowButtonGroup";
import DashedLineGroup, { DashedLine } from "../controls/dashedLines";
import { BlockQuote } from "components/common/blockQuote";
import { Testimony, testimonies } from "../../types/testimony";

interface TestimonyCardProps {
  testimony: Testimony;
}
const TestimonyCard = ({ testimony }: TestimonyCardProps) => (
  <div className={`testimonyCard`}>
    <Transition visible animation={"fadeIn"}>
      <BlockQuote>{testimony.quote}</BlockQuote>
    </Transition>
    <div className="testimonyCard-person flex">
      <div className="testimony-photo" style={{ backgroundImage: `url(/img/teamMembers/${testimony.img})` }} />
      <div>
        <div>{testimony.name}</div>
        <div>{testimony.title}</div>
      </div>
    </div>
  </div>
);

interface Props {
  color?: "white" | "black";
  showJoinLink?: boolean;
  showDashedLines?: boolean;
}

class TestimonySection extends React.Component<Props> {
  slider: Slider | null = null;

  next = () => {
    if (this.slider) this.slider.slickNext();
  };

  previous = () => {
    if (this.slider) this.slider.slickPrev();
  };

  render() {
    const { color, showJoinLink, showDashedLines } = this.props;

    const sliderSettings: Settings = {
      arrows: false,
      fade: true,
    };

    return (
      <section className={`testimony-section testimony-section_${color || ""}`}>
        <SectionContent>
          <div className="testimony-pagination-block">
            <Slider ref={(c) => (this.slider = c)} {...sliderSettings}>
              {testimonies.map((x, i) => (
                <TestimonyCard key={`testimonyCard-${i}`} testimony={x} />
              ))}
            </Slider>
            <ArrowButtonGroup>
              <ArrowButton direction="prev" onClick={this.previous} />
              <ArrowButton direction="next" onClick={this.next} />
            </ArrowButtonGroup>
            {showDashedLines && (
              <DashedLineGroup className="dashedLineGroup-1">
                {(visible) => (
                  <>
                    <DashedLine color="white" width={50} duration={2.5} delay={0} x={"5vw"} show={visible} />
                    <DashedLine color="white" width={35} duration={1.5} delay={0.2} x={"15vw"} show={visible} />
                    <DashedLine color="white" width={200} duration={1} delay={0.5} x={"18vw"} show={visible} />
                    <DashedLine color="white" width={75} duration={0.5} delay={0.7} x={"20vw"} show={visible} />
                  </>
                )}
              </DashedLineGroup>
            )}
          </div>
          {showJoinLink !== false && (
            <>
              <h3 className="txt-regular">Interested in joining the Flueid team?</h3>
              <NavLink to="/job-postings">
                <Button className="btn-black margin-auto">View Open Positions</Button>
              </NavLink>
            </>
          )}
        </SectionContent>
      </section>
    );
  }
}

export default TestimonySection;
