import React from "react";
import { SectionContent } from "../common/sectionContent";
import { AnimateNumber, AnimateNumberProps } from "../controls/animateNumber";
import Transition from "../controls/transition";
import Visible from "../controls/visible";
import DashedLineGroup, { DashedLine } from "components/controls/dashedLines";

interface NumbersGridItem
  extends Pick<AnimateNumberProps, "number" | "duration" | "format" | "frameDuration" | "fractionDigits"> {
  title: string;
  btnText1: string;
}

interface Props {
  item: NumbersGridItem;
}

const NumbersGridItem: React.FC<Props> = ({ item }) => (
  <div className="numbersGrid-item">
    <Visible>
      {(state) => (
        <Transition animation="fadeIn" visible={state.visible}>
          <div ref={state.ref} className="numbersGrid-header">
            <AnimateNumber
              show={state.visible}
              number={item.number}
              duration={item.duration}
              frameDuration={item.frameDuration}
              format={item.format}
              fractionDigits={item.fractionDigits}
            />
          </div>
        </Transition>
      )}
    </Visible>
    <div className="p-1 txt-blue">{item.title}</div>
    <p className="numbers-block-text">{item.btnText1}</p>
  </div>
);

const formatPercent = new Intl.NumberFormat("en-US", { style: "percent" }).format;

const gridItems: NumbersGridItem[] = [
  {
    number: 60,
    duration: 3000,
    title: "CLEAR TO SCHEDULE CLOSING",
    btnText1:
      "Achieved an average 60% clear-to-schedule closing result for lenders on home equity transactions resulting in faster, more seamless closings",
    format(num) {
      return formatPercent(num / 100);
    },
  },
  {
    number: 1000000,
    duration: 3000,
    title: "TRANSACTIONS",
    btnText1: "Supported +1M transactions through the platform",
    format(num) {
      return num.toLocaleString() + "+";
    },
    frameDuration: 8000,
  },
  {
    number: 221.5,
    duration: 3000,
    title: "IN LOAN VOLUME",
    btnText1: "Processed nearly $221.5 billion in loan volume to date",
    frameDuration: 6000,
    fractionDigits: 1,
    format(num) {
      return `$${num}B`;
    },
  },
];

export const NumbersSection = () => (
  <section className="numbers-section">
    <SectionContent maxWidthType="section-maxWidth-small">
      <h2 className="txt-align-center">Your collaborator in innovation </h2>
      <p className="txt-align-center text-subheader">In 2022 alone we:</p>
    </SectionContent>
    <SectionContent>
      <div className="numbersGrid">
        {gridItems.map((x: NumbersGridItem, i: number) => (
          <NumbersGridItem item={x} key={`numbersGridItem-${i}`} />
        ))}
      </div>
      <div className="row">
        <div className="numbers-img-flex">
          <div className="col-6">
            <p className="txt-align-center text-subheader" style={{ marginBottom: 0, marginTop: "2rem" }}>
              Awarded three years running
            </p>
            <div className="img-numbers-section img-mortgage"></div>
          </div>
        </div>
      </div>
      <DashedLineGroup className="dashedLineGroup-1">
        {(visible) => (
          <>
            <DashedLine width={46} duration={2.5} delay={0} x={"3vw"} show={visible} />
            <DashedLine width={201} duration={1.5} delay={0.2} x={"7vw"} show={visible} />
            <DashedLine width={81} duration={1} delay={0.5} x={"11vw"} show={visible} />
            <DashedLine width={169} duration={0.5} delay={0.7} x={"14vw"} show={visible} />
          </>
        )}
      </DashedLineGroup>
    </SectionContent>
  </section>
);
