import React from "react";
import { NavLink, RouteComponentProps } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll";
import { HashLink } from "react-router-hash-link";
import { withRouter } from "react-router-dom";
import { scrollWithOffset } from "utilities/scrollHelpers";

class Footer extends React.Component<RouteComponentProps> {
  render() {
    const onHomePage = this.props.location.pathname === "/" ? true : false;
    return (
      <div className="footer-container">
        <div className="content">
          <div className="logo-flueidmark" onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })} />
          <div className="content-text">
            <div className="p-4 txt-lightGray">
              © {new Date().getFullYear()} Flueid Software Corporation. All Rights Reserved.
            </div>
            <div className="links p-4">
              <div className="links-left m-r-0">
                <div className="privacy-terms">
                  <NavLink to="/privacy">
                    <div>Privacy Policy</div>
                  </NavLink>
                  <div>Patent 11,095,756</div>
                </div>
                <div>
                  <div className="m-r-5">Connect: </div>
                  <a className="m-r-5" href="https://www.instagram.com/flueidcorp/" target="_blank" rel="noreferrer">
                    Ig
                  </a>
                  |
                  <a className="m-r-5 m-l-5" href="https://www.facebook.com/flueid" target="_blank" rel="noreferrer">
                    Fb
                  </a>
                  |
                  <a
                    className="m-l-5"
                    href="https://www.linkedin.com/company/flueid/about/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Li
                  </a>
                </div>
              </div>
              <div className="links-right">
                {onHomePage ? (
                  <ScrollLink to="fueledByFlueid" smooth className="cursor-pointer" offset={100}>
                    Platform
                  </ScrollLink>
                ) : (
                  <HashLink
                    to="/#fueledByFlueid"
                    className="cursor-pointer"
                    scroll={(el: HTMLElement) => scrollWithOffset(el, 100)}
                  >
                    Platform
                  </HashLink>
                )}

                {onHomePage ? (
                  <ScrollLink to="integrations" smooth className="cursor-pointer" offset={100}>
                    Integrations
                  </ScrollLink>
                ) : (
                  <HashLink
                    to="/#integrations"
                    className="cursor-pointer"
                    scroll={(el: HTMLElement) => scrollWithOffset(el, 100)}
                  >
                    Integrations
                  </HashLink>
                )}
                <NavLink to="/security">Security</NavLink>
                <NavLink to="/about">About</NavLink>
                <NavLink to="/contact">Contact</NavLink>
                <NavLink to="/careers">Careers</NavLink>
                <NavLink to="/media">Media</NavLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Footer);
