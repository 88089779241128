export class ReqStatus {
  pending: boolean = false;
  ok: boolean = false;

  static Pending(): ReqStatus {
    return { pending: true, ok: false };
  }

  static Ok(): ReqStatus {
    return { pending: false, ok: true };
  }

  static Failed(): ReqStatus {
    return { pending: false, ok: false };
  }

  static Complete(prevReqStatus: ReqStatus, currentReqStatus: ReqStatus): boolean {
    return prevReqStatus.pending && !currentReqStatus.pending && currentReqStatus.ok ? true : false;
  }
}
