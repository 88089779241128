import { SectionContent } from "components/common/sectionContent";
import Transition from "components/controls/transition";
import Visible from "components/controls/visible";
import { NavLink } from "react-router-dom";

export const FlueidDecisionSection = () => (
  <section className="solutions-subsection flueidDecision-section">
    <SectionContent maxWidthType="section-maxWidth-small" className="solutions-top-header-container">
      <div className="">
        <div className="sulutions-subtitle-center flex flex-col flex-align-center">
          <h2 className="solutions-header">One simple integration, VOT in all transactions</h2>
          <p className="sulutions-subtitle-center solutions-subtitle-maxwidth">
            Our patented platform is fully diversified meaning no matter the market cycle, you can access one or
            multiple of our VOT products in real-time.
          </p>
        </div>
        <div className="solutions-row-flex">
          <p>Refinance</p>
          <p>Home Equity</p>
          <p>Purchase</p>
          <p>Loss Mitigation</p>
          <p>Loan Modification</p>
          <p>Portfolio Review</p>
        </div>
      </div>
    </SectionContent>

    <SectionContent
      className="flex section-paddingTop flueidDecision-paddingMobile"
      maxWidthType="section-maxWidth-large"
    >
      <div className="flex flex-col txtContainer txtContainer-left">
        <h3 className="txt-blue txt-header-api txt-semiBold">Flueid API</h3>
        <p>
          Use our simple APIs to create one secure integration with your standard platforms. Access multiple VOT
          products and customize systems and processes accordingly.
        </p>
        <NavLink to="/contact" className="btn-maxwidth">
          <button className="btn btn-blue flex flex-center btn-learn-more">Learn More</button>
        </NavLink>
      </div>
      <Visible>
        {(state) => (
          <Transition animation="fadeInRight" duration={2500} visible={state.visible}>
            <div className="photoContainer flueidDecision-photoContainer_mobile" ref={state.ref}>
              <div className="photo-flueidDecision" />
            </div>
          </Transition>
        )}
      </Visible>
    </SectionContent>
  </section>
);
