import React from "react";

const easeOutQuad = (t: number) => t * (2 - t);

export interface AnimateNumberProps {
  number: number;
  duration?: number;
  show?: boolean;
  direction?: "up" | "down";
  format?: (num: number) => string;
  frameDuration?: number;
  fractionDigits?: number;
}

interface State {
  count: number;
}

export class AnimateNumber extends React.Component<AnimateNumberProps, State> {
  state: State = {
    count: this.props.direction === "down" ? this.props.number : 0,
  };

  counter = 0;

  componentDidMount() {
    if (this.props.show) this.startCount();
  }

  componentDidUpdate(prevProps: AnimateNumberProps) {
    if (!prevProps.show && this.props.show) this.startCount();
  }

  componentWillUnmount(): void {
    window.clearInterval(this.counter);
  }

  startCount = () => {
    const { number, duration, direction, frameDuration: frameDurationProp } = this.props;
    const d: number = duration || 2000;
    const countTo = direction === "down" ? 0 : number;
    const frameDuration = frameDurationProp ? frameDurationProp / 60 : 1000 / 60;

    let frame = 0;
    const totalFrames = Math.round(d / frameDuration);

    this.counter = window.setInterval(() => {
      frame++;

      const progress = easeOutQuad(frame / totalFrames);

      this.setState({ count: direction === "down" ? number - number * progress : countTo * progress });

      if (frame >= totalFrames) {
        window.clearInterval(this.counter);
      }
    }, frameDuration);
  };

  render() {
    const { format, fractionDigits } = this.props;
    const count = Number(this.state.count.toFixed(fractionDigits || 0));

    if (format) return format(count);
    return count;
  }
}
