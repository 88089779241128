import Divider from "components/common/divider";
import { HeroSection } from "components/common/heroSection";
import DashedLineGroup, { DashedLine } from "components/controls/dashedLines";
import Transition from "components/controls/transition";
import Visible from "components/controls/visible";
import { ReactComponent as IconSecurityLarge } from "../../images/icons/icon-security-large.svg";
import logoSoc from "../../images/logos/logo-soc.png";

const HeroSectionSecurity = () => {
  return (
    <HeroSection className="sec-hero-section">
      <Visible>
        {({ visible, ref }) => (
          <Transition animation="fadeInUp" visible={visible}>
            <IconSecurityLarge
              className="icon-security-large"
              ref={(ref as unknown) as React.LegacyRef<SVGSVGElement> | undefined}
            />
          </Transition>
        )}
      </Visible>
      <DashedLineGroup className="heroSection-dashed-lines">
        {(visible) => (
          <>
            <DashedLine color="white" width={48} duration={2.1} delay={0} x={"1vw"} show={visible} />
            <DashedLine color="white" width={178} duration={1.3} delay={0.3} x={"4vw"} show={visible} />
            <DashedLine color="white" width={144} duration={0.9} delay={0.8} x={"9vw"} show={visible} />
          </>
        )}
      </DashedLineGroup>
      <div className="hero-flex">
        <div className="txt-white">
          <h1>Innovation Built on Security & Trust </h1>
          <h5 className="txt-light">
            Flueid is committed to innovating security and data protection standards to bring them to the highest level.
            That’s what builds trust with our client and confidence in our solutions.
          </h5>
        </div>
        <div className="ribbon-container ribbon-desktop">
          <div className="flex flex-col flex-center award-ribbon">
            <img src={logoSoc} width={200} />
            <Divider />
            <h1 className="margin-top_none margin-bottom_xsm">98%</h1>
            <p className="txt-semiBold p-2 margin-none">ALIGNMENT WITH NIST CSF</p>
          </div>
        </div>
      </div>
    </HeroSection>
  );
};

export default HeroSectionSecurity;
