import { Button } from "components/controls/button";
import React from "react";
import { NavLink } from "react-router-dom";
import { SectionContent } from "../common/sectionContent";
import Transition from "../controls/transition";
import Slider, { Settings } from "react-slick";
import { ArrowButton, ArrowButtonGroup } from "components/controls/arrowButtonGroup";
import DashedLineGroup, { DashedLine } from "../controls/dashedLines";
import { BlockQuote } from "components/common/blockQuote";
import { Testimony, testimonies } from "../../types/testimony";
import Visible from "components/controls/visible";

interface TestimonyCardProps {
  testimony: Testimony;
}

const TestimonyCardText = ({ testimony }: TestimonyCardProps) => (
  <>
    <div className="testimony-card-desktop-full">
      <div className={`testimonyCard testimony-card-careers-mobile`}>
        <div className=" flex">
          <Visible>
            {(state) => (
              <Transition>
                <div
                  ref={state.ref}
                  className="testimony-photo testimony-photo-mobile visibility-toggle-mobile-medium"
                  style={{
                    backgroundImage: `url(/img/teamMembers/${testimony.img})`,
                    width: `150px`,
                    height: `150px`,
                    backgroundRepeat: `no-repeat`,
                    margin: `0 auto`,
                    marginBottom: `50px`,
                  }}
                />
              </Transition>
            )}
          </Visible>

          <div className="text-container-desktop">
            <Visible>
              {(state) => (
                <Transition>
                  <div className="testimony-block-back-img">
                    <p ref={state.ref} className="testimony-block-text">
                      “{testimony.quote}”
                    </p>
                  </div>
                </Transition>
              )}
            </Visible>
          </div>
          <Visible>
            {(state) => (
              <Transition>
                <div ref={state.ref} className="background-circle-testimony"></div>
              </Transition>
            )}
          </Visible>
        </div>
        <div>
          <div className="testimony-mobile-card-bold testimony-mobile-card-subtitle">{testimony.name}</div>
          <div className=" testimony-mobile-card-subtitle subtitle-small testimony-mobile-card-subtitle-bottom">
            {testimony.title}
          </div>
        </div>
      </div>
    </div>
  </>
);

interface Props {
  color?: "white" | "black";
  showJoinLink?: boolean;
  showDashedLines?: boolean;
}

class TestimonySectionColumns extends React.Component<Props> {
  slider: Slider | null = null;

  next = () => {
    if (this.slider) this.slider.slickNext();
  };

  previous = () => {
    if (this.slider) this.slider.slickPrev();
  };

  render() {
    const sliderSettings: Settings = {
      arrows: false,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            dots: true,
          },
        },
      ],
    };

    return (
      <section className="testimony-section-columns">
        <div className="testimony-section-columns-inside-container">
          <div className="testimony-left-main-container"></div>
          <div className="testimony-right-main-container">
            <div className="employee-stories-img-text"></div>
            <div className="arrow-circle-container arrow-left  " onClick={this.previous}>
              <div className="arrow-testimony arrow-left-img"></div>
            </div>

            <div className="flex-btn-testimony">
              <div className="testimony-section-content-mobile">
                <div className="testimony-pagination-block">
                  <Slider ref={(c) => (this.slider = c)} {...sliderSettings}>
                    {testimonies.map((x, i) => (
                      <TestimonyCardText key={`testimonyCard-${i}`} testimony={x} />
                    ))}
                  </Slider>
                </div>
              </div>
            </div>
            <div className="arrow-circle-container arrow-right " onClick={this.next}>
              <div className="arrow-testimony arrow-right-img"></div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default TestimonySectionColumns;
