import React from "react";
import { UnregisterCallback } from "history";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { SubNavbar } from "components/navbar/subNavbar";

interface Props {
  children?: React.ReactNode;
  fallbackUI?: React.ReactNode;
  showFallbackUI?: boolean; // sometimes we may not want to show any fallbackUI, like if this is wrapped around a widget and we just want nothing to show
  useRouteListener?: boolean; // might not always need to use history.listen, like if this is wrapped around a widget and won't need to reset state.hasError when route changes
}

type ComponentProps = Props & RouteComponentProps;

interface State {
  hasError: boolean;
}

class ErrorBoundary extends React.Component<ComponentProps, State> {
  unregisterListener: UnregisterCallback | null = null;

  state: State = {
    hasError: false,
  };

  componentDidMount() {
    // default useRouteListener to true
    const { history, useRouteListener = true } = this.props;
    if (useRouteListener) {
      // route changes, reset state
      this.unregisterListener = history.listen(() => {
        if (this.state.hasError) this.setState({ hasError: false });
      });
    }
  }

  componentDidCatch(_error: Error, info: React.ErrorInfo) {
    this.setState({ hasError: true });
    console.log(_error);
    console.log(info);
  }

  componentWillUnmount() {
    if (this.unregisterListener) this.unregisterListener();
  }

  defaultFallbackUI = () => {
    return (
      <div className="errorBoundary-fallbackUI">
        <SubNavbar title="Error Message" />
        <div className="content-border">
          <div className="errorBoundary-header">Oops! Something went wrong.</div>
          <div className="errorBoundary-txt">
            Please refresh the page. If the problem persists, please contact{" "}
            <a href="mailto:oops@pro.flueid.com">oops@pro.flueid.com</a> for assistance.
          </div>
        </div>
      </div>
    );
  };

  render() {
    const { children, fallbackUI, showFallbackUI } = this.props;
    // error, determine what to show
    if (this.state.hasError) {
      if (showFallbackUI === false) return null;
      return fallbackUI || this.defaultFallbackUI();
    }
    return children;
  }
}

export default withRouter(ErrorBoundary);
