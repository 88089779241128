import React from "react";
import { TeamMember } from "../../types/teamMembers";

interface Props {
  teamMember: TeamMember;
}

export const TeamMemberCard: React.FC<Props> = ({ teamMember }) => (
  <div className="teamMember-card">
    <div className="teamMember-photo" style={{ backgroundImage: `url(/img/teamMembers/${teamMember.imgPath})` }} />
    <h5>{teamMember.name}</h5>
    <div className="p-2 txt-medium">{teamMember.title}</div>
    {teamMember.linkedInUrl ? (
      <a target="_blank" href={teamMember.linkedInUrl} className="icon-linkedIn" rel="noreferrer" />
    ) : (
      <div className="icon-linkedIn icon-linkedIn_disabled" />
    )}
  </div>
);
