import { useEffect, useState } from "react";

/** Hook for copying text to the clipboard */
export function useClipboard(text: string = "") {
  const [value, setValue] = useState(text);
  const [hasCopied, setHasCopied] = useState(false);

  // copy to clipboard handler
  const onCopy = () => {
    navigator.clipboard
      .writeText(value)
      .then(() => {
        setHasCopied(true);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  // set a timer to reset has copied flag
  useEffect(() => {
    if (hasCopied) {
      const COPIED_TIMER = 3000;
      const timer = window.setTimeout(() => {
        setHasCopied(false);
      }, COPIED_TIMER);

      return () => {
        window.clearTimeout(timer);
      };
    }
  }, [hasCopied]);

  return {
    value,
    setValue,
    hasCopied,
    onCopy,
  };
}
