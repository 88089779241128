import { Button } from "components/controls/button";
import Input from "components/controls/input";
import { mapSelectOption, ReactSelect } from "components/controls/select";
import React, { useState } from "react";
import { ActionMeta, OptionTypeBase } from "react-select";
import { prodSystemsOptions } from "types/demoRequest";
import { Editorial } from "types/editorial";
import {
  EditorialRequest,
  EditorialRequestFullKeyNames,
  editorialRequestRecipients,
  orgTypeOptions,
} from "types/editorialRequest";
import { Email, EmailResponse, StatusCodes } from "types/email";
import { ReqStatus } from "types/reqStatus";
import {
  allSchemaFieldsValid,
  newValidatedField,
  setValidatedFieldTrue,
  validateSchema,
  ValidationSchema,
  ValidationType,
} from "types/validation";
import { API_URL, callEmailHandler, defaultFetchOptions } from "utilities/apiService";
import { hasKey } from "utilities/tsHelpers";
import { Entry } from "contentful";
import Modal from "../controls/modal";

const InputMargin: React.FC = ({ children }) => <div className="margin-top_xsm margin-bottom_xsm">{children}</div>;

const validationDefault: ValidationSchema<EditorialRequest> = {
  name: newValidatedField(true, [ValidationType.Value]),
  email: newValidatedField(true, [ValidationType.Email]),
  orgName: newValidatedField(true, [ValidationType.Value]),
  orgType: newValidatedField(true, [ValidationType.Value]),
};

interface Props {
  editorial: Entry<Editorial>;
}

function EditorialRequestForm({ editorial }: Props) {
  const defaultRequest: EditorialRequest = {
    ...new EditorialRequest(),
    editorialId: editorial.sys.id,
    editorialTitle: editorial.fields.title,
  };
  const [request, setRequest] = useState<EditorialRequest>(defaultRequest);
  const [reqStatus, setReqStatus] = useState<ReqStatus>(new ReqStatus());
  const [validation, setValidation] = useState<ValidationSchema<EditorialRequest>>(validationDefault);
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const handleChange = (name: keyof EditorialRequest, value: string) => {
    setRequest({ ...request, [name]: value });
    if (validation[name]?.valid === false) {
      setValidation(setValidatedFieldTrue<EditorialRequest>(validation, name));
    }
  };

  const handleDropdownChange = (data: any, options: ActionMeta<OptionTypeBase>) => {
    if (options.name && hasKey(request, options.name)) {
      setRequest({
        ...request,
        [options.name]: data.value,
      });
      if (validation[options.name]?.valid === false) {
        setValidation(setValidatedFieldTrue<EditorialRequest>(validation, options.name));
      }
    }
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    // return out if req pending already
    if (reqStatus.pending) return;

    // validate
    const updatedValidation = validateSchema<EditorialRequest>(request, validation);
    const allFieldsValid = allSchemaFieldsValid<EditorialRequest>(updatedValidation);
    if (!allFieldsValid) {
      setValidation(updatedValidation);
      return;
    }

    // set status pending
    setReqStatus(ReqStatus.Pending);

    // construct email
    const email: Email = {
      to: editorialRequestRecipients,
      subject: "Flueid Corp Site Editorial Request",
      text: EditorialRequest.EmailBody(request),
    };

    // submit
    callEmailHandler(email)
      .then((data) => data.json())
      .then((resp: EmailResponse) => {
        console.log(resp);
        setModalOpen(true);
        if (resp.statusCode === StatusCodes.Ok) {
          setReqStatus(ReqStatus.Ok);
          setRequest(defaultRequest);
        } else {
          setReqStatus(ReqStatus.Failed);
        }
      })
      .catch((err) => {
        console.log(err);
        setModalOpen(true);
        setReqStatus(ReqStatus.Failed);
      });
  };

  return (
    <form className="editorialRequest-form margin-top_lg" onSubmit={handleSubmit}>
      <div className="subheader-1 txt-medium margin-bottom_xsm">Get the case study</div>
      <div className="subheader-2 margin-bottom_xsm">Contact our team and we'll send you the detailed case study</div>
      <InputMargin>
        <Input<keyof EditorialRequest>
          type="text"
          name="name"
          label="Your Name"
          placeHolder="Full Name"
          value={request.name}
          onChange={handleChange}
          inputVersion="v2"
          required
          valid={validation?.name?.valid}
          errorMessages={!validation?.name?.valid ? ["Please enter your name"] : undefined}
        />
      </InputMargin>
      <InputMargin>
        <Input<keyof EditorialRequest>
          type="text"
          name="email"
          label="Email Address"
          placeHolder="Email Address"
          value={request.email}
          onChange={handleChange}
          inputVersion="v2"
          required
          valid={validation?.email?.valid}
          errorMessages={!validation?.email?.valid ? ["Please enter valid email"] : undefined}
        />
      </InputMargin>
      <InputMargin>
        <Input<keyof EditorialRequest>
          type="text"
          name="orgName"
          label={EditorialRequestFullKeyNames.orgName}
          placeHolder={EditorialRequestFullKeyNames.orgName}
          value={request.orgName}
          onChange={handleChange}
          inputVersion="v2"
          required
          valid={validation?.orgName?.valid}
          errorMessages={!validation?.orgName?.valid ? ["Please enter your organization name"] : undefined}
        />
      </InputMargin>
      <InputMargin>
        <ReactSelect<keyof EditorialRequest>
          label={EditorialRequestFullKeyNames.orgType}
          options={orgTypeOptions}
          name="orgType"
          value={mapSelectOption(request.orgType)}
          onChange={handleDropdownChange}
          required
          errors={validation?.orgType?.valid === false ? ["Please select value"] : []}
        />
      </InputMargin>
      <Button type="submit" className="btn-black margin-top_sm">
        Request the Case Study
      </Button>
      <Modal open={modalOpen} onOpen={() => setModalOpen(true)} onClose={() => setModalOpen(false)}>
        {reqStatus.ok ? (
          <>
            <h3>Thank you for your interest in Flueid’s case study.</h3>
            <p>A member of our team will follow up shortly with the detailed content.</p>
          </>
        ) : (
          <>
            <h3>Oops! Something went wrong.</h3>
            <p>Please resubmit your request.</p>
          </>
        )}
      </Modal>
    </form>
  );
}

export default EditorialRequestForm;
