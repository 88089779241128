import Transition from "components/controls/transition";
import Visible from "components/controls/visible";

export interface EquityGraph {
  img: string;
  imgMobile: string;
  quote: React.ReactNode;
  rightTitle1?: string;
  rightTitle2?: string;
  rightTitle3?: string;
  rightTitle4?: string;
  rightTitle5?: string;
  rightTitle6?: string;
  rightTitle7?: string;
  topHeader: string;
}

export const graph: EquityGraph[] = [
  {
    topHeader: "Fast-track more than half of applications into a Clear to Schedule Closing pipeline",
    rightTitle1: "Approved",
    img: "workflow-HE-approved-min.png",
    imgMobile: "workflow-HE-approved-mobile-min.png",
    quote: (
      <>
        <Visible>
          {(state) => (
            <Transition animation={"fadeInUp"} duration={1000} delay={500} visible={state.visible}>
              <div ref={state.ref}>
                <span className="bottom-text-bold">Approved!</span> Immediately pre-qualify borrowers at the point of
                sale thanks to data and insights on the property and consumer.
              </div>
            </Transition>
          )}
        </Visible>
      </>
    ),
  },
  {
    topHeader: "Fast-track more than half of applications into a Clear to Schedule Closing pipeline",
    rightTitle2: "Strike First",
    img: "workflow-HE-strikeFirst-min.png",
    imgMobile: "workflow-HE-strikeFirst-mobile-min.png",
    quote: (
      <>
        <Visible>
          {(state) => (
            <Transition animation={"fadeInUp"} duration={1000} delay={500} visible={state.visible}>
              <div ref={state.ref}>
                <span className="bottom-text-bold">Strike First!</span> Receive an immediate, upfront clear/not yet
                clear closing decision and data to select, tailor and lock in the loan program.
              </div>
            </Transition>
          )}
        </Visible>
      </>
    ),
  },
  {
    topHeader: "Fast-track more than half of applications into a Clear to Schedule Closing pipeline",
    rightTitle3: "Clear",
    img: "workflow-HE-lenderDecision-s2-min.png",
    imgMobile: "workflow-HE-lenderDecision-mobile-s2-min.png",
    quote: (
      <>
        <Visible>
          {(state) => (
            <Transition animation={"fadeInUp"} duration={1000} delay={500} visible={state.visible}>
              <div ref={state.ref}>
                <span className="bottom-text-bold">Clear!</span> Put the majority of applications down a fast path to a
                <span className="bottom-text-bold"> 10-day or less close.</span>
              </div>
            </Transition>
          )}
        </Visible>
      </>
    ),
  },
  {
    topHeader: "Fast-track more than half of applications into a Clear to Schedule Closing pipeline",
    rightTitle4: "Filter",
    img: "workflow-HE-workflowDecision-min.png",
    imgMobile: "workflow-HE-workflowDecision-mobile-min.png",
    quote: (
      <>
        <Visible>
          {(state) => (
            <Transition animation={"fadeInUp"} duration={1000} delay={500} visible={state.visible}>
              <div ref={state.ref}>
                <span className="bottom-text-bold">Filter!</span> Use a workflow decision and key data to inform the
                property report – known to Flueid as a Title Condition Report – and insurance pairing.
              </div>
            </Transition>
          )}
        </Visible>
      </>
    ),
  },
  {
    topHeader: "Fast-track more than half of applications into a Clear to Schedule Closing pipeline",
    rightTitle5: "Receive",
    img: "workflow-HE-TCR-min.png",
    imgMobile: "workflow-HE-TCR-mobile-min.png",
    quote: (
      <>
        <Visible>
          {(state) => (
            <Transition animation={"fadeInUp"} duration={1000} delay={500} visible={state.visible}>
              <div ref={state.ref}>
                <span className="bottom-text-bold">Receive!</span> A quick and cost-effective insurable Title Condition
                Report with the information needed for loan underwriting, including ownership, encumbrances, legal and
                vesting.
              </div>
            </Transition>
          )}
        </Visible>
      </>
    ),
  },
  {
    topHeader: "Fast-track more than half of applications into a Clear to Schedule Closing pipeline",
    rightTitle6: "Insure & Close",
    img: "workflow-HE-close-min.png",
    imgMobile: "workflow-HE-close-mobile-min.png",
    quote: (
      <>
        <Visible>
          {(state) => (
            <Transition animation={"fadeInUp"} duration={1000} delay={500} visible={state.visible}>
              <div ref={state.ref}>
                <span className="bottom-text-bold">Insure & Close!</span> Coordinate the right home equity insurance and
                close the loan according to your investor guidelines and home equity profiles.
              </div>
            </Transition>
          )}
        </Visible>
      </>
    ),
  },
  {
    topHeader: "For Not Yet Clear decisions, immediately know the path to success",
    rightTitle7: "Path to Success",
    img: "workflow-HE-path-min.png",
    imgMobile: "workflow-HE-path-mobile-min.png",
    quote: (
      <>
        <Visible>
          {(state) => (
            <Transition animation={"fadeInUp"} duration={1000} delay={500} visible={state.visible}>
              <div ref={state.ref}>
                <span className="bottom-text-bold">Path to Success!</span> Know the exceptions to review and assign the
                right workflow and staffing upfront to reach closing.
              </div>
            </Transition>
          )}
        </Visible>
      </>
    ),
  },
];
