import React from "react";
import { ContactTopic, DropdownOption, getEmailRecipient } from "../../types/contactUsRequest";
import { ReqStatus } from "../../types/reqStatus";
import { allSchemaFieldsValid, setValidatedFieldTrue, validateSchema, ValidationSchema } from "../../types/validation";
import { Button } from "../controls/button";
import Input from "../controls/input";
import Popup, { PopupOption } from "../controls/popup";
import { withRouter, RouteComponentProps } from "react-router-dom";
import * as QueryString from "query-string";
import { Email, EmailResponse, StatusCodes } from "types/email";
import { validationHomeEquityDemo } from "../contact/validation";
import { callEmailHandler } from "utilities/apiService";
import { mapParsedValues } from "utilities/tsHelpers";
import DemoRequest, { DemoRequestKey } from "types/demoRequest";
import { ReactComponent as IconArrowDown } from "../../images/icons/glyph-dropdown.down.svg";

interface State {
  demoRequest: DemoRequest;
  popupOpen: boolean;
  reqStatus: ReqStatus;
  showSuccessMessage: boolean;
  validation: ValidationSchema<DemoRequest>;
}

export const ContactOkMessage = () => (
  <>
    <h3>Thank you for your email!</h3>
    <p>
      We appreciate your interest in Flueid. Our team is reviewing your request and will follow up directly with next
      steps. We look forward to connecting.
    </p>
  </>
);

export const ContactFailMessage = () => (
  <>
    <h3>Oops! Something went wrong.</h3>
    <p>
      Please refresh the page and try again or send an email to <a href="mailto:sales@flueid.com">sales@flueid.com</a>
    </p>
  </>
);

export enum IndustryTypes {
  Lender = "Lender",
  InsuranceProvider = "Insurance Provider",
  SoftwareProvider = "Software Provider",
  Other = "Other",
}

class DemoFormModel extends React.Component<RouteComponentProps, State> {
  defaultState: State = {
    demoRequest: new DemoRequest(),
    reqStatus: new ReqStatus(),
    validation: { ...validationHomeEquityDemo },
    popupOpen: false,
    showSuccessMessage: false,
  };

  state: State = { ...this.defaultState };

  componentDidMount() {
    const { location } = this.props;
    if (location.search) {
      const parsedParams = QueryString.parse(location.search);
      const demoRequest = mapParsedValues<DemoRequest>(new DemoRequest(), parsedParams);
      this.setState({ demoRequest });
    }
  }

  handleInputChange = (name: DemoRequestKey, value: string) => {
    const { demoRequest, validation } = this.state;
    this.setState({
      ...this.state,
      demoRequest: {
        ...demoRequest,
        [name]: value,
      },
      validation: setValidatedFieldTrue<DemoRequest>(validation, name),
    });
  };

  handleInputClear = (name: DemoRequestKey) => {
    const { validation, demoRequest } = this.state;
    this.setState({
      ...this.state,
      demoRequest: {
        ...demoRequest,
        [name]: "",
      },
      validation: setValidatedFieldTrue<DemoRequest>(validation, name),
    });
  };

  submitForm = (e: React.FormEvent) => {
    e.preventDefault();

    const { demoRequest, validation, reqStatus } = this.state;

    // don't allow extra smashes while request pending
    if (reqStatus.pending) return;

    // validate form
    const v = validateSchema<DemoRequest>(demoRequest, validation);
    const allFieldsValid = allSchemaFieldsValid<DemoRequest>(v);

    if (allFieldsValid) {
      // set reqStatus pending
      this.setState({ reqStatus: ReqStatus.Pending() });

      // construct email and fire request
      const email: Email = {
        to: [getEmailRecipient(ContactTopic.Demo)],
        subject: "Home Equity Decision for Lender Demo Request",
        text: DemoRequest.EmailBodyHomeEquity(demoRequest),
      };

      callEmailHandler(email)
        .then((data) => data.json())
        .then((resp: EmailResponse) => {
          if (resp.statusCode === StatusCodes.Ok) {
            // reset form, open modal
            this.setState({
              ...this.defaultState,
              reqStatus: ReqStatus.Ok(),
              showSuccessMessage: true,
            });
          } else {
            console.log(resp);
            // open modal and show error
            this.setState({
              reqStatus: ReqStatus.Failed(),
            });
          }
        })
        .catch((err) => {
          console.log(err);
          // open modal and show error
          this.setState({
            reqStatus: ReqStatus.Failed(),
          });
        });
    } else {
      this.setState({ validation: v });
    }
  };

  render() {
    const { validation, popupOpen, demoRequest } = this.state;
    const industryOptions: DropdownOption<IndustryTypes>[] = [
      { value: IndustryTypes.Lender, label: "Lender" },
      { value: IndustryTypes.InsuranceProvider, label: "Insurance Provider" },
      { value: IndustryTypes.SoftwareProvider, label: "Software Provider" },
      { value: IndustryTypes.Other, label: "Other" },
    ];
    return (
      <div className="container-relative-home-equity">
        <div className="form-section-home-equity">
          {!this.state.showSuccessMessage && (
            <form className="content-left" onSubmit={this.submitForm}>
              <div className="header-text-top">Flueid Decision for Lenders</div>
              <h1 className="form-title">Demo Request</h1>
              <h5 className="txt-light form-subtitle">We can't wait to hear from you.</h5>
              <div className="flueidProContactForm-inputSec">
                <Input<DemoRequestKey>
                  label="Full Name"
                  type="text"
                  name="name"
                  value={demoRequest.name}
                  onChange={this.handleInputChange}
                  required
                  valid={validation?.name?.valid}
                  errorMessages={validation?.name?.valid === false ? ["Please enter name"] : []}
                />
              </div>
              <div className="input-row-2">
                <div className="flueidProContactForm-inputSec text-input-half text-input-half-margin">
                  <Input<DemoRequestKey>
                    label="Email Address"
                    type="text"
                    name="email"
                    value={demoRequest.email}
                    onChange={this.handleInputChange}
                    required
                    valid={validation?.email?.valid}
                    errorMessages={validation?.email?.valid === false ? ["Please enter valid email"] : []}
                  />
                </div>
                <div className="flueidProContactForm-inputSec text-input-half">
                  <Input<DemoRequestKey>
                    label="Phone Number"
                    type="phone"
                    name="phone"
                    value={demoRequest.phone}
                    onChange={this.handleInputChange}
                    required
                    valid={validation?.phone?.valid}
                    errorMessages={validation?.phone?.valid === false ? ["Please enter valid phone number"] : []}
                  />
                </div>
              </div>
              <div className="input-row-2">
                <div className="flueidProContactForm-inputSec text-input-half text-input-half-margin">
                  <Input<DemoRequestKey>
                    label="Company Name"
                    type="text"
                    name="companyName"
                    value={demoRequest.companyName}
                    onChange={this.handleInputChange}
                    required
                    valid={validation?.companyName?.valid}
                    errorMessages={validation?.companyName?.valid === false ? ["Please enter company name"] : []}
                  />
                </div>

                <Popup
                  className="contactForm-popup text-input-half"
                  open={popupOpen}
                  onOpen={() => this.setState({ popupOpen: true })}
                  onClose={() => this.setState({ popupOpen: false })}
                  trigger={
                    <div className="flueidProContactForm-inputSec ">
                      <Input<DemoRequestKey>
                        label="Industry Type"
                        type="text"
                        name="industryType"
                        value={demoRequest.industryType}
                        onChange={this.handleInputChange}
                        editable={false}
                        // needs to be false for clicking the label to activate popup
                        labelUseHTMLFor={false}
                        required
                        valid={validation?.industryType?.valid}
                        errorMessages={validation?.industryType?.valid === false ? ["Please select industry type"] : []}
                      />
                      <IconArrowDown className="icon-arrow" />
                    </div>
                  }
                >
                  {industryOptions.map((x: DropdownOption<IndustryTypes>, i: number) => (
                    <PopupOption<DemoRequestKey, IndustryTypes>
                      key={`contact-topic-${i}`}
                      value={x.value}
                      name="industryType"
                      onClick={(name, value) => {
                        this.setState({
                          demoRequest: {
                            ...demoRequest,
                            industryType: value || "",
                          },
                          popupOpen: false,
                        });
                      }}
                    >
                      {x.value}
                    </PopupOption>
                  ))}
                </Popup>
              </div>
              <div className="flueidProContactForm-inputSec msg">
                <Input<DemoRequestKey>
                  label="Your Message"
                  type="text"
                  name="message"
                  value={demoRequest.message}
                  onChange={this.handleInputChange}
                />
              </div>
              <div className="flueidProContactForm-submitBtnSec">
                <div className="p-4 txt-light demo-form-bottom-subtext-he">
                  <span className="txt-semiBold">We care about your privacy. </span>
                  Your data is secure and we will never share your information with outside parties.
                </div>
                <Button type="submit" className="btn-black" onSubmit={this.submitForm}>
                  SUBMIT
                </Button>
              </div>
            </form>
          )}
          {this.state.showSuccessMessage && (
            <>
              <div className="header-text-top">Demo Request</div>
              <h1 className="form-title">Success!</h1>
              <h5 className="txt-light form-subtitle">We will reach out to coordinate a meeting and next steps.</h5>
            </>
          )}
        </div>
      </div>
    );
  }
}

export default withRouter(DemoFormModel);
