import React from "react";

interface ButtonProps
  extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  direction: "prev" | "next";
}

export const ArrowButton: React.FC<ButtonProps> = (props) => (
  <button className={`btn btn-arrow btn-arrow_${props.direction}`} {...props}>
    {props.children}
  </button>
);

export const ArrowButtonGroup: React.FC = (props) => (
  <div className="arrowButton-group flex flex-center">{props.children}</div>
);
