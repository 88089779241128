import React from "react";

type MaxWidthType =
  | "section-maxWidth-xxsm"
  | "section-maxWidth-xsm"
  | "section-maxWidth-small"
  | "section-maxWidth-large";

interface Props {
  maxWidthType?: MaxWidthType;
  className?: string;
}

export const SectionContent: React.FC<Props> = (props) => (
  <div
    className={`
        section-content 
        ${props.className || ""} 
        ${props.maxWidthType || ""}
    `}>
    {props.children}
  </div>
);
