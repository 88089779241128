import React, { PropsWithChildren } from "react";
import DemoRequest, {
  CompanyType,
  DemoRequestKey,
  //industryTypeOptions,
  prodSystemsOptions,
} from "../../types/demoRequest";
import { ValidationSchema } from "../../types/validation";
import Input from "../controls/input";
import { ActionMeta, OptionTypeBase } from "react-select";
import { stateOptions } from "../../types/stateOptions";
import { mapSelectOption, mapSelectOptionMulti, ReactSelect } from "../controls/select";
import CheckboxGroup from "../controls/checkboxGroup";

interface InputFlexProps {
  companyTypeName?: CompanyType;
  inputName?: DemoRequestKey;
}
export const InputFlex = ({ children, companyTypeName, inputName }: PropsWithChildren<InputFlexProps>) => {
  if (!children) return null;
  const x = React.Children.map(children, (child, index) => {
    if (React.isValidElement(child)) {
      const props: Partial<any> & React.Attributes = { key: companyTypeName + "-" + inputName + "_" + index };
      return React.cloneElement(child, props);
    }
  });
  return <div className="flex">{x}</div>;
};

type CompanyInfo = {
  [key in CompanyType]?: React.ReactNode;
};

interface CompanyFieldsProps {
  demoRequest: DemoRequest;
  validation: ValidationSchema<DemoRequest>;
  handleDropdownChange: (data: any, options: ActionMeta<OptionTypeBase>) => void;
  handleInputChange: (name: DemoRequestKey, value: string) => void;
  handleCheckboxChange: (name: DemoRequestKey, value: string[]) => void;
}

export const CompanyFields = ({
  demoRequest,
  validation,
  handleDropdownChange,
  handleInputChange,
  handleCheckboxChange,
}: CompanyFieldsProps): React.ReactNode => {
  const companyInfo: CompanyInfo = {
    "Title Underwriter": (
      <>
        <InputFlex companyTypeName="Title Underwriter" inputName="stateFootprint">
          <CheckboxGroup<DemoRequestKey>
            items={stateOptions}
            onChange={handleCheckboxChange}
            name="stateFootprint"
            label="State Footprint:"
            required
            //key={"checkboxGroup-1"}
            errors={validation?.stateFootprint?.valid === false ? ["Please select state(s)"] : []}
          />
        </InputFlex>
        <InputFlex companyTypeName="Title Underwriter" inputName="prodSystems">
          <ReactSelect<DemoRequestKey>
            label="Title Production System(s):"
            options={prodSystemsOptions}
            isMulti
            name="prodSystems"
            //key={uniqueID("select")}
            value={mapSelectOptionMulti(demoRequest.prodSystems)}
            onChange={handleDropdownChange}
            placeholder="Select"
            required
            errors={validation?.prodSystems?.valid === false ? ["Please select value"] : []}
          />
          <Input<DemoRequestKey>
            type="text"
            name="titleAgents"
            label="Title Agents: "
            placeHolder="Title Agents"
            value={demoRequest.titleAgents}
            onChange={handleInputChange}
            inputVersion="v2"
          />
        </InputFlex>
      </>
    ),
    "Title Agency": (
      <>
        <InputFlex companyTypeName="Title Agency" inputName="stateFootprint">
          <CheckboxGroup<DemoRequestKey>
            items={stateOptions}
            onChange={handleCheckboxChange}
            name="stateFootprint"
            label="State Footprint: "
            required
            //key={"checkboxGroup-2"}
            errors={validation?.stateFootprint?.valid === false ? ["Please select state(s)"] : []}
          />
        </InputFlex>
        <InputFlex companyTypeName="Title Agency" inputName="prodSystems">
          <ReactSelect<DemoRequestKey>
            label="Title Production Systems:"
            options={prodSystemsOptions}
            isMulti
            value={mapSelectOptionMulti(demoRequest.prodSystems)}
            name="prodSystems"
            //key={uniqueID("select")}
            onChange={handleDropdownChange}
            required
            errors={validation?.prodSystems?.valid === false ? ["Please select value"] : []}
          />
          <Input<DemoRequestKey>
            type="text"
            name="orderVolume"
            label="Monthly Order Volume:"
            placeHolder="Monthly Order Volume"
            value={demoRequest.orderVolume}
            onChange={handleInputChange}
            inputVersion="v2"
          />
        </InputFlex>
        <InputFlex companyTypeName="Title Agency" inputName="titleUnderwriters">
          <Input<DemoRequestKey>
            type="text"
            name="titleUnderwriters"
            label="Title Underwriters:"
            placeHolder="Title Underwriters"
            value={demoRequest.titleUnderwriters}
            onChange={handleInputChange}
            inputVersion="v2"
            required
            valid={validation?.titleUnderwriters?.valid}
            errorMessages={validation?.titleUnderwriters?.valid === false ? ["Please enter value"] : []}
          />
        </InputFlex>
      </>
    ),
    "Escrow/Closing": (
      <>
        <InputFlex companyTypeName="Escrow/Closing" inputName="stateFootprint">
          <CheckboxGroup<DemoRequestKey>
            items={stateOptions}
            onChange={handleCheckboxChange}
            name="stateFootprint"
            label="State Footprint: "
            required
            //key={"checkboxGroup-1"}
            errors={validation?.stateFootprint?.valid === false ? ["Please select state(s)"] : []}
          />
        </InputFlex>
        <InputFlex companyTypeName="Escrow/Closing" inputName="prodSystems">
          <ReactSelect<DemoRequestKey>
            label="Title Production Systems:"
            options={prodSystemsOptions}
            isMulti
            value={mapSelectOptionMulti(demoRequest.prodSystems)}
            name="prodSystems"
            //key={uniqueID("select")}
            onChange={handleDropdownChange}
            required
            errors={validation?.prodSystems?.valid === false ? ["Please select value"] : []}
          />
          <Input<DemoRequestKey>
            type="text"
            name="orderVolume"
            label="Monthly Order Volume:"
            placeHolder="Monthly Order Volume"
            value={demoRequest.orderVolume}
            onChange={handleInputChange}
            inputVersion="v2"
          />
        </InputFlex>
        <InputFlex companyTypeName="Escrow/Closing" inputName="titleUnderwriters">
          <Input<DemoRequestKey>
            type="text"
            name="titleUnderwriters"
            label="Title Underwriters:"
            placeHolder="Title Underwriters"
            value={demoRequest.titleUnderwriters}
            onChange={handleInputChange}
            inputVersion="v2"
            required
            valid={validation?.titleUnderwriters?.valid}
            errorMessages={validation?.titleUnderwriters?.valid === false ? ["Please enter value"] : []}
          />
        </InputFlex>
      </>
    ),
    "Mortgage Lender": (
      <>
        <InputFlex companyTypeName="Mortgage Lender" inputName="stateFootprint">
          <CheckboxGroup<DemoRequestKey>
            items={stateOptions}
            onChange={handleCheckboxChange}
            name="stateFootprint"
            label="State Footprint: "
            required
            //key={"checkboxGroup-1"}
            errors={validation?.stateFootprint?.valid === false ? ["Please select state(s)"] : []}
          />
        </InputFlex>
        <InputFlex companyTypeName="Mortgage Lender" inputName="posSystem">
          <Input<DemoRequestKey>
            type="text"
            name="posSystem"
            label="POS System:"
            placeHolder="POS System"
            value={demoRequest.posSystem}
            onChange={handleInputChange}
            inputVersion="v2"
            required
            valid={validation?.posSystem?.valid}
            errorMessages={validation?.posSystem?.valid === false ? ["Please enter value"] : []}
          />
          <Input<DemoRequestKey>
            type="text"
            name="losSystem"
            label="LOS System:"
            placeHolder="LOS System"
            value={demoRequest.losSystem}
            onChange={handleInputChange}
            inputVersion="v2"
          />
        </InputFlex>
        <InputFlex companyTypeName="Mortgage Lender" inputName="titleAgents">
          <Input<DemoRequestKey>
            type="text"
            name="titleAgents"
            label="Title Agents:"
            placeHolder="Title Agents"
            value={demoRequest.titleAgents}
            onChange={handleInputChange}
            inputVersion="v2"
            required
            valid={validation?.titleAgents?.valid}
            errorMessages={validation?.titleAgents?.valid === false ? ["Please enter value"] : []}
          />
          <Input<DemoRequestKey>
            type="text"
            name="titleUnderwriters"
            label="Title Underwriters:"
            placeHolder="Title Underwriters"
            value={demoRequest.titleUnderwriters}
            onChange={handleInputChange}
            inputVersion="v2"
            required
            valid={validation?.titleUnderwriters?.valid}
            errorMessages={validation?.titleUnderwriters?.valid === false ? ["Please enter value"] : []}
          />
        </InputFlex>
      </>
    ),
    "Mortgage Servicer": (
      <>
        <InputFlex companyTypeName="Mortgage Servicer" inputName="stateFootprint">
          <CheckboxGroup<DemoRequestKey>
            items={stateOptions}
            onChange={handleCheckboxChange}
            name="stateFootprint"
            label="State Footprint: "
            required
            //key={"checkboxGroup-1"}
            errors={validation?.stateFootprint?.valid === false ? ["Please select state(s)"] : []}
          />
        </InputFlex>
      </>
    ),
    "Real Estate": (
      <>
        <InputFlex companyTypeName="Real Estate" inputName="stateFootprint">
          <CheckboxGroup<DemoRequestKey>
            items={stateOptions}
            onChange={handleCheckboxChange}
            name="stateFootprint"
            label="State Footprint:"
            required
            //key={"checkboxGroup-1"}
            errors={validation?.stateFootprint?.valid === false ? ["Please select state(s)"] : []}
          />
        </InputFlex>
        <InputFlex companyTypeName="Real Estate" inputName="bizCities">
          <Input<DemoRequestKey>
            type="text"
            label="Business Cities:"
            value={demoRequest.bizCities}
            name="bizCities"
            placeHolder="Business Cities"
            onChange={handleInputChange}
            inputVersion="v2"
            required
            valid={validation?.bizCities?.valid}
            errorMessages={validation?.bizCities?.valid === false ? ["Please enter value"] : []}
          />
        </InputFlex>
        <InputFlex companyTypeName="Real Estate" inputName="bizCounties">
          <Input<DemoRequestKey>
            type="text"
            name="bizCounties"
            label="Business County(s): "
            placeHolder="Business Counties"
            value={demoRequest.bizCounties}
            onChange={handleInputChange}
            inputVersion="v2"
          />
        </InputFlex>
      </>
    ),
    "Foreclosure Attorney": (
      <>
        <InputFlex companyTypeName="Foreclosure Attorney" inputName="stateFootprint">
          <CheckboxGroup<DemoRequestKey>
            items={stateOptions}
            onChange={handleCheckboxChange}
            name="stateFootprint"
            label="State Footprint: "
            required
            //key={"checkboxGroup-1"}
            errors={validation?.stateFootprint?.valid === false ? ["Please select state(s)"] : []}
          />
        </InputFlex>
      </>
    ),
    "Capital Markets/Investor": (
      <>
        <InputFlex companyTypeName="Capital Markets/Investor" inputName="stateFootprint">
          <CheckboxGroup<DemoRequestKey>
            items={stateOptions}
            onChange={handleCheckboxChange}
            name="stateFootprint"
            label="State Footprint: "
            required
            //key={"checkboxGroup-1"}
            errors={validation?.stateFootprint?.valid === false ? ["Please select state(s)"] : []}
          />
        </InputFlex>
      </>
    ),
    "Digital Mortgage/Title SaaS": (
      <>
        <InputFlex companyTypeName="Digital Mortgage/Title SaaS" inputName="stateFootprint">
          <CheckboxGroup<DemoRequestKey>
            items={stateOptions}
            onChange={handleCheckboxChange}
            name="stateFootprint"
            label="State Footprint: "
            required
            //key={"checkboxGroup-1"}
            errors={validation?.stateFootprint?.valid === false ? ["Please select state(s)"] : []}
          />
        </InputFlex>
      </>
    ),
    Other: (
      <>
        <InputFlex companyTypeName="Other" inputName="industryType">
          <Input<DemoRequestKey>
            type="text"
            name="industryType"
            label="Industry Type"
            placeHolder="Industry Type"
            value={demoRequest.industryType}
            onChange={handleInputChange}
            inputVersion="v2"
            required
            valid={validation?.industryType?.valid}
            errorMessages={validation?.industryType?.valid === false ? ["Please enter Industry Type"] : []}
          />
        </InputFlex>
      </>
    ),
  };

  return companyInfo[demoRequest.companyType];
};
