import React from "react";
import { classNames } from "utilities/classNames";

interface Props {
  className?: string;
  backgroundImg?: string;
}

export const HeroSection: React.FC<Props> = (props) => (
  <section
    className={classNames("hero-section", props.className)}
    style={{ backgroundImage: props.backgroundImg ? `url(${props.backgroundImg})` : undefined }}
  >
    <div className="content">{props.children}</div>
  </section>
);
