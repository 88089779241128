import { SectionContent } from "../common/sectionContent";
import DashedLineGroup, { DashedLine } from "components/controls/dashedLines";
import { TextLink } from "components/common/textLink";

export const JoinSection = () => (
  <section className="join-section">
    <SectionContent>
      <div className="graphic-joinTeam" />
      <div>
        <h3>Join our team.</h3>
        <p className="txt-light">We are growing and always looking for exceptional people to join our team.</p>
        <TextLink to="/careers" color="blue">
          Check out what we are all about
        </TextLink>
      </div>
      <DashedLineGroup className="dashedLineGroup-1">
        {(visible) => (
          <>
            <DashedLine width={123} duration={2.5} delay={0} x={"13vw"} show={visible} />
            <DashedLine width={78} duration={1.5} delay={0.2} x={"21vw"} show={visible} />
            <DashedLine width={51} duration={0.5} delay={0.7} x={"25vw"} show={visible} />
          </>
        )}
      </DashedLineGroup>
    </SectionContent>
  </section>
);
