import { HeroSectionHomeEquity as HeroSection } from "./heroSection";
import Footer from "../footer";
import { useEffect, useState } from "react";
import { EditorialCardsSection } from "components/media/editorialCardsSection";
import EditorialCard from "components/media/editorialCard";
import GraphSectionHomeEquity from "./graphSection";
import DecisionReportHomeEquity from "./decisionReport";
import { EquityServicesHomeEquity } from "./equityServicesSection";
import { BreakSectionHomeEquity } from "./blackBreakSection";
import Navbar from "components/navbar/navbar";
import { getEditorialCardSection, IEditorialCardSection } from "types/editorialCardSection";

const HomeEquityPageContainer = () => {
  const [editorialCardSection, setEditorialCardSection] = useState<IEditorialCardSection>();

  useEffect(() => {
    const editorialSectionId = "2JkYTFR11hBJg477nrFWlv";
    getEditorialCardSection(editorialSectionId)
      .then((entry) => setEditorialCardSection(entry.fields))
      .catch((e) => console.error(e));
  }, []);

  return (
    <div className="homeEquityPage-container">
      <Navbar color="trans-white" offsetLogo useDemoFormModal />
      <div>
        <HeroSection />
        <BreakSectionHomeEquity />
        <GraphSectionHomeEquity />
        <DecisionReportHomeEquity />
        <EquityServicesHomeEquity />
        <div className="editorial-container">
          {!!editorialCardSection?.editorials && (
            <EditorialCardsSection title={editorialCardSection.title} subtitle={editorialCardSection.subtitle}>
              {editorialCardSection.editorials.map((x) => (
                <EditorialCard editorial={x} key={x.sys.id}>
                  <EditorialCard.Subtitle2>
                    <div className="txt-blue">{x.fields.type}</div>
                  </EditorialCard.Subtitle2>
                  <EditorialCard.Title>{x.fields.title}</EditorialCard.Title>
                  <EditorialCard.Subtitle>{x.fields.cardDescription}</EditorialCard.Subtitle>
                  <EditorialCard.Link />
                </EditorialCard>
              ))}
            </EditorialCardsSection>
          )}
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default HomeEquityPageContainer;
