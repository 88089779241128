import React from "react";
import { uniqueID } from "utilities/formatters";
import { OptionType } from "../../types/stateOptions";
import { Button } from "./button";
import { InputErrors } from "./input";

interface Props<T> {
  items: OptionType[];
  // selectedItems: (string | number)[];
  onChange: (name: T, values: string[]) => void;
  name: T;
  label?: string;
  className?: string;
  errors?: string[];
  required?: boolean;
}

interface State {
  isShiftDown: boolean;
  lastSelectedItem: string | null;
  selectedItems: string[];
  allStatesActive: boolean;
}

class CheckboxGroup<T> extends React.Component<Props<T>, State> {
  ref = React.createRef<HTMLDivElement>();

  state: State = {
    isShiftDown: false,
    lastSelectedItem: null,
    selectedItems: [],
    allStatesActive: false,
  };

  componentDidMount() {
    document.addEventListener("keyup", this.handleKeyUp, false);
    document.addEventListener("keydown", this.handleKeyDown, false);
    const ref = this.ref.current;
    if (ref) {
      ref.addEventListener("selectstart", this.handleSelectStart, false);
    }
  }

  componentWillUnmount() {
    document.removeEventListener("keyup", this.handleKeyUp);
    document.removeEventListener("keydown", this.handleKeyDown);
    const ref = this.ref.current;
    if (ref) {
      ref.removeEventListener("selectstart", this.handleSelectStart);
    }
  }

  handleSelectStart = (e: Event) => {
    // if we're clicking the labels it'll select the text if holding shift
    if (this.state.isShiftDown) {
      e.preventDefault();
    }
  };

  handleKeyUp = (e: KeyboardEvent) => {
    if (e.key === "Shift" && this.state.isShiftDown) {
      this.setState({ isShiftDown: false });
    }
  };

  handleKeyDown = (e: KeyboardEvent) => {
    if (e.key === "Shift" && !this.state.isShiftDown) {
      this.setState({ isShiftDown: true });
    }
  };

  handleSelectItem = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const nextValue = this.getNextValue(value);

    this.setState({ selectedItems: nextValue, lastSelectedItem: value }, () => {
      this.props.onChange(this.props.name, this.state.selectedItems);
    });
  };

  getNextValue = (value: string) => {
    const { isShiftDown, selectedItems } = this.state;
    const hasBeenSelected = selectedItems.includes(value);

    if (isShiftDown) {
      const newSelectedItems = this.getNewSelectedItems(value);
      // de-dupe the array using a Set
      const selections = Array.from(new Set([...selectedItems, ...newSelectedItems]));

      if (hasBeenSelected) {
        return selections.filter((item) => !newSelectedItems.includes(item));
      }

      return selections;
    }

    // if it's already in there, remove it, otherwise append it
    return selectedItems.includes(value) ? selectedItems.filter((item) => item !== value) : [...selectedItems, value];
  };

  getNewSelectedItems = (value: string) => {
    const { lastSelectedItem } = this.state;
    const { items } = this.props;
    const currentSelectedIndex = items.findIndex((item) => item.value === value);
    const lastSelectedIndex = items.findIndex((item) => item.value === lastSelectedItem);

    return items
      .slice(Math.min(lastSelectedIndex, currentSelectedIndex), Math.max(lastSelectedIndex, currentSelectedIndex) + 1)
      .map((item) => item.value);
  };

  selectAll = () => {
    this.setState(
      {
        selectedItems: this.props.items.map((x) => x.value),
        allStatesActive: !this.state.allStatesActive,
        isShiftDown: false,
        lastSelectedItem: null,
      },
      () => {
        this.props.onChange(this.props.name, this.state.selectedItems);
      },
    );
  };

  clearAll = () => {
    this.setState(
      {
        isShiftDown: false,
        selectedItems: [],
        lastSelectedItem: null,
      },
      () => {
        this.props.onChange(this.props.name, this.state.selectedItems);
      },
    );
  };

  renderItems = () => {
    const { selectedItems } = this.state;
    const { items, errors } = this.props;
    return items.map((item) => {
      const { value, label } = item;
      return (
        <div
          className={`
                input-group v2 
                ${selectedItems.includes(value) ? "checked" : ""}
                ${errors?.length ? "error" : ""}
              `}
          key={value}>
          <input
            onChange={this.handleSelectItem}
            type="checkbox"
            checked={selectedItems.includes(value)}
            value={value}
            id={`item-${value}`}
          />
          <label htmlFor={`item-${value}`}>{label}</label>
        </div>
      );
    });
  };

  render() {
    const { className, label, required, errors } = this.props;
    return (
      <div className={`checkboxGroup input-group v2 ${className || ""} ${errors?.length ? "error" : ""}`}>
        {label && label.length > 0 && (
          <div className={`label ${required && "required"}`}>
            {label} {required && required === true ? "*" : ""}
          </div>
        )}
        <div className="flex border">
          <div className="btns">
            <Button type="button" onClick={this.selectAll}>
              All States
            </Button>
            <Button type="button" onClick={this.clearAll}>
              Reset
            </Button>
          </div>
          <div ref={this.ref} className="checkboxGroup-grid">
            {this.renderItems()}
          </div>
        </div>
        <InputErrors errors={errors} />
      </div>
    );
  }
}

export default CheckboxGroup;
