import { SectionContent } from "components/common/sectionContent";
import Transition from "components/controls/transition";
import { SubNavbar } from "components/navbar/subNavbar";
import Footer from "../footer";

export const PrivacyPage = () => (
  <Transition visible animation="fadeIn">
    <div className="privacyPage-container">
      <SubNavbar title="Privacy Policy" />
      <SectionContent maxWidthType="section-maxWidth-xsm">
        <>
          <h2 style={{ marginTop: "150px" }}>Flueid Privacy Policy</h2>

          <p>Effective January 1, 2020</p>

          <p>
            Flueid Software Corporation ("Flueid" or "we") respects and is committed to protecting your privacy. This
            Privacy Notice explains how we collect, use, and protect personal information, when and to whom we disclose
            such information, and the choices you have about the use and disclosure of that information.
          </p>

          <h3 className="txt-semiBold">Personal Information Collected as a Third-Party Service Provider</h3>
          <p>
            In almost all cases that Flueid is receiving Personal Information, it is acting as a third-party service
            provider to its clients. In such cases, we may collect or receive Personal Information about or from
            individuals for the sole purpose of providing services to or on behalf of such clients. In cases where
            Flueid is providing services to or on behalf of such clients, we do not share consumer information with
            third parties, other than (1) those with which the clients has contracted to interface with the Flueid
            application, (2) those which Flueid has contracted with in provision of those services, (3) law enforcement
            or other governmental authority in connection with an investigation, or civil or criminal subpoenas, court
            orders, discovery, or legal process, or (4) as directed by the client. The privacy notices of those clients
            shall apply and you should refer to those privacy notices and/or contact your particular service provider to
            understand how your Personal Information will be used by such company.
          </p>

          <h3 className="txt-semiBold">Types of Information Collected</h3>
          <p>
            We, and any third party we engage for the purpose of administering, operating, hosting, configuring,
            designing, maintaining and providing internal support for our Website, as well as third parties we may
            engage for improving our products and advertising for business to business purposes only, may collect two
            types of information from you: Personal Information and Browsing Information.
          </p>

          <h3 className="txt-semiBold">Personal Information</h3>

          <p>Flueid may collect the following categories of Personal information:</p>
          <ul>
            <li>contact information (e.g., name, address, phone number, email address);</li>
            <li>demographic information (e.g., date of birth, gender, marital status);</li>
            <li>
              identity information (e.g. Social Security Number, driver’s license, passport, or other government ID
              number);
            </li>
            <li>financial account information (e.g. loan or bank account information); and</li>
            <li>other personal information necessary to provide products or services to you.</li>
          </ul>

          <p>We may collect Personal Information about you from:</p>
          <ul>
            <li>
              applications or other forms we receive from you or your authorized representative, which may include
              information about your assets and identifying information, such as name, address and SSN;
            </li>
            <li>the correspondence you and others send to us;</li>
            <li>information we receive from our partners or service providers;</li>
            <li>information about your transactions with Flueid, our affiliates, or others;</li>
            <li>
              information we receive from consumer reporting agencies and/or governmental entities, either directly from
              these entities or through others; and
            </li>
            <li>information we receive from other sources, as permitted by applicable laws, rules, and regulations.</li>
          </ul>

          <h3 className="txt-semiBold">Browsing Information</h3>

          <p>
            Flueid automatically collects the following types of Browsing Information when you access a Flueid website,
            online service, or application (each an “Flueid Website”) from your Internet browser, computer, and/or
            device:
          </p>
          <ul>
            <li>Internet Protocol (IP) address and operating system;</li>
            <li>browser version, language, and type;</li>
            <li>domain name system requests; and</li>
            <li>
              browsing history on the Flueid Website, such as date and time of your visit to the Flueid Website and
              visits to the pages within the Flueid Website.
            </li>
          </ul>

          <p>
            Like most websites, our servers automatically log each visitor to the Flueid Website and may collect the
            Browsing Information described above. We use Browsing Information for system administration,
            troubleshooting, fraud investigation, and to improve our websites. Browsing Information generally does not
            reveal anything personal about you, though if you have created a user account for a Flueid Website and are
            logged into that account, the Flueid Website may be able to link certain browsing activity to your user
            account.
          </p>

          <h3 className="txt-semiBold">Other Online Specifics</h3>

          <p>
            Cookies. When you visit an Flueid Website, a “cookie” may be sent to your computer. A cookie is a small
            piece of data that is sent to your Internet browser from a web server and stored on your computer’s hard
            drive. Information gathered using cookies helps us improve your user experience. For example, a cookie can
            help the website load properly or can customize the display page based on your browser type and user
            preferences. You can choose whether or not to accept cookies by changing your Internet browser settings. Be
            aware that doing so may impair or limit some functionality of the Flueid Website.
          </p>
          <p>
            Web Beacons. We use web beacons to determine when and how many times a page has been viewed. This
            information is used to improve our websites.
          </p>
          <p>
            Do Not Track. Currently our Flueid Websites do not respond to “Do Not Track” features enabled through your
            browser.
          </p>
          <p>
            Links to Other Sites. Flueid Websites may contain links to unaffiliated third-party websites. Flueid is not
            responsible for the privacy practices or content of those websites. We recommend that you read the privacy
            policy of every website you visit.
          </p>

          <h3 className="txt-semiBold">Use of Personal Information</h3>

          <p>Flueid uses Personal Information for three main purposes:</p>
          <ul>
            <li>To provide products and services to you or in connection with a transaction involving you.</li>
            <li>To improve our products and services.</li>
            <li>
              To communicate with you about our, our affiliates’, and others’ products and services, jointly or
              independently.
            </li>
          </ul>

          <h3 className="txt-semiBold">When Information Is Disclosed</h3>

          <p>We may disclose your Personal Information and Browsing Information in the following circumstances:</p>
          <ul>
            <li>
              to enable us to detect or prevent criminal activity, fraud, material misrepresentation, or nondisclosure;
            </li>
            <li>
              to nonaffiliated service providers who provide or perform services or functions on our behalf and who
              agree to use the information only to provide such services or functions;
            </li>
            <li>
              to nonaffiliated third-party service providers with whom we perform joint marketing, pursuant to an
              agreement with them to jointly market financial products or services to you;
            </li>
            <li>
              to law enforcement or authorities in connection with an investigation, or in response to a subpoena or
              court order; or
            </li>
            <li>
              in the good-faith belief that such disclosure is necessary to comply with legal process or applicable
              laws, or to protect the rights, property, or safety of Flueid, its customers, or the public.
            </li>
          </ul>

          <p>
            The law does not require your prior authorization and does not allow you to restrict the disclosures
            described above. Additionally, we may disclose your information to third parties for whom you have given us
            authorization or consent to make such disclosure. We do not otherwise share your Personal Information or
            Browsing Information with nonaffiliated third parties, except as required or permitted by law. We do share
            Personal Information among affiliates (other companies owned by Flueid) to directly market to you. Please
            see “Choices with Your Information” to learn how to restrict that sharing.
          </p>
          <p>
            We reserve the right to transfer your Personal Information, Browsing Information, and any other information,
            in connection with the sale or other disposition of all or part of the Flueid business and/or assets, or in
            the event of bankruptcy, reorganization, insolvency, receivership, or an assignment for the benefit of
            creditors. By submitting Personal Information and/or Browsing Information to Flueid, you expressly agree and
            consent to the use and/or transfer of the foregoing information in connection with any of the above
            described proceedings.
          </p>

          <h3 className="txt-semiBold">Security of Your Information</h3>

          <p>We maintain physical, electronic, and procedural safeguards to protect your Personal Information.</p>
          <p>
            When we provide Personal Information to our affiliates or third-party service providers as discussed in this
            Privacy Notice, we require that these parties agree to process such information in compliance with our
            Privacy Notice or in a manner that complies with applicable privacy laws. The use of your information by a
            trusted business partner may be subject to that party’s own Privacy Notice. Unless permitted by law, we do
            not disclose information we collect from consumer or credit reporting agencies with our affiliates or others
            without your consent.
          </p>
          <p>
            In addition to the other rights herein, we have the right to share and use, for any purpose, the collected
            Personal Information that is in an aggregated, anonymized form, such that you are not identified
            (collectively, “De-identified Data”).
          </p>
          <p>
            We reserve the right to transfer your Personal Information, Browsing Information, and any other information,
            in connection with the sale or other disposition of all or part of the Flueid business and/or assets, or in
            the event of our bankruptcy, reorganization, insolvency, receivership or an assignment for the benefit of
            creditors. You expressly agree and consent to the use and/or transfer of the foregoing information in
            connection with any of the above described proceedings. We cannot and will not be responsible for any breach
            of security by any third party or for any actions of any third party that receives any of the information
            that is disclosed to us. We may disclose your Personal Information for any purpose disclosed by us when you
            provide the information. We may also disclose your Personal Information with your permission or as directed
            by you.
          </p>

          <h3 className="txt-semiBold">Choices With Your Information</h3>

          <p>
            If you do not want Flueid to share your information among our affiliates to directly market to you, you may
            send an “opt out” request by email, phone, or physical mail as directed at the end of this Privacy Notice.
            We do not share your Personal Information with nonaffiliates for their use to direct market to you.
          </p>
          <p>
            Whether you submit Personal Information or Browsing Information to Flueid is entirely up to you. If you
            decide not to submit Personal Information or Browsing Information, Flueid may not be able to provide certain
            services or products to you.
          </p>
          <p>
            <strong>For California Residents:</strong> We will not share your Personal Information or Browsing
            Information with nonaffiliated third parties, except as permitted by California law. For additional
            information about your California privacy rights see the additional “California Residents” section below.
          </p>
          <p>
            <strong>For Nevada Residents:</strong> You may be placed on our internal Do Not Call List by calling
            (888)934-3354 or by contacting us via the information set forth at the end of this Privacy Notice. Nevada
            law requires that we also provide you with the following contact information: Bureau of Consumer Protection,
            Office of the Nevada Attorney General, 555 E. Washington St., Suite 3900, Las Vegas, NV 89101; Phone number:
            (702) 486-3132; email: BCPINFO@ag.state.nv.us.
          </p>
          <p>
            <strong>For Oregon Residents:</strong> We will not share your Personal Information or Browsing Information
            with nonaffiliated third parties for marketing purposes, except after you have been informed by us of such
            sharing and had an opportunity to indicate that you do not want a disclosure made for marketing purposes.
          </p>
          <p>
            <strong>For Vermont Residents:</strong> We will not disclose information about your creditworthiness to our
            affiliates and will not disclose your personal information, financial information, credit report, or health
            information to nonaffiliated third parties to market to you, other than as permitted by Vermont law, unless
            you authorize us to make those disclosures.
          </p>

          <h3 className="txt-semiBold">Information From Children</h3>
          <p>
            The Flueid Website is not intended or designed to attract persons under the age of eighteen (18). We do not
            collect Personal Information from any person that we know to be under the age of thirteen (13) without
            permission from a parent or guardian.
          </p>

          <h3 className="txt-semiBold">International Users</h3>
          <p>
            Flueid’s headquarters is located within the United States. If you reside outside the United States and
            choose to provide Personal Information or Browsing Information to us, please note that we may transfer that
            information outside of your country of residence. By providing Flueid with your Personal Information and/or
            Browsing Information, you consent to our collection, transfer, and use of such information in accordance
            with this Privacy Notice.
          </p>

          <h3 className="txt-semiBold">
            Your Consent To This Privacy Notice; Notice Changes; Use of Comments or Feedback
          </h3>
          <p>
            By submitting Personal Information and/or Browsing Information to Flueid, you consent to the collection and
            use of the information in accordance with this Privacy Notice. We may change this Privacy Notice at any
            time. The Privacy Notice’s effective date will show the last date changes were made. If you provide
            information to us following any change of the Privacy Notice, that signifies your assent to and acceptance
            of the changes to the Privacy Notice. We may use comments or feedback that you submit to us in any manner
            without notice or compensation to you.
          </p>

          <h3 className="txt-semiBold">Accessing and Correcting Information; Contact Us</h3>
          <p>
            If you have questions, would like to correct your Personal Information, or want to opt-out of information
            sharing for affiliate marketing, send your requests to{" "}
            <a href="mailto:privacy@flueid.com">privacy@flueid.com</a>, or by phone to{" "}
            <a href="tel:(888)835-8343">(888)835-8343</a>, or by mail to:
          </p>
          <p>
            <address>
              Flueid Software Corporation
              <br />
              11801 Domain Blvd
              <br />
              3rd Floor
              <br />
              Austin, TX 78758
              <br />
              Attn: General Counsel
            </address>
          </p>

          <h3 className="txt-semiBold">
            <u>California Residents</u>
          </h3>

          <p>
            If you are a California resident, you may have certain rights under California law, including but not
            limited to the California Consumer Privacy Act of 2018 (“CCPA”). All phrases used in this section shall have
            the same meaning as those phrases are used under California law, including the CCPA. Please also note that
            nothing in this disclosure should be construed to concede that Flueid or any of its affiliates is a
            “business” as that term is defined under the California Consumer Protection Act (the “CCPA”).
          </p>
          <p>
            <strong>Right to Know.</strong> You have a right to request that we disclose the following information to
            you: (1) the categories of personal information we have collected about or from you; (2) the categories of
            sources from which the personal information was collected; (3) the business or commercial purpose for such
            collection and/or disclosure of your personal information; (4) the categories of third parties with whom we
            have shared your personal information; and (5) the specific pieces of your personal information we have
            collected. To submit a verified request for this information, you may submit your request to{" "}
            <a href="mailto:privacy@flueid.com">privacy@flueid.com</a> or call{" "}
            <a href="tel:(888)835-8343">(888)835-8343</a>. You may also designate an authorized agent to submit a
            request on your behalf, which requires submission of written proof of such authorization to{" "}
            <a href="mailto:privacy@flueid.com">privacy@flueid.com</a>.
          </p>
          <p>
            <strong>Right of Deletion.</strong> You also have a right to request that we delete the personal information
            we have collected from you. This right is subject to certain exceptions available under the CCPA and other
            applicable law. To submit a verified request for deletion you may submit your request to{" "}
            <a href="mailto:privacy@flueid.com">privacy@flueid.com</a> or call{" "}
            <a href="tel:(888)835-8343">(888)835-8343</a>. You may also designate an authorized agent to submit a
            request on your behalf, which requires submission of written proof of such authorization to{" "}
            <a href="mailto:privacy@flueid.com">privacy@flueid.com</a>.
          </p>
          <p>
            <strong>Verification Process.</strong> For either a request to know or delete, we will verify your identity
            before responding to your request. To verify your identity, we will generally match the identifying
            information provided in your request with the information we have on file about you. Depending on the
            sensitivity of the personal information requested, we may also utilize more stringent verification methods
            to verify your identity, including but not limited to requesting additional information from you and/or
            requiring you to sign a declaration under penalty of perjury.
          </p>
          <p>
            <strong>Right to Opt-Out.</strong> We do not sell your personal information to third parties, and do not
            plan to do so in the future.
          </p>
          <p>
            <strong>Right of Non-Discrimination.</strong> You have a right to exercise your rights under California law,
            including under the CCPA, without suffering discrimination. Accordingly, Flueid will not discriminate
            against you in any away if you choose to exercise your rights under the CCPA.
          </p>
          <p>
            <strong>Collection Notice.</strong> The following is a list of the categories of personal information we may
            have collected about California residents in the 12 months preceding the date this Privacy Notice was last
            updated, including the business or commercial purpose for said collection, the categories of sources from
            which we may have collected the personal information, and the categories of third parties with whom we may
            have shared the personal information.
          </p>
          <p>
            <strong>Categories of Personal Information Collected.</strong> The categories of personal information we may
            have collected include, but may not be limited to: real name; signature; alias; SSN; physical
            characteristics or description, including protected characteristics under federal or state law; address;
            telephone number; passport number; driver’s license number; state identification card number; IP address;
            policy number; file number; employment history; bank account number; credit card number; debit card number;
            financial account numbers; commercial information; internet or other electronic network activity;
            geolocation data; audio and visual information; professional or employment information; and inferences drawn
            from the above categories to create a profile about a consumer.
          </p>
          <p>
            Please note that because the above list is comprehensive it may refer to types of information that we share
            about people other than yourself. If you would like more information concerning the categories of personal
            information (if any) we share with third parties or affiliates for those parties to use for direct
            marketing, please submit a written request to us using the information in the “Contact” section above.
          </p>
          <p>
            <strong>Categories of Sources.</strong> Categories of sources from which we may have collected personal
            information include, but may not be limited to: the consumer directly; public records; governmental
            entities; non-affiliated third parties; social media networks; affiliated third parties.
          </p>
          <p>
            <strong>Business Purpose for Collection.</strong> The business purposes for which we may have collected
            personal information include, but may not be limited to: completing a transaction for our Products;
            verifying eligibility for employment; facilitating employment; performing services on behalf of affiliated
            and non-affiliated third parties; debugging to identify and repair errors that impair existing intended
            functionality on our Websites, applications, or products; protecting against malicious, deceptive,
            fraudulent, or illegal activity.
          </p>
          <p>
            <strong>Categories of Third Parties Shared.</strong> The categories of third parties with whom we may have
            shared personal information include, but may not be limited to: advertising networks; internet service
            providers; data analytics providers; service providers; government entities; operating systems and
            platforms; social media networks; non-affiliated third parties; affiliated third parties.
          </p>
          <p>
            <strong>Categories of Personal Information We Have Sold In The Past Year.</strong> We have not sold any
            personal information of California residents to any third party in the 12 months preceding the date this
            Privacy Notice was last updated.
          </p>
          <p>
            <strong>Categories of Personal Information Disclosed For A Business Purpose In The Past Year.</strong> The
            following is a list of the categories of personal information of California residents we may have disclosed
            for a business purpose in the 12 months preceding the date this Privacy Notice was last updated: The
            categories of personal information we have collected include, but may not be limited to: real name;
            signature; alias; SSN; physical characteristics or description, including protected characteristics under
            federal or state law; address; telephone number; passport number; driver’s license number; state
            identification card number; IP address; policy number; file number; employment history; bank account number;
            credit card number; debit card number; financial account numbers; commercial information; internet or other
            electronic network activity; geolocation data; audio and visual information; professional or employment
            information; and inferences drawn from the above categories to create a profile about a consumer.
          </p>
        </>
      </SectionContent>
      <Footer />
    </div>
  </Transition>
);
