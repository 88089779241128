import Select, { Props, Styles } from "react-select";
import { InputErrors } from "./input";

export interface SelectOption {
  label: string;
  value: any;
}

// need to return null instead of undefined because react-select's internal StateManager won't
// clear its value if passed undefined
export function mapSelectOption(value: string): SelectOption | null {
  return value ? { value, label: value } : null;
}

export function mapSelectOptionMulti(values: string[]): SelectOption[] {
  return values.map((x) => {
    return { value: x, label: x };
  });
}

const customStyles: Partial<Styles<SelectOption, boolean>> = {
  control: (provided, state) => ({
    ...provided,
    minHeight: "41px",
    border: state.menuIsOpen ? "1px solid #152935" : "1px solid #e1e1e1",
    boxShadow: "none",
    borderRadius: "2px",
    outline: "none !important",
    transition: "0.3s ease border",
    "&:hover": {
      boxShadow: "0 0 3px 0 rgb(0 0 0 / 12%)",
    },
  }),
  placeholder: (provided) => ({
    ...provided,
    color: "#d0d0d0",
  }),
};

interface IProps<T extends string> extends Props<SelectOption, boolean> {
  label?: string;
  name?: T;
  required?: boolean;
  errors?: string[];
}

export function ReactSelect<T extends string>({ label, errors, required, ...props }: IProps<T>) {
  return (
    <div className={`input-group v2 ${errors?.length ? "error" : ""}`}>
      <div className="label dropdown-label">
        {label} {required && "*"}
      </div>
      <Select styles={customStyles} {...props} />
      <InputErrors errors={errors} />
    </div>
  );
}
