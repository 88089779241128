export type IconArrowColor = "black" | "white" | "blue";

type Props = {
  color?: IconArrowColor;
  colorInline?: string;
  svgProps?: React.SVGProps<SVGSVGElement>;
};

const colorMap: Record<IconArrowColor, string> = {
  black: "#000",
  white: "#FFFFFF",
  blue: "#0070c0",
};

export const IconArrowRight = ({ color = "black", colorInline, svgProps }: Props) => {
  return (
    <svg width="19px" height="15px" viewBox="0 0 19 15" version="1.1" xmlns="http://www.w3.org/2000/svg" {...svgProps}>
      <title>arrow-right</title>
      <g id="Home" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <g
          id="Flueid-Desktop-Home-2023-v2"
          transform="translate(-345.000000, -1656.000000)"
          fill={colorInline || colorMap[color]}
          fillRule="nonzero"
        >
          <g id="Group-67" transform="translate(128.000000, 1538.000000)">
            <g id="Group-35" transform="translate(0.000000, 114.000000)">
              <g
                id="Group-43"
                transform="translate(226.263440, 11.500000) rotate(-90.000000) translate(-226.263440, -11.500000) translate(219.007751, 2.236560)"
              >
                <path
                  d="M8.25568908,2.09191233e-13 L8.255,14.318 L13,8.84506946 L14.5113782,10.1549305 L8.01137817,17.6549305 L7.25568908,18.5268795 L6.5,17.6549305 L0,10.1549305 L1.51137817,8.84506946 L6.255,14.32 L6.25568908,2.09191233e-13 L8.25568908,2.09191233e-13 Z"
                  id="arrow-right"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
