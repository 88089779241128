import React from "react";
import Validate from "../../types/validation";
import { SectionContent } from "../common/sectionContent";
import { Button } from "../controls/button";
import Input from "../controls/input";
import * as QueryString from "query-string";
import { ReqStatus } from "../../types/reqStatus";
import { jsonp, JsonpProps, MailchimpResponse } from "../../utilities/jsonp";
import DashedLineGroup, { DashedLine } from "../controls/dashedLines";
import Modal from "components/controls/modal";

const OkMessage = () => (
  <>
    <h3>Thank you for subscribing!</h3>
    <p>We look forward to sharing updates with you.</p>
  </>
);

const FailMessage = () => (
  <>
    <h3>Oops! Something went wrong.</h3>
    <p>
      Please try subscribing again or send an email to <a href="mailto:marketing@flueid.com">marketing@flueid.com</a>
    </p>
  </>
);

interface State {
  reqStatus: ReqStatus;
  email: string;
  errors: string[];
  modalOpen: boolean;
}

class NewsletterSignUp extends React.Component<{}, State> {
  state: State = {
    reqStatus: new ReqStatus(),
    email: "",
    errors: [],
    modalOpen: false,
  };

  handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    const { email, reqStatus } = this.state;

    e.preventDefault();

    // return out if pending request
    if (reqStatus.pending) return;

    if (Validate.Email(email)) {
      // set reqStatus pending
      this.setState({ reqStatus: ReqStatus.Pending() });

      // call mailchimp
      //  - "EMAIL" field must be capitalized!
      const params = QueryString.stringify({ EMAIL: email });
      const url = process.env.REACT_APP_MAILCHIMP_URL + "&" + params;
      const jsonpProps: JsonpProps = {
        url,
        opts: {
          // mailchimp needs this "c" param to place a callback name with
          param: "c",
        },
        fn: this.handleResponse,
      };

      jsonp(jsonpProps);
    } else {
      this.setState({ errors: ["Please enter valid email"] });
    }
  };

  handleResponse = (err: null | Error, data?: MailchimpResponse) => {
    if (err) {
      console.log(err);
      this.setState({
        modalOpen: true,
        reqStatus: ReqStatus.Failed(),
      });
    } else if (data?.result !== "success") {
      console.log(data);
      this.setState({
        reqStatus: ReqStatus.Failed(),
        errors: data?.msg.includes(`${this.state.email} is already subscribed`)
          ? ["This email is already subscribed"]
          : [],
      });
    } else {
      this.setState({
        modalOpen: true,
        reqStatus: ReqStatus.Ok(),
        email: "",
        errors: [],
      });
    }
  };

  modalOpen = () => this.setState({ modalOpen: true });
  modalClose = () => this.setState({ modalOpen: false });

  render() {
    const { email, errors, reqStatus, modalOpen } = this.state;

    return (
      <section className="newsletter-section">
        <SectionContent maxWidthType="section-maxWidth-xxsm">
          <DashedLineGroup className="dashedLineGroup-1">
            {(visible) => (
              <>
                <DashedLine width={50} duration={2.5} delay={0} x={"5vw"} show={visible} />
                <DashedLine width={35} duration={1.5} delay={0.2} x={"15vw"} show={visible} />
                <DashedLine width={200} duration={1} delay={0.5} x={"18vw"} show={visible} />
                <DashedLine width={75} duration={0.5} delay={0.7} x={"20vw"} show={visible} />
              </>
            )}
          </DashedLineGroup>
          <h3>Stay Connected</h3>
          <h6 className="txt-light">Sign up to receive the latest news and updates from Flueid.</h6>
          <form onSubmit={this.handleSubmit} className="input-container">
            <div className="flex flex-align-end">
              <Input
                name="email"
                value={email}
                label="Email Address"
                type="text"
                onChange={(name: string, value: string) => this.setState({ email: value, errors: [] })}
                valid={errors.length ? true : false}
                errorMessages={errors}
              />
              <Button type="submit">Subscribe</Button>
            </div>
            <div className="p-4 txt-light">
              <span className="txt-semiBold">We care about your privacy.</span> Your data is secure and we will never
              share your information with outside parties.
            </div>
          </form>
        </SectionContent>
        <Modal open={modalOpen} onOpen={this.modalOpen} onClose={this.modalClose}>
          {reqStatus.ok ? <OkMessage /> : <FailMessage />}
          <Button onClick={this.modalClose}>Got it</Button>
        </Modal>
      </section>
    );
  }
}

export default NewsletterSignUp;
