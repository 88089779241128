import Transition from "components/controls/transition";
import Visible from "components/controls/visible";

export interface EquityGraph {
  img: string;
  imgMobile: string;
  quote1?: React.ReactNode;
  quote2?: React.ReactNode;
  quote3?: React.ReactNode;
  quote4?: React.ReactNode;
  quote5?: React.ReactNode;
}

export const graph: EquityGraph[] = [
  {
    img: "loss-mit-desktop.png",
    imgMobile: "graphic-1-mobile-min.png",
    quote1: (
      <>
        <Visible>
          {(state) => (
            <Transition animation={"fadeInUp"} duration={1000} delay={500} visible={state.visible}>
              <div className="graph-para-text" ref={state.ref}>
                <span className="bottom-text-bold">1. Receive</span> a 30-day delinquency notification
              </div>
            </Transition>
          )}
        </Visible>
      </>
    ),
  },
  {
    img: "loss-mit-desktop.png",
    imgMobile: "graphic-2-mobile-min.png",
    quote2: (
      <>
        <Visible>
          {(state) => (
            <Transition animation={"fadeInUp"} duration={1000} delay={500} visible={state.visible}>
              <div className="graph-para-text" ref={state.ref}>
                <span className="bottom-text-bold">2. Order</span> Flueid’s Loss Mitigation Analysis Report to
                understand the potential loss mitigation pathways and establish clear lines of communication with your
                borrower
              </div>
            </Transition>
          )}
        </Visible>
      </>
    ),
  },
  {
    img: "loss-mit-desktop.png",
    imgMobile: "graphic-3-mobile-min.png",
    quote3: (
      <>
        <Visible>
          {(state) => (
            <Transition animation={"fadeInUp"} duration={1000} delay={500} visible={state.visible}>
              <div className="graph-para-text" ref={state.ref}>
                <span className="bottom-text-bold">3. Consult</span> with your borrower on the most favorable strategy,
                including those tapping into their home’s potential equity
              </div>
            </Transition>
          )}
        </Visible>
      </>
    ),
  },
  {
    img: "loss-mit-desktop.png",
    imgMobile: "graphic-4-mobile-min.png",
    quote4: (
      <>
        <Visible>
          {(state) => (
            <Transition animation={"fadeInUp"} duration={1000} delay={500} visible={state.visible}>
              <div className="graph-para-text" ref={state.ref}>
                <span className="bottom-text-bold">4. Key Feature!</span> Identify new support solutions for your
                borrower with a summarized Borrower Equity Insights section of the report
              </div>
            </Transition>
          )}
        </Visible>
      </>
    ),
  },
  {
    img: "loss-mit-desktop.png",
    imgMobile: "graphic-4-mobile-min.png",
    quote5: (
      <>
        <Visible>
          {(state) => (
            <Transition animation={"fadeInUp"} duration={1000} delay={500} visible={state.visible}>
              <div className="graph-para-text" ref={state.ref}>
                <span className="bottom-text-bold">5. Select</span> path and
                <span className="bottom-text-bold"> order</span> the corresponding Title Decision Report for an
                integrated and frictionless process end-to-end
              </div>
            </Transition>
          )}
        </Visible>
      </>
    ),
  },
  {
    img: "loss-mit-desktop.png",
    imgMobile: "graphic-4-mobile-min.png",
  },
];
