import React from "react";
import { useHistory } from "react-router-dom";

export interface IHistoryContext {
  initialHistoryEventCalled: boolean;
  setInitialHistoryEventCalled: () => void;
}

export const HistoryContext = React.createContext<IHistoryContext>({
  initialHistoryEventCalled: false,
  setInitialHistoryEventCalled: () => {},
});

export const HistoryListener: React.FC<IHistoryContext> = (props) => {
  const history = useHistory();
  history.listen((x) => {
    if (!props.initialHistoryEventCalled) props.setInitialHistoryEventCalled();
  });
  return null;
};
