import { ReactComponent as IconArrow } from "../../images/icons/glyph-arrow.right.svg";
import Transition from "components/controls/transition";
import { NavLink } from "react-router-dom";
import { Editorial } from "types/editorial";
import { Entry } from "contentful";

interface Props {
  story: Entry<Editorial>;
}

export const PressReleaseRow = ({ story }: Props) => {
  // created date
  const date = new Date(story.fields.publishDate);
  const formattedDate = `${date.toLocaleString("default", { month: "long" })} ${date.getDate()}, ${date.getFullYear()}`;
  return (
    <NavLink to={`/editorial/${story.fields.slug}`}>
      <Transition visible animation="fadeIn">
        <div className={`pressReleaseRow`}>
          <div className="pressRelease-grid">
            <div className="p-4">{formattedDate}</div>
            <div className="title">
              <div className="p-1 txt-semiBold title-maxWidth">{story?.fields.title}</div>
            </div>
            <div className="flex flex-justify-end col-3">
              <div className="p-2 txt-underline">Read More</div>
              <IconArrow className="icon-svg_arrow" />
            </div>
          </div>
        </div>
      </Transition>
    </NavLink>
  );
};
