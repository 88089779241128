import { useHistory } from "react-router-dom";
import { HistoryContext } from "utilities/historyListener";
import { ReactComponent as IconBackArrow } from "../../images/icons/icon-arrow.back.svg";

interface Props {
  title: string;
  leftItems?: string | React.ReactNode;
  rightItems?: string | React.ReactNode;
  to?: string;
}

export const SubNavbar: React.FC<Props> = ({ title, leftItems, rightItems, to }) => {
  const history = useHistory();
  return (
    <HistoryContext.Consumer>
      {({ initialHistoryEventCalled }) => (
        <div className="subNavbar">
          <div className="subNavbar-content flex flex-center">
            <div className="flex flex-1">
              {leftItems || (
                <IconBackArrow
                  onClick={initialHistoryEventCalled ? history.goBack : () => history.push(to || "/")}
                  className="icon-arrow_back"
                />
              )}
            </div>
            <div className="flex-1 header-minWidth subheader-2 txt-bold txt-center">{title}</div>
            <div className="flex flex-justify-end flex-1">{rightItems || null}</div>
          </div>
        </div>
      )}
    </HistoryContext.Consumer>
  );
};
