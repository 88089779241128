import { NavLink } from "react-router-dom";
import { Editorial } from "types/editorial";
import { Entry } from "contentful";
import { ReactComponent as IconArrow } from "../../images/icons/glyph-arrow.right.svg";
import React from "react";
import Transition from "../controls/transition";

interface Props {
  editorial: Entry<Editorial>;
}

type CardComponent = React.FC<Props> & {
  Link: React.FC;
  Subtitle: React.FC;
  Subtitle2: React.FC;
  Title: React.FC;
};

const EditorialCard: CardComponent = ({ editorial, children }) => {
  const card = (
    <Transition visible animation={"fadeIn"} duration={2000}>
      <div className="editorial-card">
        <img
          className="editorial-card_img"
          src={editorial.fields.previewImg?.fields?.file?.url}
          alt={editorial.fields.previewImg?.fields?.description}
        />
        <div className="editorial-card_content flex flex-col">{children}</div>
      </div>
    </Transition>
  );

  // determine what type of link this is internal vs external
  return editorial.fields.externalLink ? (
    <a target="_blank" href={editorial.fields.externalLink}>
      {card}
    </a>
  ) : (
    <NavLink to={`/editorial/${editorial.fields.slug}`}>{card}</NavLink>
  );
};

export const EditorialLink: React.FC = ({ children }) => (
  <div className="flex flex-align-end flex-1">
    <div className="p-4 txt-bold margin-right_xsm">{children || "Continue Reading"}</div>
    <IconArrow className="icon-svg_arrow arrow-icon" />
  </div>
);

export const EditorialCardSubtitle: React.FC = ({ children }) => (
  <div className="editorial-card_subtitle">{children}</div>
);

export const EditorialCardSubtitle2: React.FC = ({ children }) => (
  <div className="editorial-card_subtitle2 p-4">{children}</div>
);

export const EditorialCardTitle: React.FC = ({ children }) => <h6 className="editorial-card_title">{children}</h6>;

EditorialCard.Link = EditorialLink;
EditorialCard.Subtitle = EditorialCardSubtitle;
EditorialCard.Subtitle2 = EditorialCardSubtitle2;
EditorialCard.Title = EditorialCardTitle;

export default EditorialCard;
