interface Props {
  className?: string;
}

export const MissionBlock = ({ className }: Props) => (
  <div className={`mission-block ${className || ""}`}>
    <h3 className="txt-semiBold">
      Utilizing data, automation and industry expertise, our mission is simple: deliver a definitive transaction
      experience to our clients and consumers.
    </h3>
  </div>
);
