import Badge from "components/common/badge";
import { Button } from "components/controls/button";
import { Entry } from "contentful";
import { Link, useRouteMatch } from "react-router-dom";
import { IJobPosting } from "types/jobPosting";
import styles from "./styles.module.scss";
import { classNames } from "utilities/classNames";

const JobPostingCard = (job: Entry<IJobPosting>) => {
  const { path } = useRouteMatch();
  const { title, slug, department, location, summaryTags, descriptionShort } = job.fields;

  return (
    <article className={styles.card}>
      <h5 className={classNames(styles.title, "txt-blue", "txt-semiBold")}>{title}</h5>
      <p className={classNames(styles.details, "p")}>
        <i>
          {department}, {location}
        </i>
      </p>
      <div className={styles.tags}>
        {summaryTags?.map((tag) => (
          <Badge key={tag}>{tag}</Badge>
        ))}
      </div>
      <p className={classNames(styles.description, "p")}>{descriptionShort}</p>
      <Link to={path + `/${slug}|${job.sys.id}`} className={styles.link}>
        <Button className="btn-blue">Learn More</Button>
      </Link>
    </article>
  );
};

export default JobPostingCard;
