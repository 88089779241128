import React from "react";

interface ButtonProps
  extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {}

export const Button: React.FC<ButtonProps> = ({ onClick, className, children, ...rest }) => (
  <button className={`btn ${className || "btn-black"}`} onClick={onClick} {...rest}>
    {children}
  </button>
);
