import { SectionContent } from "components/common/sectionContent";
import Footer from "components/footer";
import { SubNavbar } from "components/navbar/subNavbar";
import { Entry } from "contentful";
import { useEffect, useState } from "react";
import { Route, Switch, useRouteMatch } from "react-router";
import { getJobPostings, IJobPosting } from "types/jobPosting";
import JobDetails from "./jobDetails";
import JobPostings from "./jobPostings";

const JobsPage = () => {
  const { path } = useRouteMatch();
  const [jobs, setJobs] = useState<Entry<IJobPosting>[]>([]);
  const [loading, setLoading] = useState(true);

  // fetch all jobs
  useEffect(() => {
    getJobPostings()
      .then((entries) => setJobs(entries.items))
      .catch((err) => console.error(err))
      .finally(() => setLoading(false));
  }, []);

  return (
    <>
      <SubNavbar title="Open Jobs" />
      <main>
        <SectionContent maxWidthType="section-maxWidth-xsm" className="margin-top_lg margin-bottom_lg">
          <Switch>
            <Route path={path + "/:slug|:jobId"} component={() => <JobDetails jobs={jobs} loading={loading} />} />
            <Route path={path} component={() => <JobPostings jobs={jobs} loading={loading} />} />
          </Switch>
        </SectionContent>
      </main>
      <Footer />
    </>
  );
};

export default JobsPage;
