import React, { ReactElement } from "react";

export type TransitionType =
  | "fadeIn"
  | "fadeInLeft"
  | "fadeInRight"
  | "fadeInUp"
  | "fadeOut"
  | "fadeOutHalf"
  | "fadeInUpLong";

export interface TransitionProps {
  visible?: boolean;
  animation?: TransitionType;
  duration?: number; // in milliseconds
  delay?: number; // in milliseconds
  children?: ReactElement;
  fireOnMount?: boolean;
}

interface State {
  show: boolean;
}

class Transition extends React.Component<TransitionProps, State> {
  state: State = {
    show: this.props.visible !== undefined ? this.props.visible : true,
  };

  componentDidUpdate(prevProps: TransitionProps) {
    const { visible, delay } = this.props;

    if (prevProps.visible === false && visible) {
      delay ? setTimeout(() => this.setState({ show: true }), delay) : this.setState({ show: true });
    }
  }

  render() {
    const { children, duration, fireOnMount, animation } = this.props;
    const { show } = this.state;

    if (!children) return <></>;

    const style: React.CSSProperties = {
      animationDuration: duration ? `${duration}ms` : "1s",
      ...children.props.style,
    };
    const initial = animation?.includes("fadeIn") ? "hidden" : "visible";
    const className: string = `${children.props.className} ${show || fireOnMount ? animation : initial}`;
    const props = { style, className };

    return React.cloneElement(children, props);
  }
}

export default Transition;
