export enum AppEnv {
  LOCAL = "local",
  STAGING = "staging",
  PROD = "production",
}

export function getAppEnv(): AppEnv {
  const hostName = window?.location?.hostname;

  if (hostName.includes("staging.flueid.com")) {
    return AppEnv.STAGING;
  } else if (hostName.includes("flueid.com")) {
    return AppEnv.PROD;
  } else {
    return AppEnv.LOCAL;
  }
}
