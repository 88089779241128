import TestimonySection from "components/about/testimonySection";
import { OverflowContainer } from "components/controls/overflow";
import { NavLink } from "react-router-dom";
import { HeroSection } from "../common/heroSection";
import { SectionContent } from "../common/sectionContent";
import { Button } from "../controls/button";
import Footer from "../footer";
import Navbar from "../navbar/navbar";
import { BenefitsSection } from "./benefits";
import { DiscoverSection } from "./discoverSection";
import { LifeAtFlueidSection } from "./lifeAtFlueidSection";
import { OfficesSection } from "./officesSection";
import { ShowcasePositionSection } from "./showcasePositionSection";
import DashedLineGroup, { DashedLine } from "../controls/dashedLines";
import TestimonySectionColumns from "./testimonySectionColumns";
import Transition from "../controls/transition";
import Visible from "components/controls/visible";

/** NEW VERSION - NOT APPROVED FOR FINAL PROD RELEASE AWAITING */
const CareersPageContainer = () => {
  return (
    <div className="careersPage-container">
      <Navbar color="white" />
      <OverflowContainer>
        <HeroSection className="careersPage-heroSection">
          <div className="at-the-core-text"></div>
          <Transition visible animation={"fadeInUp"} duration={1000} delay={1000}>
            <div className="subheader-2 txt-semiBold z-index-4">Careers</div>
          </Transition>
          <Transition visible animation={"fadeInUp"} duration={1000} delay={1000}>
            <h1 className="text-red z-index-4">Do what you love</h1>
          </Transition>
          <Transition visible animation={"fadeInUp"} duration={1000} delay={1000}>
            <h5 className="txt-light z-index-4">
              Why does real estate have to be so complicated? Join our team of architects, inventors, planners and
              problem-solvers to help make real estate transactions easier – for our clients and ultimately, for you,
              too.
            </h5>
          </Transition>
          <Transition visible animation={"fadeInUp"} duration={10} delay={800}>
            <NavLink to="/job-postings">
              <Button className="btn-black z-index-4">View Open Positions</Button>
            </NavLink>
          </Transition>
        </HeroSection>
        <div className="img-careers img-careers_mobile" />
        <DiscoverSection />
        <TestimonySectionColumns />
        <LifeAtFlueidSection />
        {/* <ShowcasePositionSection /> */}
        <OfficesSection />
        <section className="contactLink-section">
          <SectionContent maxWidthType="section-maxWidth-small">
            <h3 className="txt-regular">Didn’t find what you are looking for?</h3>
            <p>We’d love to hear from you anyway.</p>
            <NavLink to="/contact">
              <Button>Contact Us</Button>
            </NavLink>
          </SectionContent>
        </section>
        <Footer />
      </OverflowContainer>
    </div>
  );
};

export default CareersPageContainer;
