import { uniqueID } from "./formatters";

function noop() {}

export interface MailchimpResponse {
  result: "success" | "error" | string;
  msg: string;
}

export interface JsonpProps {
  url: string;
  opts?: {
    prefix?: string;
    name?: string;
    param?: string;
    timeout?: number;
  };
  fn?: (err: null | Error, data?: MailchimpResponse) => void;
}

interface W extends Window {
  [key: string]: any;
}

export function jsonp({ url, opts, fn }: JsonpProps): Function {
  if ("function" == typeof opts) {
    fn = opts;
    opts = {};
  }
  if (!opts) opts = {};

  const prefix = opts.prefix || "__jp";

  // use the callback name that was passed if one was provided.
  // otherwise generate a unique name by incrementing our counter.
  const id = opts.name || prefix + uniqueID();
  // const id = opts.name || (prefix + (count++));

  const param = opts.param || "callback";
  const timeout = opts.timeout !== null && opts.timeout !== undefined ? opts.timeout : 60000;
  const enc = encodeURIComponent;
  const target = document.getElementsByTagName("script")[0] || document.head;
  let script: HTMLScriptElement | undefined;
  let timer: NodeJS.Timeout | undefined;
  const w: W = window;

  if (timeout) {
    timer = setTimeout(function () {
      cleanup();
      if (fn) fn(new Error("Timeout"));
    }, timeout);
  }

  function cleanup() {
    if (script?.parentNode) script.parentNode.removeChild(script);
    w[id] = noop;
    if (timer) clearTimeout(timer);
  }

  function cancel() {
    if (w[id]) {
      cleanup();
    }
  }

  w[id] = function (data: MailchimpResponse) {
    cleanup();
    if (fn) fn(null, data);
  };

  // add qs component
  url += (~url.indexOf("?") ? "&" : "?") + param + "=" + enc(id);
  url = url.replace("?&", "?");

  // create script
  script = document.createElement("script");
  script.src = url;
  target?.parentNode?.insertBefore(script, target);

  return cancel;
}
