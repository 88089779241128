import React from "react";
import { SectionContent } from "components/common/sectionContent";
import Transition from "components/controls/transition";
import Visible from "components/controls/visible";
import { NavLink } from "react-router-dom";
import { Button } from "components/controls/button";

type Direction = "left" | "right";

interface TimelineItemProps {
  direction: Direction;
  className?: string;
}

const TimelinePeriod: React.FC<React.HTMLAttributes<HTMLElement>> = (props) => (
  <div className={`timeline-period timeline-period-mobileMargin ${props.className || ""} `}>{props.children}</div>
);

const TimelineYear: React.FC<TimelineItemProps> = ({ children, direction }) => (
  <div className={`timeline-yearContainer timeline-yearContainer-${direction}`}>
    <h2 className={`timeline-year timeline-year-${direction}`}>{children}</h2>
  </div>
);

const TimelineTiles: React.FC = (props) => <div className="timeline-tiles">{props.children}</div>;

const TimelineTile: React.FC<TimelineItemProps> = ({ children, direction, className }) => (
  <Visible>
    {(state) => (
      <Transition visible={state.visible} animation="fadeIn" duration={1000} delay={500}>
        <div
          ref={state.ref}
          className={`timeline-tile timeline-tile-mobileMargin timeline-tile-${direction} ${className || ""}`}>
          <div className="timeline-paragraph txt-regular">{children}</div>
        </div>
      </Transition>
    )}
  </Visible>
);

export const TimelineSection = () => (
  <section className="timeline-section">
    <SectionContent maxWidthType="section-maxWidth-small">
      <h2>Our deep history and culture of innovation</h2>
      <p>
        Nearly 20 years ago, our founders recognized the value data and technology could unlock for the title
        underwriting process and championed the first automated title underwriting engine. Today, this same vision holds
        true and has fostered nearly two decades of innovation committed to digitally accelerating the real estate life
        cycle.
      </p>
      <div className="timeline-container">
        <TimelinePeriod className="timeline-period_margin1995">
          <TimelineYear direction="left">1995</TimelineYear>
          <TimelineTiles>
            <TimelineTile direction="right">
              In the mid-1990s, our founders created the title industry’s first online application for property research
              and review, significantly automating a disjointed and manual origination and fulfillment process.
            </TimelineTile>
          </TimelineTiles>
        </TimelinePeriod>

        <TimelinePeriod>
          <TimelineYear direction="right">2000</TimelineYear>
          <TimelineTiles>
            <TimelineTile className="timeline-tile_margin2000" direction="left">
              At Move, Inc. (formerly Homestore), our founders were responsible for the development and implementation
              of the company’s suite of software products and services to help real estate professionals better operate
              in a digital real estate transaction environment.
            </TimelineTile>
          </TimelineTiles>
        </TimelinePeriod>

        <TimelinePeriod>
          <TimelineYear direction="right">2003</TimelineYear>
          <TimelineTiles>
            <TimelineTile direction="right">
              In 2003, we introduced ClosingPoint, which redefined the industry standard for title insurance and escrow
              processing technology by creating, implementing and continuing the competitive evolution of the industry’s
              first integrated transaction management platform.
            </TimelineTile>
          </TimelineTiles>
        </TimelinePeriod>

        <TimelinePeriod className="timeline-period_margin2004">
          <TimelineYear direction="left">2004</TimelineYear>
          <TimelineTiles>
            <TimelineTile direction="left">
              We successfully championed the industry’s first premier automated title decision engine that utilized
              non-traditional data sources to render instant title underwriting decisions.
            </TimelineTile>
          </TimelineTiles>
        </TimelinePeriod>

        <TimelinePeriod>
          <TimelineYear direction="right">2007</TimelineYear>
          <TimelineTiles>
            <TimelineTile direction="right">
              By delivering the second-generation title underwriting engine, we advanced the original technology by
              incorporating additional data sources and enhancing rule sets for a more complete, customized experience.
            </TimelineTile>
          </TimelineTiles>
        </TimelinePeriod>

        <TimelinePeriod className="timeline-period_margin2009">
          <TimelineYear direction="left">2009</TimelineYear>
          <TimelineTiles>
            <TimelineTile direction="left">
              We co-founded Title365 Company during the worst real estate market and quickly scaled the business to
              become the fastest growing national title agency. We also engineered multiple innovative industry
              applications from 2009 to 2015, including the Title365 Platform, Title365 Mobile, PropFacts, Xpress Search
              Engine, Trusted Signing and more.
            </TimelineTile>
          </TimelineTiles>
        </TimelinePeriod>

        <TimelinePeriod className="timeline-period_margin2010">
          <TimelineYear direction="right">2010</TimelineYear>
          <TimelineTiles>
            <TimelineTile direction="right">
              Our co-founders achieved three significant industry-first technology milestones:
              <ol>
                <li>Created the title industry’s first native mobile iOS application</li>
                <li>Became the first to deploy the entire enterprise in the cloud</li>
                <li>Achieved first-ever SSAE 16 SOC II Type II compliance for a title agency</li>
              </ol>
            </TimelineTile>
          </TimelineTiles>
        </TimelinePeriod>

        <TimelinePeriod className="timeline-period_margin2013">
          <TimelineYear direction="left">2013</TimelineYear>
          <TimelineTiles>
            <TimelineTile direction="left">
              We delivered an all-new decisioning solution that expedited the title insurance, settlement services,
              financial and real estate industries. With an improved design, this third-generation engine is currently
              operated by a large national underwriter.
            </TimelineTile>
          </TimelineTiles>
        </TimelinePeriod>

        <TimelinePeriod className="timeline-period_margin2015">
          <TimelineYear direction="right">2015</TimelineYear>
          <TimelineTiles>
            <TimelineTile direction="left">
              We became the first in the industry to bring to market an innovative end-to-end document signing and
              closing experience to the market, Trusted Signing.
            </TimelineTile>
            <TimelineTile direction="right">
              Title365 is acquired by Mr. Cooper (formerly Nationstar), a top national mortgage servicer and lender.
            </TimelineTile>
          </TimelineTiles>
        </TimelinePeriod>

        <TimelinePeriod className="timeline-period_2017-zIndex">
          <TimelineYear direction="left">2017</TimelineYear>
          <TimelineTiles>
            <TimelineTile direction="left">
              <div className="icon-rocket" />
              <b>In July of 2017, Peter Bowman and Peter Richter founded Flueid Software Corporation. </b>The founders,
              along with Dennis Frandsen, the company’s chief architect, self-funded the journey to fuel digital real
              estate transactions as an independent technology company.
              <ul>
                <li>Designed and built a proprietary decision engine for a large national underwriter.</li>
                <li>
                  Built on industry experience to deliver an entirely new proprietary decisioning system, which is
                  currently used in the market today.
                </li>
              </ul>
            </TimelineTile>
            <TimelineTile direction="right" className="timeline-tile_margin2017">
              Built <b>Flueid Pro</b> (fka Titlefy Pro), the title industry’s most user-friendly real estate and lending
              pro property and consumer research platform that provides transaction management.
              <br />
              <br />
              <NavLink to="/demo">
                <Button>Schedule a Flueid Pro Demo</Button>
              </NavLink>
            </TimelineTile>
            <TimelineTile direction="right">
              Designed an entirely unique software-as-a service solution, <b>Flueid Decision</b>. In 2019, we began
              building the platform to be agnostic, meaning it is not owned or managed by any one underwriter, title
              agent, lender, servicer or service provider and is not focused on any one component of the real estate
              lifecycle.
              <br />
              <br />
              <NavLink to="/demo">
                <Button>Schedule a Flueid Decision Demo</Button>
              </NavLink>
            </TimelineTile>
          </TimelineTiles>
        </TimelinePeriod>

        <TimelinePeriod className="timeline-period_margin2020">
          <TimelineYear direction="left">2020</TimelineYear>
          <TimelineTiles>
            <TimelineTile direction="left">
              Partnered with Aquiline Technology Growth and accepted a strategic investment.
            </TimelineTile>
            <TimelineTile direction="left">Strengthened data lake through a strategic investment.</TimelineTile>
            <TimelineTile className="timeline-tile_marginFlueidBuilt" direction="right">
              Introduce <b>Flueid Built</b>, Flueid’s total title solution for lenders, PropTech and FinTech providers.
              <br />
              <br />
              <NavLink to="/demo">
                <Button>Schedule a Flueid Built Analysis</Button>
              </NavLink>
            </TimelineTile>
          </TimelineTiles>
        </TimelinePeriod>

        <TimelinePeriod className="timeline-period_margin2021">
          <TimelineYear direction="right">2021</TimelineYear>
          <TimelineTiles>
            <TimelineTile direction="left">
              Secured Series A funding from lead investor, Aquiline, and Commerce Ventures. Raised initial funding to
              support the expansion of the Flueid platform, advance business development, and expand integrations with
              client and industry partners.
            </TimelineTile>
            <TimelineTile direction="left">Successfully completed SOC 2 Type I audit.</TimelineTile>
            <TimelineTile direction="left">
              Successfully completed SOC 2 Type II audit in just nine months.
            </TimelineTile>
            <TimelineTile direction="right" className="timeline-tile_marginSeriesA">
              Acquired next-gen real estate software provider, Vodii.
            </TimelineTile>
            <TimelineTile direction="right">Awarded patent for the Flueid Decision platform.</TimelineTile>
            <TimelineTile direction="right">
              Added Purchase transactions to Flueid Decision’s capabilities.
            </TimelineTile>
            <TimelineTile direction="left">
              Launched limited release of Flueid’s Purchase product, delivering the first milestone toward the company’s
              vision of a truly immediate and centralized purchase workflow and closing supporting the on-demand
              expectations of today’s homebuying consumer.
            </TimelineTile>
          </TimelineTiles>
        </TimelinePeriod>

        <TimelinePeriod className="timeline-period_margin2021">
          <TimelineYear direction="left">2022</TimelineYear>
          <TimelineTiles>
            <TimelineTile direction="left">
              Awarded patent extension on Flueid Decision that broadens the scope of its first patent, further
              validating Flueid Decision’s industry innovation as the first and only multi-tenant and agnostic system
              that drives integration and streamlines workflows.
            </TimelineTile>
            <TimelineTile direction="right" className="timeline-tile_marginSeriesA">
              Launched limited release of Flueid’s end-to-end Home Equity solution, demonstrating the platform’s
              versatility regardless of market forces and tapping into consumers’ growing home equity in the nation.
            </TimelineTile>
            <TimelineTile direction="right">
              Launched new solar analyzer as the latest Flueid Decision feature, which uses unique technology to detect
              solar panels through satellite imagery. This reduces risks during the title search by proactively
              uncovering potential PACE/HERO liens through property data and liens recorded with the UCC Secretary of
              State -- clearing the transaction for closing.
            </TimelineTile>
            <TimelineTile direction="left">
              Received final issuance of SOC 2 Type 2 unqualified report on Flueid’s Title Underwriting Workflow System
              Related to Flueid Decision.
            </TimelineTile>
            <TimelineTile direction="right">
              Raised $20 million to accelerate product diversification and growth in every real estate market cycle.
            </TimelineTile>
          </TimelineTiles>
        </TimelinePeriod>

        <TimelinePeriod className="timeline-period_marginToday">
          <TimelineYear direction="left">Today</TimelineYear>
          <TimelineTiles>
            <TimelineTile className="timeline-tile_blue" direction="left">
              Hundreds of thousands of homeowners, buyers and sellers have benefitted from Flueid’s technology through
              savings and customer experience. And we are just getting started.
            </TimelineTile>
          </TimelineTiles>
        </TimelinePeriod>
      </div>
    </SectionContent>
  </section>
);
