export function hasKey<O extends {}>(obj: O, key: keyof any): key is keyof O {
  return key in obj;
}

export function mapParsedValues<T>(targetObj: T, parsedParamObj: object): T {
  const target = { ...targetObj };
  for (const key in target) {
    // check is key exists
    if (hasKey(parsedParamObj, key)) {
      // is this an object?
      if (Object.prototype.toString.call(target[key]).indexOf("Object") > -1) {
        // parse the object
        const parsedNestedObj = JSON.parse(parsedParamObj[key]);
        // recursive call
        target[key] = mapParsedValues(target[key], parsedNestedObj);
      } else {
        target[key] = parsedParamObj[key];
      }
    }
  }
  return target;
}
