import { useState } from "react";
import SecurityDetailsRow from "./securityDetailsRow";
import iconBank from "../../images/icons/icon-bankgrade.svg";
import iconCloud from "../../images/icons/icon-cloud.svg";
import iconArrows from "../../images/icons/icon-config.svg";
import iconToken from "../../images/icons/icon-token.svg";
import iconDataFirst from "../../images/icons/icon-datafirst.svg";
import iconControl from "../../images/icons/icon-data-white.svg";
import { TextLink } from "components/common/textLink";

export interface SecurityDetails {
  title: string;
  description: string;
  img: string;
}

const bankSecurity: SecurityDetails = {
  title: "Bank-Grade Security",
  description: `Our platform is built on a zero-trust architecture to prioritize the protection of your data and your
  clients’ data. It was also built by members of the team who advised on the standard of information
  gathering (SIG) for institutional banks and lenders.`,
  img: iconBank,
};

const cloudNativeArch: SecurityDetails = {
  title: "Cloud-Native Architecture",
  description: `We are 100% architected in cloud-native services allowing our partners to take advantage of a scalable, elastic, resilient, flexible, and more importantly, secure system as you grow and evolve.`,
  img: iconCloud,
};

const configSolutions: SecurityDetails = {
  title: "Configurable Solutions",
  description: `Our platform is customizable to your unique needs and business objectives – from risk tolerance to data
  sets and more – to optimize systems for a competitive advantage.`,
  img: iconArrows,
};

const dataFirst: SecurityDetails = {
  title: "Data-First Products",
  description: `We are bringing robust and authoritative data sets together in an automated fashion. This allows decisioning to be fast, secure, transparent, and tailored to client’s needs.`,
  img: iconToken,
};

const apiTokens: SecurityDetails = {
  title: "Token-Based API Integrations",
  description: `Our APIs securely exchange data between systems using leading token-based authentication and dedicated KMS
  encryption management.`,
  img: iconDataFirst,
};

const dataControl: SecurityDetails = {
  title: "Data Control & Retention",
  description: `Inherit an audited and hardened environment which can be placed behind your ITGC’s for maximum control of
  your data, both in transit and at rest.`,
  img: iconControl,
};

const details: SecurityDetails[] = [bankSecurity, cloudNativeArch, configSolutions, dataFirst, apiTokens, dataControl];

export const SolutionsSection = () => {
  const [open, setOpen] = useState(bankSecurity);

  return (
    <section className="solutions-section" id="solutions">
      <div className="waves-container">
        <svg
          className="waves"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          viewBox="0 24 150 28"
          preserveAspectRatio="none"
          shapeRendering="auto"
        >
          <defs>
            <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
          </defs>
          <g className="parallax">
            <use xlinkHref="#gentle-wave" x="48" y="0" fill="rgba(248,248,248,0.7)" />
            <use xlinkHref="#gentle-wave" x="48" y="3" fill="rgba(248,248,248,0.5)" />
            <use xlinkHref="#gentle-wave" x="48" y="5" fill="rgba(248,248,248,0.3)" />
            <use xlinkHref="#gentle-wave" x="48" y="7" fill="rgba(248,248,248,1)" />
          </g>
        </svg>
      </div>
      <h2 className="security-header mobile-width">Your trusted partner in security</h2>
      <div className="security-flex">
        <div className="card">
          {details.map((d, i) => (
            <SecurityDetailsRow onClick={() => setOpen(d)} key={i} open={open === d} details={d} />
          ))}
          <TextLink to="/security" color="white" className="my-4">
            Explore our platform's principles
          </TextLink>
        </div>
        <div className="security-right">
          <div className="aicpa-img"></div>
          <h2>98%</h2>
          <p className="txt-semiBold">ALIGNMENT WITH NIST CSF</p>
        </div>
      </div>
      <div className="trust-img"></div>
    </section>
  );
};
