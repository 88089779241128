import { Entry } from "contentful";
import contentfulClient from "utilities/contentfulClient";

export interface IJobPosting {
  title?: string;
  slug?: string;
  department?: string;
  location?: string;
  listingUrl?: string;
  type?: string;
  summaryTags?: string[];
  benefitTags?: string[];
  // all these below are markdown strings - use react-markdown to render
  descriptionShort?: string;
  descriptionFull?: string;
  duties?: string;
  requirements?: string;
  benefits?: string;
  aboutFlueid?: string;
}

export const JOB_POSTING_CONTENT_TYPE_ID = "flueidJobPosting";

export const getJobPostings = () =>
  contentfulClient.getEntries<IJobPosting>({
    content_type: JOB_POSTING_CONTENT_TYPE_ID,
  });

export type JobProps = {
  jobs: Entry<IJobPosting>[];
  loading: boolean;
};
