import { useEffect, useRef, useState } from "react";
import HeroSlideBtn from "./heroSlideBtn";
import { SectionContent } from "components/common/sectionContent";
import { HomePageHeroSlideProps, IHomePageHeroSlide } from "types/homePageHeroSlide";
import { HeroSlide } from "./heroSlide";

const HeroSectionHome = ({ homePageHeroSlides }: HomePageHeroSlideProps) => {
  // sort by their position
  const slides = [...homePageHeroSlides]
    .sort((a, b) => Number(a.fields?.position) - Number(b.fields?.position))
    .map((x) => x.fields);
  const multipleSlides = slides.length > 1;
  const [activeSlide, setActiveSlide] = useState(slides[0]);
  const [timerEnabled, setTimerEnabled] = useState(true);
  const [activeSlideProgress, setActiveSlideProgress] = useState(0);
  const progressIntervalRef = useRef<number>();
  const INTERVAL_LENGTH = 10000;

  const getNextSlide = (activeSlide: IHomePageHeroSlide) => {
    const activeIndex = slides.indexOf(activeSlide);
    // at the end? go to start
    if (slides.length - 1 === activeIndex) {
      return slides[0];
    } else {
      return slides[activeIndex + 1];
    }
  };

  // when active slide changes - start calculating new progress
  // inspo https://stackoverflow.com/questions/31530666/progress-bar-with-duration-and-percentage
  useEffect(() => {
    // no need for any of this if there's only 1 slide
    if (!multipleSlides) return;

    // timer disabled by user interaction - clear interval
    if (!timerEnabled) {
      window.clearInterval(progressIntervalRef.current);
    } else {
      const percent = INTERVAL_LENGTH / 100;
      let i = 0;

      progressIntervalRef.current = window.setInterval(() => {
        i++;
        // complete? reset progress, go to next slide & clear interval
        if (i >= 100) {
          setActiveSlideProgress(0);
          setActiveSlide((active) => getNextSlide(active));
          window.clearInterval(progressIntervalRef.current);
        } else {
          setActiveSlideProgress(i);
        }
      }, percent);
    }

    return () => window.clearInterval(progressIntervalRef.current);
  }, [activeSlide, timerEnabled]);

  return (
    <>
      <div className="home-hero-sections-container">
        {/* NOTE - unique key is necessary to trigger the animations to fire anew when hero slide changes */}
        <HeroSlide heroslider={activeSlide} key={activeSlide.name} />

        <SectionContent className="btns-section">
          <div className="btns-wrapper flex">
            {multipleSlides &&
              slides.map((slide, i) => {
                const active = activeSlide === slide;
                const progress = active ? (timerEnabled ? activeSlideProgress : 100) : 0;
                return (
                  <HeroSlideBtn
                    key={i}
                    active={active}
                    progress={progress}
                    label={String(i + 1)}
                    onClick={() => {
                      // when user clicks these buttons, they assume control and auto play timer disabled
                      setTimerEnabled(false);
                      setActiveSlide(slide);
                    }}
                  />
                );
              })}
          </div>
        </SectionContent>
      </div>
    </>
  );
};

export default HeroSectionHome;
