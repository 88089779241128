export interface Email {
  to: string[];
  subject: string;
  text: string;
}

export enum StatusCodes {
  Ok = 200,
  BadRequest = 400,
  InternalServerError = 500,
}

export interface EmailResponse {
  statusCode: StatusCodes;
  body: string;
}
