import { createContext } from "react";
import { Entry, Asset } from "contentful";
import { Document } from "@contentful/rich-text-types";

// use this for typing route match params
export interface EditorialRouteParams {
  slug: string;
}

export type EditorialType = "Press Release" | "Case Study" | "Article" | "Story";

export interface EditorialStat {
  value?: string;
  text?: string;
}

export interface Editorial {
  body?: Document;
  byLine?: string;
  cardDescription?: string;
  externalLink?: string;
  featured?: boolean;
  featuredImg?: Asset;
  headerImg?: Asset;
  previewImg?: Asset;
  previousPath?: string;
  publishDate: string;
  slug: string;
  stats?: EditorialStat[];
  subTitle?: string;
  title: string;
  type: EditorialType;
  useRequestForm?: boolean;
}

export const EditorialsContext = createContext<Entry<Editorial>[]>([]);
