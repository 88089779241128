import { NavLink } from "react-router-dom";
import { Button } from "react-scroll";
import { HeroSection } from "../common/heroSection";
import Transition from "components/controls/transition";
import Visible from "components/controls/visible";

export const HeroSectionHomeEquity = () => (
  <div className="loss-mit-container">
    <HeroSection className="home-equity-hero-section">
      <div className="hero-img-equity">
        <div className="hero-content">
          <div className="hero-title">
            <span className="hero-bold-title">Loss Mitigation Analysis Report </span>for Servicers
          </div>
          <div className="hero-subtitle">The power of data realized for the borrower and servicer.</div>
        </div>
      </div>
      <Visible>
        {(state) => (
          <Transition animation="fadeInUpLong" duration={1000} visible={state.visible} delay={2000}>
            <div ref={state.ref} className="hero-right-container">
              <div className="hero-right-bold text-white">Decision Overview</div>
              <div className="hero-right-row">
                <div className="hero-right-icon-green"></div>
                <div className="hero-right-light text-white">Refinance</div>
              </div>
              <div className="hero-right-row">
                <div className="hero-right-icon-green"></div>
                <div className="hero-right-light text-white">Loan Modification</div>
              </div>
              <div className="hero-right-row">
                <div className="hero-right-icon-red"></div>
                <div className="hero-right-light text-white">Short Sale</div>
              </div>
              <div className="hero-right-row">
                <div className="hero-right-icon-red"></div>
                <div className="hero-right-light text-white">Deed-in-Lieu Preview</div>
              </div>
              <div className="hero-right-row">
                <div className="hero-right-icon-yellow"></div>
                <div className="hero-right-light text-white">Foreclosure Preview</div>
              </div>
              <div className="hero-right-bold border-top text-white">Consumer Options with Equity</div>
              <div className="hero-right-row">
                <div className="hero-right-icon-green"></div>
                <div className="hero-right-light text-white">Equity Options</div>
              </div>
              <div className="hero-right-row">
                <div className="hero-right-icon-green"></div>
                <div className="hero-right-light text-white">Property Sale Options</div>
              </div>
            </div>
          </Transition>
        )}
      </Visible>
      <div className="mobile-text-container-loss-mit">
        <div className="hero-title-mobile">
          <span className="hero-bold-title-mobile">Loss Mitigation Decision </span>for Servicers
        </div>
        <div className="hero-subtitle-mobile">
          All paths, one consolidated snapshot right at the start of your process.
        </div>
      </div>
    </HeroSection>
  </div>
);
