import { Link as ScrollLink } from "react-scroll";
export const BreakSectionLossMit = () => (
  <div className="break-section">
    <div className="breaksection-text">
      What if you could understand the viability of every loss mitigation strategy for you as the servicer – and for the
      borrower – in 45 seconds?
    </div>
    <ScrollLink to="graphsection" smooth>
      <div className="arrow-icon"></div>
    </ScrollLink>
  </div>
);
