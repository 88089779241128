import { NavLink } from "react-router-dom";
import { Button } from "react-scroll";
import { HeroSection } from "../common/heroSection";

export const HeroSectionHomeEquity = () => (
  <HeroSection className="home-equity-hero-section">
    <div className="hero-img-equity">
      <div className="hero-content">
        <div className="hero-title">
          <span className="hero-bold-title">Home Equity Decision </span>for Lenders
        </div>
        <div className="hero-subtitle">
          Imagine the ability to convert borrowers seconds after a home equity application is placed
        </div>
      </div>
    </div>
    <div className="mobile-text-container">
      <div className="hero-title">
        <span className="hero-bold-title">Home Equity Decision </span>for Lenders
      </div>
      <div className="hero-subtitle">
        Imagine the ability to convert borrowers seconds after a home equity application is placed
      </div>
    </div>
  </HeroSection>
);
