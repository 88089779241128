import DashedLineGroup, { DashedLine } from "../../controls/dashedLines";
import { HeroSection } from "../../common/heroSection";
import Transition from "../../controls/transition";
import Visible from "../../controls/visible";
import { Button } from "../../controls/button";
import { NavLink } from "react-router-dom";
import { IHomePageHeroSlide } from "types/homePageHeroSlide";
import { getInternalUrlPath } from "utilities/url";

interface Props {
  heroslider: IHomePageHeroSlide;
}

type ScreenType = "mobile" | "desktop";

export const HeroSlide: React.FC<Props> = ({ heroslider }) => {
  const {
    backgroundGraphic,
    ctaLink,
    ctaLinkExternal,
    ctaText,
    floatingGraphic,
    floatingGraphicOffsetRight,
    floatingGraphicOffsetTop,
    floatingPhoto,
    floatingPhotoObjectPosition,
    floatingPhotoOffsetRight,
    floatingPhotoOffsetTop,
    header,
    message,
    preHeadline,
    useAnimatedDashedLines,
  } = heroslider;

  // offsets in `rem` - only applies for desktop screens
  const getOffsetValue = (number: number | undefined, screen: ScreenType) =>
    number && screen !== "mobile" ? `${number}rem` : undefined;

  const PreHeadline = () => (
    <Visible>
      {(state) => (
        <Transition animation="fadeInLeft" duration={1000} visible={state.visible}>
          <p className="txt-subheader" ref={state.ref}>
            {preHeadline}
          </p>
        </Transition>
      )}
    </Visible>
  );

  const Header = () => (
    <Visible>
      {(state) => (
        <Transition animation="fadeInLeft" duration={1000} delay={150} visible={state.visible}>
          <h1 ref={state.ref}>{header}</h1>
        </Transition>
      )}
    </Visible>
  );

  const Message = () => (
    <Visible>
      {(state) => (
        <Transition animation="fadeInLeft" duration={1000} delay={300} visible={state.visible}>
          <h5 ref={state.ref} className="txt-light">
            {message}
          </h5>
        </Transition>
      )}
    </Visible>
  );

  const CTALink = () => (
    <Visible>
      {(state) => (
        <Transition animation="fadeInLeft" duration={1000} delay={450} visible={state.visible}>
          <div ref={state.ref}>
            {ctaLinkExternal ? (
              <a href={ctaLink} className="max-content btn btn-white btn-learn-more-hero" target="_blank">
                {ctaText}
              </a>
            ) : (
              <NavLink to={getInternalUrlPath(ctaLink)} className="max-content">
                <Button className="btn-white btn-learn-more-hero">{ctaText}</Button>
              </NavLink>
            )}
          </div>
        </Transition>
      )}
    </Visible>
  );

  const Photos = (screen: "desktop" | "mobile") => (
    <>
      {floatingGraphic?.fields.file.url && (
        <Visible>
          {(state) => (
            <Transition animation="fadeInRight" duration={1500} delay={600} visible={state.visible}>
              <img
                ref={state.ref as React.RefObject<HTMLImageElement>}
                className={`floating-graphic-${screen}`}
                src={floatingGraphic?.fields.file.url}
                style={{
                  top: getOffsetValue(floatingGraphicOffsetTop, screen),
                  right: getOffsetValue(floatingGraphicOffsetRight, screen),
                }}
              />
            </Transition>
          )}
        </Visible>
      )}
      {floatingPhoto?.fields.file.url && (
        <Visible>
          {(state) => (
            <Transition animation="fadeInRight" duration={2500} delay={1000} visible={state.visible}>
              <img
                ref={state.ref as React.RefObject<HTMLImageElement>}
                className={`floating-photo-${screen}`}
                src={floatingPhoto?.fields.file.url}
                style={{
                  top: getOffsetValue(floatingPhotoOffsetTop, screen),
                  right: getOffsetValue(floatingPhotoOffsetRight, screen),
                  objectPosition: floatingPhotoObjectPosition || undefined,
                }}
              />
            </Transition>
          )}
        </Visible>
      )}
    </>
  );

  return (
    <HeroSection
      className={`fadeIn homePage black hero-section-new`}
      backgroundImg={backgroundGraphic?.fields.file.url ? "https:" + backgroundGraphic?.fields.file.url : undefined}
    >
      {/* TODO JB - randomize the dashed line props */}
      {useAnimatedDashedLines && (
        <DashedLineGroup className="dashedLineGroup-1">
          {(visible) => (
            <>
              <DashedLine color="white" width={75} duration={2.5} delay={0} x={"1vw"} show={visible} />
              <DashedLine color="white" width={231} duration={1.5} delay={0.2} x={"5vw"} show={visible} />
              <DashedLine color="white" width={122} duration={1} delay={0.6} x={"8vw"} show={visible} />
              <DashedLine color="white" width={29} duration={0.5} delay={0.8} x={"10vw"} show={visible} />
            </>
          )}
        </DashedLineGroup>
      )}

      {/* DESKTOP LAYOUT */}
      <div className="desktop-grid">
        <div className="desktop-text">
          {PreHeadline()}
          {Header()}
          {Message()}
          {CTALink()}
        </div>
        <div className="desktop-photos">{Photos("desktop")}</div>
      </div>

      {/* MOBILE LAYOUT */}
      <div className="mobile-stack">
        {PreHeadline()}
        {Header()}
        <div className="mobile-photo-grid">{Photos("mobile")}</div>
        {Message()}
        {CTALink()}
      </div>
    </HeroSection>
  );
};
