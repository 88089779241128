import React from "react";
import DashedLineGroup, { DashedLine } from "../controls/dashedLines";

export const ContactInfoSection = () => (
  <section className="contactInfo-section">
    <div className="content">
      <div className="row">
        <h2>Call or email us</h2>
        <div className="flueidProTalktDes-officeSec">
          <div className="flueidProTalktDes-officeItem">
            <div className="flueidProTalktDes-seperator"></div>
            <div className="p-2 txt-semiBold">CUSTOMER SERVICE</div>
            <a href="tel:888-835-8343" className="p-2 txt-regular">
              (888) 835-8343
            </a>
            <a href="mailto:support@flueid.com" className="p-2 txt-regular address" rel="noreferrer">
              support@flueid.com
            </a>
          </div>
          <div className="flueidProTalktDes-officeItem">
            <div className="flueidProTalktDes-seperator"></div>
            <div className="p-2 txt-semiBold">SALES</div>
            <a href="tel:888-835-8343" className="p-2 txt-regular">
              (888) 835-8343
            </a>
            <a href="mailto:sales@flueid.com" className="p-2 txt-regular address" rel="noreferrer">
              sales@flueid.com
            </a>
          </div>
          <div className="flueidProTalktDes-officeItem">
            <div className="flueidProTalktDes-seperator"></div>
            <div className="p-2 txt-semiBold">MEDIA</div>
            <a href="tel:737-703-3578" className="p-2 txt-regular">
              (737) 703-3578
            </a>
            <a href="mailto:media@flueid.com" className="p-2 txt-regular address" rel="noreferrer">
              media@flueid.com
            </a>
          </div>
          <div className="flueidProTalktDes-officeItem">
            <div className="flueidProTalktDes-seperator"></div>
            <div className="p-2 txt-semiBold">HUMAN RESOURCES</div>
            <a href="tel:737-703-3578" className="p-2 txt-regular">
              (619) 870- 8161
            </a>
            <a href="mailto:media@flueid.com" className="p-2 txt-regular address" rel="noreferrer">
              hr@flueid.com
            </a>
          </div>
        </div>
      </div>
      <div className="row">
        <h2>Visit us or send snail mail</h2>
        <div className="flueidProTalktDes-officeSec">
          <div className="flueidProTalktDes-officeItem">
            <div className="flueidProTalktDes-seperator"></div>
            <div className="p-2 txt-semiBold">AUSTIN, TX OFFICE</div>
            <div className="p-2 txt-regular">7500 Rialto Blvd, Building 2, Suite 210</div>
            <div className="p-2 txt-regular address">Austin, Texas 78735</div>
            <div className="flueidProTalktDes-locationDir">
              <a
                target="_blank"
                href="https://www.google.com/maps/place/7500+Rialto+Blvd+%23260,+Austin,+TX+78735/@30.2538202,-97.8743147,17z/data=!3m1!4b1!4m5!3m4!1s0x865b4a29d552f86f:0x34cefd6cda8e9bf0!8m2!3d30.2538202!4d-97.872126"
                className="p-2 txt-regular "
                rel="noreferrer">
                Map
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.google.com/maps/dir//7500+Rialto+Blvd+%23260,+Austin,+TX+78735/@31.7327468,-98.4603833,8z/data=!4m8!4m7!1m0!1m5!1m1!1s0x865b4a29d552f86f:0x34cefd6cda8e9bf0!2m2!1d-97.872126!2d30.2538202"
                className="p-2 txt-regular ">
                Driving Directions
              </a>
            </div>
          </div>
          <div className="flueidProTalktDes-officeItem">
            <div className="flueidProTalktDes-seperator"></div>
            <div className="p-2 txt-semiBold">SANTA BARBARA, CA OFFICE</div>
            <div className="p-2 txt-regular">800 Miramonte Drive, Suite 320</div>
            <div className="p-2 txt-regular address">Santa Barbara, CA 93109</div>
            <div className="flueidProTalktDes-locationDir">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.google.com/maps/place/800+Miramonte+Dr+%23320,+Santa+Barbara,+CA+93109/@34.4105404,-119.7109922,17z/data=!3m1!4b1!4m5!3m4!1s0x80e9146e33b1a6e7:0x8efd28ee0b3ffa18!8m2!3d34.4105404!4d-119.7088035"
                className="p-2 txt-regular ">
                Map
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.google.com/maps/dir//800+Miramonte+Dr+%23320,+Santa+Barbara,+CA+93109/@34.4105404,-119.7109922,17z/data=!4m17!1m7!3m6!1s0x80e9146e33b1a6e7:0x8efd28ee0b3ffa18!2s800+Miramonte+Dr+%23320,+Santa+Barbara,+CA+93109!3b1!8m2!3d34.4105404!4d-119.7088035!4m8!1m0!1m5!1m1!1s0x80e9146e33b1a6e7:0x8efd28ee0b3ffa18!2m2!1d-119.7088035!2d34.4105404!3e2"
                className="p-2 txt-regular ">
                Driving Directions
              </a>
            </div>
          </div>
          <div className="flueidProTalktDes-officeItem">
            <div className="flueidProTalktDes-seperator"></div>
            <div className="p-2 txt-semiBold">BUSINESS HOURS</div>
            <div className="p-2 txt-regular">8:30 am - 5:30 pm</div>
            <div className="p-2 txt-regular address">Mon - Fri</div>
          </div>
        </div>
      </div>
    </div>
    <DashedLineGroup className="dashedLineGroup-1">
      {(visible) => (
        <>
          <DashedLine width={50} duration={2.5} delay={0} x={"5vw"} show={visible} />
          <DashedLine width={35} duration={1.5} delay={0.2} x={"15vw"} show={visible} />
          <DashedLine width={200} duration={1} delay={0.5} x={"18vw"} show={visible} />
          <DashedLine width={75} duration={0.5} delay={0.7} x={"20vw"} show={visible} />
        </>
      )}
    </DashedLineGroup>
  </section>
);
