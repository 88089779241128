import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

export interface UsePaginationProps<T> {
  pageSize: number;
  name: string;
  data: T[];
}

export const usePagination = <T>({ pageSize, name, data }: UsePaginationProps<T>) => {
  const history = useHistory();
  const [current, setCurrent] = useState(1);

  // use search params for initial state
  useEffect(() => {
    const params = new URLSearchParams(history.location.search);
    const page = params.get(name);
    setCurrent(Number(page) || 1);
  }, []);

  // current page changes, update query params
  useEffect(() => {
    const params = new URLSearchParams(history.location.search);
    params.set(name, current.toString());
    history.replace(history.location.pathname + "?" + params.toString());
  }, [current]);

  // calculate page data
  const pageStart = (current - 1) * pageSize;
  const pageEnd = pageStart + pageSize;
  const totalPages = Math.ceil(data.length / pageSize);
  const pageData = data.slice(pageStart, pageEnd);

  // return the pagination state
  return { current, setCurrent, pageStart, pageEnd, totalPages, pageSize, pageData };
};
