import React from "react";

interface Props {
  onVisible?: () => void;
  showOnMount?: boolean;
  options?: IntersectionObserverInit;
  children?(state: VisibleState): React.ReactNode;
}

interface VisibleState {
  visible: boolean;
  ref: React.RefObject<HTMLDivElement>;
}

class Visible extends React.Component<Props, VisibleState> {
  ref = React.createRef<HTMLDivElement>();
  observer: IntersectionObserver | null = null;
  defaultOptions: IntersectionObserverInit = {
    rootMargin: "0px 0px 0px 0px",
  };

  state: VisibleState = {
    visible: false,
    ref: React.createRef<HTMLDivElement>(),
  };

  componentDidMount() {
    const { showOnMount, options } = this.props;
    if (showOnMount) {
      this.setState({ visible: true });
    } else {
      const ref = this.state.ref.current;
      if (ref) {
        this.observer = new IntersectionObserver(this.onIntersection, options || this.defaultOptions);
        this.observer.observe(ref);
      }
    }
  }

  componentWillUnmount() {
    if (this.observer) this.observer.disconnect();
  }

  onIntersection = (entries: IntersectionObserverEntry[], observer: IntersectionObserver) => {
    const [entry] = entries;
    if (entry.isIntersecting) {
      if (this.props.onVisible) this.props.onVisible();
      this.setState({ visible: true });
      observer.disconnect();
    }

    // entries.forEach((entry: IntersectionObserverEntry) => {
    //   const { isIntersecting } = entry;
    //   if (isIntersecting) {
    //     if (this.props.onVisible) this.props.onVisible();
    //     this.setState({ visible: true });
    //     observer.disconnect();
    //   }
    // });
  };

  render() {
    const { children } = this.props;
    return <>{children ? children(this.state) : ""}</>;
  }
}

export default Visible;
