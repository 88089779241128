import { isNavBlackVariant, isNavWhiteVariant, NavbarColor } from "components/navbar/navbar";
import { ReactComponent as IconDownBlack } from "../../images/icons/glyph-dropdown.down.svg";
import { ReactComponent as IconDownWhite } from "../../images/icons/glyph-dropdown.down.white.svg";

export function PopupArrow(open: boolean, color: NavbarColor): React.ReactNode | undefined {
  const iconClass: string = `icon-dropdownArrow ${open ? "rotateOpen" : ""}`;

  if (isNavBlackVariant(color) || color === "green") {
    return <IconDownWhite className={iconClass} />;
  }

  if (isNavWhiteVariant(color)) {
    return <IconDownBlack className={iconClass} />;
  }
}
