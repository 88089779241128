import cloneDeep from "lodash.clonedeep";
import get from "lodash.get";

export enum ValidationType {
  Undefined,
  Value,
  Email,
  Phone,
  SSN,
  Zip,
  TaxId,
  NotNullOrUndefined,
  CustomPattern,
  NotEmptyArray,
}

export interface ValidatedField {
  required: boolean;
  valid: boolean;
  validationTypes: ValidationType[];
  customValidationPattern?: RegExp;
}

export function newValidatedField(
  required: boolean,
  validationTypes: ValidationType[],
  customValidationPattern?: RegExp,
): ValidatedField {
  const newField: ValidatedField = { required, valid: true, validationTypes };
  if (customValidationPattern) newField.customValidationPattern = customValidationPattern;
  return newField;
}

function isValidatedField(field: ValidatedField | undefined): field is ValidatedField {
  return field !== undefined && "valid" in field;
}

export function setValidatedFieldTrue<T>(validation: ValidationSchema<T>, name: keyof T): ValidationSchema<T> {
  let updatedValidation = cloneDeep(validation);
  let field: ValidatedField | undefined = updatedValidation[name];
  if (field && !field.valid) {
    field = { ...field, valid: true };
    updatedValidation[name] = field;
  }
  return updatedValidation;
}

export type ValidationSchema<T> = {
  [Key in keyof T]?: ValidatedField;
};

export type ValidationDictionary<K extends string, O> = {
  [key in K]?: ValidationSchema<O>;
};

// pass in an object that has the values and the validation schema
export function validateSchema<T>(
  object: { [key in keyof T]?: any },
  schema: ValidationSchema<T>,
): ValidationSchema<T> {
  const updatedSchema: ValidationSchema<T> = cloneDeep(schema);
  // iterate through keys of validation schema
  for (const key in updatedSchema) {
    // grab the value off the object
    const value = get(object, key);
    // validate it
    const field = updatedSchema[key];
    if (isValidatedField(field)) {
      validateField(field, value) ? (field.valid = true) : (field.valid = false);
    }
  }
  return updatedSchema;
}

// quick way to check if any fields are valid, will break when first false value hit
export function allSchemaFieldsValid<T>(schema: ValidationSchema<T>): boolean {
  let allFieldsValid: boolean = true;
  for (const key in schema) {
    const field = schema[key];
    if (isValidatedField(field) && !field.valid) {
      allFieldsValid = false;
      break;
    }
  }
  return allFieldsValid;
}

export function validateField(field: ValidatedField, value: any): boolean {
  // it's not required and there's no value, then it's considered valid
  if (!field.required && !value) return true;

  let fieldValid: boolean = true;

  // go through all validation types
  for (let i = 0; i < field.validationTypes.length; i++) {
    let validationTypePassed: boolean = true;

    // validate the value
    switch (field.validationTypes[i]) {
      case ValidationType.Email:
        validationTypePassed = Validate.Email(value);
        break;
      case ValidationType.Phone:
        validationTypePassed = Validate.Phone(value);
        break;
      case ValidationType.Zip:
        validationTypePassed = Validate.Zipcode(value);
        break;
      case ValidationType.Value:
        validationTypePassed = Validate.Value(value);
        break;
      case ValidationType.NotNullOrUndefined:
        validationTypePassed = Validate.NotNullOrUndefined(value);
        break;
      case ValidationType.NotEmptyArray:
        validationTypePassed = Array.isArray(value) && value.length > 0;
        break;
      case ValidationType.CustomPattern:
        if (field.customValidationPattern) {
          validationTypePassed = Validate.CustomPattern(field.customValidationPattern, value);
          break;
        }
        break;
      default:
        return true;
    }

    // field invalid, break loop
    if (!validationTypePassed) {
      fieldValid = false;
      break;
    }
  }

  return fieldValid;
}

export default class Validate {
  static Email(email: string): boolean {
    // new test regex allowing a specific .realtor or .realestate ending to the email
    return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3}|.realtor|.realestate)+$/.test(email) ? true : false;
  }

  static Phone(phone: string): boolean {
    // remove all white space from phone
    const trimmedPhone: string = phone.replace(/\s+/g, "");
    return trimmedPhone.match(/^(?:\+?1[-.●]?)?\(?([0-9]{3})\)?[-.●]?([0-9]{3})[-.●]?([0-9]{4})$/) ? true : false;
  }

  static Zipcode(zipcode: string): boolean {
    return /^(\d{5})?$/.test(zipcode) ? true : false;
  }

  static Value(value: string | number): boolean {
    // used for making sure some type of value exists for the input
    return value !== null && value !== undefined && value !== "" && value !== 0 ? true : false;
  }

  static NotNullOrUndefined(
    value: string | number | null | undefined | boolean | (string | number | boolean)[],
  ): boolean {
    return value !== null && value !== undefined ? true : false;
  }

  static CustomPattern(pattern: RegExp, value: string): boolean {
    return pattern.test(value);
  }
}
