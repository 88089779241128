export const EquityServicesHomeEquity = () => (
  <div className="services-container">
    <div className="services-title">
      A <span className="text-blue">total</span> home equity services experience
    </div>
    <div className="services-flex-group">
      <div className="services-flex-block">
        <div className="services-block-title">Enable Closing Within Days!</div>
        <div className="services-block-text">
          Achieve a faster closing by pre-qualifying customers for a home equity loan at the POS/Loan Application. Move
          files that are Clear to Schedule Closing down the fast path and order title.
        </div>
      </div>
      <div className="services-flex-block">
        <div className="services-block-title">Eliminate “Shopping Around”</div>
        <div className="services-block-text">
          Identify customers that qualify for a quick closing, locking them in and preventing any shopping around with
          competitors.
        </div>
      </div>
      <div className="services-flex-block">
        <div className="services-block-title">Tailored for the Consumer</div>
        <div className="services-block-text">
          Quickly identify and push forward on loans that have the highest likelihood to close. For loans on the slower
          path, loan officers can transparently communicate any challenges that might arise for a fully tailored
          experience.
        </div>
      </div>
      <div className="services-flex-block">
        <div className="services-block-title">Proactive Title Curative</div>
        <div className="services-block-text">
          Identify loans immediately at the POS that would later fall out in the life cycle saving the lender time and
          money, while enhancing the overall consumer experience.
        </div>
      </div>
      <div className="services-flex-block">
        <div className="services-block-title">Workflow Improvements</div>
        <div className="services-block-text">
          Utilize the lender decision to determine workflow processes. Evaluate Not Clear to Schedule Closing files to
          determine if a loan is viable or if it needs experienced staff to review and process the loan.
        </div>
      </div>
      <div className="services-flex-block">
        <div className="services-block-title">A Foundation & Focus on Data Security</div>
        <div className="services-block-text">
          Be confident your customers' data is securely processed, transmitted and stored with Flueid's SOC 2 Type 2
          compliant platform. NPPI data is secured within a client-controlled Amazon Web Services (AWS) environment down
          to the individual order.
        </div>
      </div>
    </div>
  </div>
);
