import styles from "./styles.module.scss";

interface Props {
  active: boolean;
  progress: number;
  label: string;
  onClick: () => void;
}

// inspo from https://blog.logrocket.com/build-svg-circular-progress-component-react-hooks/
const HeroSlideBtn = ({ active, label, progress, onClick }: Props) => {
  const size = 32;
  const trackWidth = 1.5;
  const trackColor = `#565656`;
  const indicatorWidth = 1.5;
  const indicatorColor = "#5AE995";
  const indicatorCap = `round`;
  const labelColor = "#5AE995";
  const spinnerSpeed = 1;

  const center = size / 2,
    radius = center - (trackWidth > indicatorWidth ? trackWidth : indicatorWidth),
    dashArray = 2 * Math.PI * radius,
    dashOffset = dashArray * ((100 - progress) / 100);

  return (
    <button className={`${styles.btn} ${active ? styles.btn_active : ""}`} style={{ height: size }} onClick={onClick}>
      <svg className={styles.pi} style={{ width: size, height: size }}>
        <circle cx={center} cy={center} fill="transparent" r={radius} stroke={trackColor} strokeWidth={trackWidth} />
        <circle
          className={styles.indicator}
          style={{ animationDuration: String(spinnerSpeed * 1000) }}
          cx={center}
          cy={center}
          fill="transparent"
          r={radius}
          stroke={indicatorColor}
          strokeWidth={indicatorWidth}
          strokeDasharray={dashArray}
          strokeDashoffset={dashOffset}
          strokeLinecap={indicatorCap}
        />
      </svg>
      <div className={styles.label} style={{ color: labelColor }}>
        {label}
      </div>
    </button>
  );
};

export default HeroSlideBtn;
