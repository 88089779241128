import Collapse from "components/controls/collapse";
import { SecurityDetails } from "../solutionsSection";
import styles from "./styles.module.scss";

interface SecurityDetailsRowProps {
  details: SecurityDetails;
  onClick: () => void;
  open: boolean;
}

const SecurityDetailsRow = ({ details, onClick, open }: SecurityDetailsRowProps) => {
  return (
    <div className={styles.details_wrapper}>
      <button className={`${styles.details_btn} ${open ? styles.details_btn_open : ""}`} onClick={onClick}>
        <p className={`${styles.summary} ${open ? styles.open : ""}`}>
          <img className={styles.img} src={details.img}></img>
          <span>{details.title}</span>
        </p>
      </button>
      <Collapse open={open}>
        <p className={styles.paragraph}>{details.description}</p>
      </Collapse>
    </div>
  );
};

export default SecurityDetailsRow;
