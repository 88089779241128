import { SectionContent } from "components/common/sectionContent";
import { useContext } from "react";
import { NavLink } from "react-router-dom";
import { EditorialsContext } from "types/editorial";
import { ReactComponent as IconArrowRight } from "../../images/icons/glyph-arrow.right.svg";

export const FeaturedStoryAboutSection = () => {
  // grab petes story
  const editorials = useContext(EditorialsContext);
  const story = editorials.find((x) => x.sys.id === "6L2q6TgiW4TiZx8RgPrtFt");

  if (!story) return null;

  return (
    <section className="featuredAboutStory-section">
      <SectionContent>
        <div className="flex flex-col-mobile">
          <img
            src={story.fields.headerImg?.fields?.file?.url}
            alt={story.fields.headerImg?.fields?.description}
            className="img-featuredStory"
          />
          <div className="right-col-about">
            <h3>{story.fields.title}</h3>
            <p>{story.fields.cardDescription}</p>
            <NavLink to={`/editorial/${story.fields.slug}`} className="inlineBlock">
              <div className="subheader-2 txt-underline flex flex-center">
                Continue Reading <IconArrowRight className="icon-arrowRight" />
              </div>
            </NavLink>
          </div>
        </div>
      </SectionContent>
    </section>
  );
};
